import React, { CSSProperties } from 'react'
import classNames from 'classnames'

export const GraySection: React.FC<{
  className?: string
  style?: CSSProperties
  children?: any
}> = ({ className, children, ...props }) => {
  const baseClassName = 'bg-gray-100'
  return (
    <section className={classNames(baseClassName, className)} {...props}>
      {children}
    </section>
  )
}
