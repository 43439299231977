import React from 'react'
import classNames from 'classnames'

export type ButtonProps = { disabled?: boolean } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button: React.FC<ButtonProps> = ({ children, className, disabled = false, ...rest }) => {
  const disabledClassName = 'disabled:bg-green-300 disabled:cursor-not-allowed'
  const baseClassName =
    'inline-block justify-center bg-lime hover:bg-green-600 text-white py-2 text-lg font-semibold px-5 rounded'
  return (
    <button disabled={disabled} className={classNames(baseClassName, disabledClassName, className)} {...rest}>
      {children}
    </button>
  )
}

export const LearnMoreButton: React.FC<{ children?: any }> = ({ children }) => {
  const baseClassName = 'ml-1 inline-block justify-center bg-blue-900 text-white text-sm px-2 rounded'
  return <button className={classNames(baseClassName)}>{children}</button>
}

export const ButtonOutline: React.FC<
  { disabled?: boolean } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ children, className, disabled = false, ...rest }) => {
  const disabledClassName = 'opacity-50 cursor-not-allowed'
  return (
    <button
      disabled={disabled}
      className={`${className} border-primary hover:text-white border inline-block justify-center bg-transparent hover:bg-primary text-primary py-2 text-lg font-semibold px-5 rounded ${
        disabled ? disabledClassName : ''
      }`}
      {...rest}
    >
      {children}
    </button>
  )
}

export const YelpButton: React.FC<{ children?: any }> = ({ children }) => {
  return (
    <button
      className={`inline-block w-full md:w-auto  border-gray-800 border justify-center bg-transparent hover:bg-gray-800 hover:text-white text-gray-800 py-2 text-lg font-semibold px-5 rounded`}
    >
      {children}
    </button>
  )
}

export const ViewAllCitiesButton: React.FC<{ children?: any }> = ({ children }) => {
  return (
    <button
      className={`inline-block border-allCitiesGray border justify-center bg-transparent hover:bg-allCitiesGray hover:text-white text-allCitiesGray py-2 text-lg font-semibold px-5 rounded`}
    >
      {children}
    </button>
  )
}
