export const carData = [
  {
    year: 2024,
    make: 'Mercedes-Benz',
    models:
      'EQS AMG,GLE63 AMG S,Sprinter 2500,GLC300,AMG GT 63,GLS580,SL55 AMG,C43 AMG,GLE450e,Maybach S680,EQS 580,GLS450,EQB 350,S500,C300,EQS 450 SUV,GLE580,EQS 450+,GLC43 AMG,EQE 350+ SUV,EQE 350+,EQE 500,AMG GT 55,GLE350,EQS 580 SUV,S580e,Maybach GLS600,EQS 450+ SUV,EQB 250,EQS 450,EQB 300,SL63 AMG,EQE 350,EQE 500 SUV,Maybach S580,E450,GLA250,Maybach EQS 680 SUV,GLA35 AMG,GLE53 AMG,GLB35 AMG,GLB250,E350,S580,EQE 350 SUV,SL43 AMG,GLS63 AMG,EQE AMG,S63 AMG E Performance',
  },
  {
    year: 2024,
    make: 'Kia',
    models: 'Sportage,Telluride,Carnival,Soul,EV9,Seltos,EV6,Niro EV,Niro,K5',
  },
  {
    year: 2024,
    make: 'Hyundai',
    models:
      'Palisade,Tucson,Ioniq 6,Venue,Sonata,Santa Cruz,Santa Fe,Grand i10,Kona,Elantra N,Ioniq 5,Nexo,Kona Electric,Creta',
  },
  {
    year: 2024,
    make: 'GMC',
    models:
      'Hummer EV SUV,Sierra 1500,Sierra 2500 HD,Yukon XL,Terrain,Yukon,Savana 2500,Hummer EV Pickup,Canyon,Sierra EV',
  },
  {
    year: 2024,
    make: 'Toyota',
    models:
      'GR Corolla,Sienna,Crown,Corolla Cross,Tacoma,Highlander,Camry,Land Cruiser,Prius Prime,Corolla,Tundra,bZ4X,Sequoia,Prius AWD-e,Grand Highlander,RAV4,GR86,Prius,RAV4 Prime,Mirai,GR Supra',
  },
  {
    year: 2024,
    make: 'Honda',
    models: 'HR-V,Civic,Prologue,Pilot,CR-V,BR-V,Ridgeline,Passport,Odyssey,Accord',
  },
  {
    year: 2024,
    make: 'Chevrolet',
    models:
      'Silverado 1500,Equinox,Silverado 2500 HD,Malibu,Tracker,Tahoe,Suburban,Bolt EV,Blazer,LCF 3500HG,Trailblazer,Express 2500,Aveo,Camaro,Traverse,Blazer EV,Colorado,Corvette,Montana,Trax,Silverado EV,LCF 6500XD,Onix,Equinox EV,Groove,Captiva,Bolt EUV',
  },
  {
    year: 2024,
    make: 'Audi',
    models:
      'A3 Quattro,Q8 e-tron Sportback,Q8 e-tron,A4 allroad,S4,Q7,Q5 PHEV,SQ7,e-tron GT,A4 Quattro,A5 Quattro,Q8,Q4 e-tron Sportback,Q4 e-tron,A6 Quattro,S5,SQ8 e-tron,S5 Sportback,A3,Q3,RS7 Sportback,RS e-tron GT,S3,S8,Q5 Sportback,SQ5,A8 Quattro,A7 Sportback,Q5,SQ8 e-tron Sportback,A6 allroad,RS5,RS3,A5 Sportback,SQ8,RS5 Sportback,RS6 Avant,SQ5 Sportback',
  },
  {
    year: 2024,
    make: 'Buick',
    models: 'Enclave,Encore GX,Envision,Envista',
  },
  {
    year: 2024,
    make: 'Jeep',
    models:
      'Grand Wagoneer L,Wagoneer,Grand Cherokee,Renegade,Gladiator,Grand Cherokee L,Grand Wagoneer,Wrangler,Compass,Wagoneer L',
  },
  {
    year: 2024,
    make: 'Acura',
    models: 'Integra,RDX,TLX,MDX,ZDX',
  },
  {
    year: 2024,
    make: 'Ford',
    models:
      'Expedition,Bronco Sport,Bronco,Territory,F-250 Super Duty,F-150,Explorer,Mustang,Ranger,E-Transit,F-150 Lightning,Edge,F-750,Escape,Maverick,Transit-250,Transit-150,Police Interceptor Utility,Mustang Mach-E',
  },
  {
    year: 2024,
    make: 'Lincoln',
    models: 'Navigator,Nautilus,Corsair',
  },
  {
    year: 2024,
    make: 'Maserati',
    models: 'Grecale,MC20,GranTurismo,Levante,Ghibli',
  },
  {
    year: 2024,
    make: 'Nissan',
    models: 'GT-R,Sentra,LEAF,Altima,Frontier,Versa,ARIYA,Z,Rogue,Pathfinder,Murano,TITAN,Kicks,TITAN XD',
  },
  {
    year: 2024,
    make: 'Subaru',
    models: 'Forester,WRX,Crosstrek,Legacy,Solterra,BRZ,Outback,Impreza',
  },
  {
    year: 2024,
    make: 'Volvo',
    models: 'VNR,VNL,S60,XC40,C40 Recharge,VHD,VAH',
  },
  {
    year: 2024,
    make: 'Cadillac',
    models: 'LYRIQ,CT4,Escalade ESV,XT5,XT6,Escalade,XT4,CT5',
  },
  {
    year: 2024,
    make: 'Porsche',
    models: 'Cayenne,Macan,718 Boxster,911,718 Cayman,Taycan,718 Spyder',
  },
  {
    year: 2024,
    make: 'McLaren',
    models: 'Artura,750S',
  },
  {
    year: 2024,
    make: 'Volkswagen',
    models: 'ID.4,Jetta,GTI,Atlas,Tiguan,Taos,Atlas Cross Sport,Golf R,T-Cross',
  },
  {
    year: 2024,
    make: 'Alfa Romeo',
    models: 'Giulia,Stelvio,Tonale',
  },
  {
    year: 2024,
    make: 'Genesis',
    models: 'GV80,GV60,GV70,G90,Electrified GV70,Electrified G80',
  },
  {
    year: 2024,
    make: 'Ram',
    models: '2500,1500,ProMaster 1500,ProMaster 2500,ProMaster Rapid',
  },
  {
    year: 2024,
    make: 'BMW',
    models:
      'M3,X4,iX1,430i xDrive,X5,i4,430i xDrive Gran Coupe,430i,X3,M2,840i xDrive,X1,M4,M8,220i Gran Coupe,750e xDrive,228i Gran Coupe,230i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,M440i,M440i xDrive Gran Coupe,M850i xDrive,128ti,220i,M8 Gran Coupe,iX3,M135i,330i,i7,iX,XM,540i xDrive,M340i xDrive,M440i xDrive,230i,X6,840i,330e xDrive,330i xDrive,i5,Alpina XB7,M240i,330e,Z4,228i xDrive Gran Coupe,X7,Alpina B8 Gran Coupe,M240i xDrive,M440i Gran Coupe,760i xDrive,430i Gran Coupe,M850i xDrive Gran Coupe,320i,M340i,530i xDrive,840i Gran Coupe,740i,118i',
  },
  {
    year: 2024,
    make: 'Chrysler',
    models: 'Grand Caravan,Pacifica,Voyager',
  },
  {
    year: 2024,
    make: 'Lexus',
    models:
      'LS500,RX450h+,UX250h,LC500,ES250,RX350,GX550,IS300,ES350,TX500h,LX600,RZ450e,RX500h,RX350h,TX350,LS500h,NX450h+,NX350,ES300h,NX350h,LC500h,IS350,IS500,NX250,TX550h+',
  },
  {
    year: 2024,
    make: 'Mitsubishi',
    models: 'Outlander Sport,RVR,Mirage G4,Outlander,Outlander PHEV,Eclipse Cross,Mirage',
  },
  {
    year: 2024,
    make: 'Mazda',
    models: 'CX-5,CX-90,3,CX-30,MX-30 EV,CX-50,3 Sport',
  },
  {
    year: 2024,
    make: 'Jaguar',
    models: 'F-Pace,XF,F-Type,E-Pace,I-Pace',
  },
  {
    year: 2024,
    make: 'Land Rover',
    models:
      'Discovery,Range Rover Sport,Discovery Sport,Defender 130,Range Rover Velar,Range Rover Evoque,Range Rover,Defender 90',
  },
  {
    year: 2024,
    make: 'Bentley',
    models: 'Continental,Flying Spur,Bentayga EWB',
  },
  {
    year: 2024,
    make: 'Lucid',
    models: 'Air',
  },
  {
    year: 2024,
    make: 'Renault',
    models: 'Oroch,KWID,Stepway,Koleos,Duster,Logan',
  },
  {
    year: 2024,
    make: 'Mini',
    models: 'Cooper,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2024,
    make: 'INFINITI',
    models: 'QX60,QX80,QX55',
  },
  {
    year: 2024,
    make: 'Dodge',
    models: 'Durango,Hornet,Attitude',
  },
  {
    year: 2024,
    make: 'Fiat',
    models: 'Fastback,500X',
  },
  {
    year: 2024,
    make: 'Aston Martin',
    models: 'DBX,Valhalla,Valour,DBX707,DB12,DBS',
  },
  {
    year: 2024,
    make: 'Rivian',
    models: 'R1T,R1S',
  },
  {
    year: 2024,
    make: 'Lotus',
    models: 'Emira',
  },
  {
    year: 2024,
    make: 'Ferrari',
    models: '296 GTB,296 GTS,Roma,Purosangue',
  },
  {
    year: 2024,
    make: 'Lamborghini',
    models: 'Revuelto,Urus',
  },
  {
    year: 2024,
    make: 'Polestar',
    models: 'Polestar 2,Polestar 3',
  },
  {
    year: 2024,
    make: 'Rolls-Royce',
    models: 'Spectre',
  },
  {
    year: 2023,
    make: 'Acura',
    models: 'Integra,MDX,RDX,TLX',
  },
  {
    year: 2023,
    make: 'Alfa Romeo',
    models: 'Giulia,Stelvio,Tonale',
  },
  {
    year: 2023,
    make: 'Aston Martin',
    models: 'DB11,DBS,DBX,DBX707,Vantage',
  },
  {
    year: 2023,
    make: 'Audi',
    models:
      'A3,A3 Quattro,A4 allroad,A4 Quattro,A5 Quattro,A5 Sportback,A6 allroad,A6 Quattro,A7 Sportback,A8 Quattro,e-tron GT,e-tron Quattro,e-tron S,e-tron S Sportback,e-tron Sportback,Q3,Q5,Q5 Sportback,Q7,Q8,R8,RS e-tron GT,RS Q8,RS3,RS5,RS5 Sportback,RS7 Sportback,S3,S4,S5,S6,S7 Sportback,S8,SQ5 Sportback,SQ7,SQ8,TT Quattro,TT RS Quattro,TTS Quattro',
  },
  {
    year: 2023,
    make: 'Bentley',
    models: 'Bentayga EWB,Continental,Flying Spur,Bentayga',
  },
  {
    year: 2023,
    make: 'BMW',
    models:
      '118i,128ti,220i,220i Gran Coupe,230i,330e,330e xDrive,330i,330i xDrive,430i,430i Gran Coupe,430i xDrive Gran Coupe,440i Gran Coupe,530e,530i,740i,760i xDrive,840i,840i Gran Coupe,840i xDrive,840i xDrive Gran Coupe,Alpina B8 Gran Coupe,Alpina XB7,i4,i7,iX,iX xDrive40,iX xDrive50,M135i,M235i xDrive Gran Coupe,M240i,M240i xDrive,M3,M340i,M340i xDrive,M4,M440i,M440i xDrive,M440i xDrive Gran Coupe,M5,M550i xDrive,M760i xDrive,M8,M8 Gran Coupe,M850i xDrive,M850i xDrive Gran Coupe,X1,X2,X3,X4,X5,X6,X7,Z4,430i xDrive',
  },
  {
    year: 2023,
    make: 'Buick',
    models: 'Enclave,Encore GX,Envision',
  },
  {
    year: 2023,
    make: 'Cadillac',
    models: 'CT4,CT5,Escalade,Escalade ESV,LYRIQ,XT4,XT5,XT6',
  },
  {
    year: 2023,
    make: 'Chevrolet',
    models:
      'Blazer,Bolt EUV,Bolt EV,Camaro,Captiva,Colorado,Corvette,Equinox,Express 2500,Grove,Malibu,Onix,S10 Max,Silverado 1500,Silverado 2500 HD,Suburban,Tahoe,Tornado Van,Tracker,Trailblazer,Traverse',
  },
  {
    year: 2023,
    make: 'Chrysler',
    models: '300,Pacifica',
  },
  {
    year: 2023,
    make: 'Dodge',
    models: 'Durango,Challenger,Hornet',
  },
  {
    year: 2023,
    make: 'Ferrari',
    models: '296 GTB,296 GTS,812 GTS,Portofino M,Roma,SF90 Spider,SF90 Stradale',
  },
  {
    year: 2023,
    make: 'Fiat',
    models: 'Pulse',
  },
  {
    year: 2023,
    make: 'Ford',
    models:
      'Bronco,Bronco Sport,E-Transit,Edge,Escape,Expedition,Explorer,F-150,F-150 Lightning,F-250 Super Duty,Maverick,Mustang Mach-E,Police Interceptor Utility,Ranger,Transit Connect,Transit-250',
  },
  {
    year: 2023,
    make: 'Genesis',
    models: 'G70,G80,GV60,GV70,GV80',
  },
  {
    year: 2023,
    make: 'GMC',
    models: 'Acadia,Canyon,Hummer EV,Savana 2500,Sierra 1500,Sierra 2500 HD,Terrain,Yukon,Yukon XL',
  },
  {
    year: 2023,
    make: 'Honda',
    models: 'Civic,CR-V,HR-V,Odyssey,Passport,Pilot,Ridgeline',
  },
  {
    year: 2023,
    make: 'Hyundai',
    models:
      'Elantra,Elantra N,Grand i10,Ioniq 5,Kona,Kona Electric,Kona N,Nexo,Palisade,Santa Cruz,Santa Fe,Sonata,Tucson,Venue',
  },
  {
    year: 2023,
    make: 'INFINITI',
    models: 'Q50,QX50,QX55,QX60,QX80',
  },
  {
    year: 2023,
    make: 'Toyota',
    models:
      'HV507,4Runner,bZ4X,Camry,Corolla,Corolla Cross,GR Corolla,GR Supra,GR86,Highlander,Mirai,RAV4,RAV4 Prime,Sequoia,Sienna,Tacoma,Tundra,Venza,Crown',
  },
  {
    year: 2023,
    make: 'Nissan',
    models:
      'Quashqai  S,Altima,Ariya,Armada,Frontier,Kicks,LEAF,Maxima,Pathfinder,Rogue,Sentra,Titan,Titan XD,Versa,Z,Kicks e-Power',
  },
  {
    year: 2023,
    make: 'Jaguar',
    models: 'E-Pace,F-Pace,F-Type,I-Pace,XF',
  },
  {
    year: 2023,
    make: 'Jeep',
    models: 'Cherokee,Compass,Gladiator,Grand Cherokee,Grand Cherokee L,Grand Wagoneer,Renegade,Wagoneer,Wrangler',
  },
  {
    year: 2023,
    make: 'Kia',
    models: 'Carnival,EV6,Forte,Forte5,K5,Niro,Seltos,Sorento,Soul,Sportage,Stinger,Telluride',
  },
  {
    year: 2023,
    make: 'Lamborghini',
    models: 'Huracan,Urus',
  },
  {
    year: 2023,
    make: 'Land Rover',
    models:
      'Defender 110,Defender 130,Defender 90,Discovery,Discovery Sport,Range Rover,Range Rover Evoque,Range Rover Sport,Range Rover Velar',
  },
  {
    year: 2023,
    make: 'Lexus',
    models: 'ES250,GX460,IS300,IS350,IS500,LX600,NX250,NX350,NX350h,NX450h+,RZ450e,UXh,LS500h',
  },
  {
    year: 2023,
    make: 'Lincoln',
    models: 'Aviator,Nautilus,Navigator',
  },
  {
    year: 2023,
    make: 'Lotus',
    models: 'Emira',
  },
  {
    year: 2023,
    make: 'Lucid',
    models: 'Air',
  },
  {
    year: 2023,
    make: 'Maserati',
    models: 'Ghibli,Grecale,Levante,MC20,Quattroporte',
  },
  {
    year: 2023,
    make: 'Mazda',
    models: '2,CX-30,CX-5,CX-50,MX-30 EV,3 Sport,3',
  },
  {
    year: 2023,
    make: 'McLaren',
    models: 'Artura',
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    models:
      'AMG GT 63,AMG GT 63 S,C300,C43 AMG,C63 AMG,CLS450,CLS53 AMG,EQB 250,EQB 300,EQB 350,EQS 450+,EQS 53 AMG,EQS 580,GLA250,GLA35 AMG,GLA45 AMG,GLB250,GLB35 AMG,GLE53 AMG,GLE63 AMG S,Maybach S580,Maybach S680,Metris,S500,S580,S580e,SL55 AMG,SL63 AMG,Sprinter 1500,Sprinter 2500,E450,E53 AMG,G63 AMG,CLA45 AMG',
  },
  {
    year: 2023,
    make: 'Mini',
    models: 'Cooper,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    models: 'Eclipse Cross,L200,Mirage,Mirage G4,Outlander,Xpander',
  },
  {
    year: 2023,
    make: 'Polestar',
    models: 'Polestar 2',
  },
  {
    year: 2023,
    make: 'Porsche',
    models: '718 Boxster,718 Cayman,718 Spyder,911,Cayenne,Macan,Panamera,Taycan',
  },
  {
    year: 2023,
    make: 'Ram',
    models: '1500 Classic,ProMaster 1500,ProMaster 2500,2500,1500',
  },
  {
    year: 2023,
    make: 'Renault',
    models: 'Kangoo,KWID,Oroch',
  },
  {
    year: 2023,
    make: 'Rivian',
    models: 'R1S,R1T,RCV-500,RCV-700',
  },
  {
    year: 2023,
    make: 'Rolls-Royce',
    models: 'Cullinan,Ghost,Phantom',
  },
  {
    year: 2023,
    make: 'Subaru',
    models: 'Ascent,BRZ,Crosstrek,Forester,Impreza,Legacy,Outback,Solterra,WRX',
  },
  {
    year: 2023,
    make: 'Tesla',
    models: '3,S,X,Y',
  },
  {
    year: 2023,
    make: 'Volkswagen',
    models: 'Arteon,Atlas,Atlas Cross Sport,Golf R,GTI,ID.4,Jetta,Taos,Tiguan',
  },
  {
    year: 2023,
    make: 'Volvo',
    models: 'C40 Recharge,S60,S90,V60,V60 Cross Country,VHD,VNL,VNR,XC40,XC40 Recharge,XC60,XC90',
  },
  {
    year: 2022,
    make: 'Acura',
    models: 'TLX,MDX,NSX',
  },
  {
    year: 2022,
    make: 'Alfa Romeo',
    models: 'Giulia,Stelvio',
  },
  {
    year: 2022,
    make: 'Aston Martin',
    models: 'DBX,Vantage',
  },
  {
    year: 2022,
    make: 'Audi',
    models:
      'SQ5,Q8,Q5,RS5 Sportback,A6 allroad,RS5,e-tron GT,e-tron Sportback,RS Q8,Q5 Sportback,Q5 PHEV,SQ7,e-tron Quattro,A7 Sportback,Q7,R8,SQ8,S7 Sportback,RS6 Avant,SQ5 Sportback,RS e-tron GT',
  },
  {
    year: 2022,
    make: 'Bentley',
    models: 'Continental,Flying Spur,Bentayga',
  },
  {
    year: 2022,
    make: 'BMW',
    models:
      '540i,840i xDrive Gran Coupe,430i Gran Coupe,M235i xDrive Gran Coupe,540i xDrive,330e xDrive,330e,M760Li xDrive,M4,M5,320i,840i xDrive,430i xDrive Gran Coupe,228i xDrive Gran Coupe,M760i xDrive,iX,M440i Gran Coupe,X7,530e,M135i,Z4,840i Gran Coupe,M850i xDrive,530i xDrive,530e xDrive,i3s,X4,750i xDrive,840i,Alpina XB7,220i Gran Coupe,M440i,430i,330i,530i,118i,M440i xDrive,M440i xDrive Gran Coupe,Alpina B7,X5,M850i xDrive Gran Coupe,M550i xDrive,745e xDrive,550i,X3,X6,X2,745e,128ti,M340i xDrive,740i xDrive,740i,X1,750Li xDrive,M8 Gran Coupe,745Le xDrive,M340i,M8',
  },
  {
    year: 2022,
    make: 'Buick',
    models: 'Envision,Encore,Enclave,Encore GX',
  },
  {
    year: 2022,
    make: 'Cadillac',
    models: 'Escalade,XT5,CT5,XT4,CT4,Escalade ESV,XT6',
  },
  {
    year: 2022,
    make: 'Chevrolet',
    models:
      'Bolt EV,Captiva,Trailblazer,Silverado 1500,Blazer,Tahoe,Suburban,Corvette,Silverado 2500 HD,Bolt EUV,Camaro,Colorado,Malibu,Equinox,Traverse,Trax,Spark',
  },
  {
    year: 2022,
    make: 'Chrysler',
    models: 'Pacifica,Voyager',
  },
  {
    year: 2022,
    make: 'Ford',
    models:
      'F-150,Edge,F-150 Lightning,Police Interceptor Utility,Explorer,Expedition,Mustang,EcoSport,Mustang Mach-E,Escape,F-250 Super Duty,Maverick,Bronco Sport,Ranger,Transit Connect',
  },
  {
    year: 2022,
    make: 'Genesis',
    models: 'G80,GV70,GV80,G70',
  },
  {
    year: 2022,
    make: 'GMC',
    models: 'Sierra 1500,Yukon,Acadia,Yukon XL,Terrain,Sierra 2500 HD,Canyon',
  },
  {
    year: 2022,
    make: 'Honda',
    models: 'Passport,Accord,CR-V,Clarity,Civic,Insight,Odyssey,Ridgeline',
  },
  {
    year: 2022,
    make: 'Hyundai',
    models:
      'Palisade,Santa Cruz,Veloster,Kona Electric,Elantra,Santa Fe,Nexo,Veloster N,Kona N,Venue,Tucson,Ioniq 5,Kona,Sonata,Accent',
  },
  {
    year: 2022,
    make: 'Infiniti',
    models: 'Q60,QX80,QX55,QX50,Q50',
  },
  {
    year: 2022,
    make: 'Jaguar',
    models: 'F-Pace,I-Pace,F-Type,E-Pace',
  },
  {
    year: 2022,
    make: 'Jeep',
    models: 'Cherokee,Wagoneer,Wrangler,Gladiator,Renegade,Grand Cherokee L,Grand Wagoneer',
  },
  {
    year: 2022,
    make: 'Kia',
    models: 'Sportage,Soul,Forte5,Telluride,Sorento,Stinger,Seltos,Forte,Carnival,K5,EV6,Rio',
  },
  {
    year: 2022,
    make: 'Lamborghini',
    models: 'Urus',
  },
  {
    year: 2022,
    make: 'Land Rover',
    models: 'Range Rover Sport,Discovery,Discovery Sport,Range Rover Evoque',
  },
  {
    year: 2022,
    make: 'Lexus',
    models: 'UX200,LC500h,IS350,IS300,LS500,RX450h,IS500,LS500h,RX350L,UX250h,RX450hL,RX350,ES250,LC500',
  },
  {
    year: 2022,
    make: 'Lincoln',
    models: 'Corsair,Nautilus,Navigator,Aviator',
  },
  {
    year: 2022,
    make: 'Mazda',
    models: 'CX-5,CX-30',
  },
  {
    year: 2022,
    make: 'McLaren',
    models: 'Artura,GT,765LT',
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    models:
      'AMG GT 63,CLS450,AMG GT 53,GLS63 AMG,G550,GLE63 AMG S,Maybach S680,GLS450,CLA35 AMG,GLS580,C300,GLB35 AMG,GLE580,EQC400,AMG GT 63 S,CLS53 AMG,A35 AMG,GLA250,GLA45 AMG,G63 AMG,CLA250,Maybach GLS600,AMG GT 43,GLE450,GLE53 AMG,GLB250,GLE350,CLA45 AMG,GLA35 AMG',
  },
  {
    year: 2022,
    make: 'Mini',
    models: 'Cooper Clubman,Cooper Countryman,Cooper',
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    models: 'Outlander Sport,Mirage G4,Outlander,Eclipse Cross',
  },
  {
    year: 2022,
    make: 'Nissan',
    models: 'Sentra,Frontier,GT-R,Armada,Altima,Pathfinder,Rogue,Kicks,Maxima,Leaf,Ariya',
  },
  {
    year: 2022,
    make: 'Peugeot',
    models: 'Manager,2008,Partner,208,301,Rifter,Expert,Landtrek,5008',
  },
  {
    year: 2022,
    make: 'Porsche',
    models: 'Cayenne,718 Boxster,Taycan,911,718 Spyder,718 Cayman',
  },
  {
    year: 2022,
    make: 'Ram',
    models: '1500',
  },
  {
    year: 2022,
    make: 'Rolls-Royce',
    models: 'Ghost',
  },
  {
    year: 2022,
    make: 'Subaru',
    models: 'BRZ,Ascent,Forester,Outback,Legacy',
  },
  {
    year: 2022,
    make: 'Toyota',
    models:
      'Prius AWD-e,Highlander,Mirai,Prius,RAV4 Prime,4Runner,GR Supra,Corolla Cross,Venza,Camry,Sienna,Prius Prime,GR 86',
  },
  {
    year: 2022,
    make: 'Volkswagen',
    models: 'Passat,Atlas Cross Sport,ID.4,Golf R,Tiguan,Taos,Arteon,Jetta',
  },
  {
    year: 2022,
    make: 'Volvo',
    models: 'V60 Cross Country,XC90',
  },
  {
    year: 2021,
    make: 'Acura',
    models: 'NSX,ILX,RDX,TLX',
  },
  {
    year: 2021,
    make: 'Alfa Romeo',
    models: 'Giulietta,Giulia,Stelvio',
  },
  {
    year: 2021,
    make: 'Aston Martin',
    models: 'DBS,Vantage,DB11,DBX',
  },
  {
    year: 2021,
    make: 'Audi',
    models:
      'R8,A6,TT Quattro,SQ8,A5 Quattro,S7 Sportback,RS6 Avant,RS7 Sportback,SQ5 Sportback,S5 Sportback,S4,S6,SQ5,Q8,A8 Quattro,A4 allroad,S8,Q5,RS5 Sportback,A5,A4,TTS Quattro,TT RS Quattro,A6 allroad,A1,RS5,e-tron Sportback,RS Q3,RS Q8,Q5 Sportback,A5 Sportback,S5,A6 Quattro,A4 Quattro,Q5 PHEV,Q3,SQ7,e-tron Quattro,A7 Sportback,Q7,Q2',
  },
  {
    year: 2021,
    make: 'Bentley',
    models: 'Bentayga,Continental,Flying Spur',
  },
  {
    year: 2021,
    make: 'BMW',
    models:
      'Alpina B7,X5,M240i xDrive,M850i xDrive Gran Coupe,M550i xDrive,745e xDrive,550i,X3,X6,M3,230i,X2,745e,M340i xDrive,740i xDrive,740i,X1,750Li xDrive,230i xDrive,440i Gran Coupe,M8 Gran Coupe,745Le xDrive,M340i,i3,540i,330i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,540i xDrive,330e xDrive,330e,M760Li xDrive,220i,M4,M5,320i,840i xDrive,228i xDrive Gran Coupe,120i,M760i xDrive,X7,530e,M135i,Z4,840i Gran Coupe,M850i xDrive,530i xDrive,530e xDrive,i3s,420i Gran Coupe,X4,750i xDrive,840i,Alpina XB7,M440i,430i,330i,530i,430i xDrive,118i,M440i xDrive,M2,M240i',
  },
  {
    year: 2021,
    make: 'Bugatti',
    models: 'Divo,Chiron',
  },
  {
    year: 2021,
    make: 'Buick',
    models: 'Envision,Encore,Enclave,Encore GX',
  },
  {
    year: 2021,
    make: 'Cadillac',
    models: 'CT4,Escalade ESV,XT6,Escalade,XT5,CT5,XT4',
  },
  {
    year: 2021,
    make: 'Chevrolet',
    models:
      'Camaro,Colorado,Malibu,Equinox,Traverse,Tracker,Trax,Spark,Express 2500,Express 3500,Bolt EV,Onix,Trailblazer,Silverado 1500,Beat,Blazer,Aveo,Tahoe,Suburban,Cavalier,Corvette,Silverado 2500 HD,Cheyenne',
  },
  {
    year: 2021,
    make: 'Chrysler',
    models: 'Voyager,Grand Caravan,300,Pacifica',
  },
  {
    year: 2021,
    make: 'Dodge',
    models: 'Durango,Attitude,Challenger,Charger',
  },
  {
    year: 2021,
    make: 'Ferrari',
    models: '488 Pista,SF90 Stradale,812 GTS,Portofino,Roma,F8 Spider,812 Superfast,488 Pista Spider,F8 Tributo',
  },
  {
    year: 2021,
    make: 'Fiat',
    models: 'Mobi,Argo,500X',
  },
  {
    year: 2021,
    make: 'Ford',
    models:
      'Transit-150,Bronco Sport,Bronco,Ranger,Transit Connect,Transit Custom,F-150,Edge,Transit-250,Police Interceptor Utility,Explorer,Expedition,Mustang,EcoSport,Transit,Mustang Mach-E,Escape,F-250 Super Duty,GT,Figo,Lobo',
  },
  {
    year: 2021,
    make: 'Freightliner',
    models: 'Sprinter 3500XD,Sprinter 2500',
  },
  {
    year: 2021,
    make: 'Genesis',
    models: 'GV80,G90,G70,G80',
  },
  {
    year: 2021,
    make: 'GMC',
    models: 'Sierra,Terrain,Sierra 2500 HD,Canyon,Sierra 1500,Yukon,Acadia,Savana 2500,Yukon XL',
  },
  {
    year: 2021,
    make: 'Honda',
    models: 'Civic,City,Insight,Odyssey,Ridgeline,Passport,Accord,CR-V,BR-V,Clarity,HR-V,Pilot',
  },
  {
    year: 2021,
    make: 'Hyundai',
    models:
      'Tucson,Grand i10,Kona,Sonata,Accent,Palisade,Veloster,Kona Electric,Creta,Ioniq,Elantra,Santa Fe,Nexo,Veloster N,Venue',
  },
  {
    year: 2021,
    make: 'Infiniti',
    models: 'QX50,Q50,Q60,QX80',
  },
  {
    year: 2021,
    make: 'JAC',
    models: 'SEI 4,ESEI 2,Sunray,E Sunray,ESEI 4,J4,EJ4,X250,SEI 2,ESEI 1,J7,SEI 3,SEI 7',
  },
  {
    year: 2021,
    make: 'Jaguar',
    models: 'E-Pace,F-Pace,XF,XE,I-Pace,F-Type',
  },
  {
    year: 2021,
    make: 'Jeep',
    models: 'Gladiator,Renegade,Grand Cherokee L,Grand Cherokee,Compass,Cherokee,Wrangler',
  },
  {
    year: 2021,
    make: 'JMC',
    models: 'Vigus',
  },
  {
    year: 2021,
    make: 'Karma',
    models: 'Revero',
  },
  {
    year: 2021,
    make: 'Kia',
    models: 'Niro EV,Seltos,K5,Sportage,Sedona,Soul,Forte5,Rio,Niro,Telluride,Sorento,Stinger,Soul EV,Forte',
  },
  {
    year: 2021,
    make: 'Lamborghini',
    models: 'Huracan,Urus,Aventador',
  },
  {
    year: 2021,
    make: 'Land Rover',
    models: 'Range Rover,Range Rover Sport,Discovery,Discovery Sport,Range Rover Velar,Range Rover Evoque',
  },
  {
    year: 2021,
    make: 'Lexus',
    models:
      'RC300,RX450hL,ES300h,RX350,LX570,RC F,ES250,LC500,UX200,LC500h,NX300h,IS350,IS300,LS500,NX300,RC350,GX460,RX450h,LS500h,ES350,RX350L,UX250h',
  },
  {
    year: 2021,
    make: 'Lincoln',
    models: 'Aviator,Corsair,Nautilus,Navigator',
  },
  {
    year: 2021,
    make: 'Lotus',
    models: 'Evora GT',
  },
  {
    year: 2021,
    make: 'Maserati',
    models: 'Levante,Quattroporte,Ghibli',
  },
  {
    year: 2021,
    make: 'Mazda',
    models: '6,MX-5 Miata,CX-3,3 Sport,2,CX-9,3,CX-5,MX-5,CX-30',
  },
  {
    year: 2021,
    make: 'McLaren',
    models: '570GT,GT,720S,765LT,600LT,540C,570S',
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    models:
      'GLA250,GLA45 AMG,G63 AMG,CLA250,Maybach GLS600,A220,AMG GT 43,Sprinter 3500XD,Maybach S580,E350e,S450L,GLE450,Sprinter 2500,E63 AMG,S580,GLB250,GLC63 AMG S,A250,GLC300,GLE350,CLA45 AMG,S63 AMG,AMG GT,GLA35 AMG,C43 AMG,E53 AMG,E300,AMG GT C,Sprinter 1500,E63 AMG S,GLC43 AMG,AMG GT 63,CLS450,E450,A45 AMG S,AMG GT 53,GLS63 AMG,Metris,A200,G550,GLE53 AMG,GLB200,GLE63 AMG S,GLA200,GLC300e,GLS450,AMG GT R Pro,GLC63 AMG,AMG GT S,C63 AMG S,C63 AMG,S500,GLC350e,E350,E200,AMG GT R,S560,G500,CLA35 AMG,GLS580,CLA200,C300,GLB35 AMG,GLE580,EQC400,AMG GT 63 S,CLS53 AMG,A35 AMG',
  },
  {
    year: 2021,
    make: 'MG',
    models: 'HS,MG5,ZS',
  },
  {
    year: 2021,
    make: 'Mini',
    models: 'Cooper,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2021,
    make: 'Mitsubishi',
    models: 'Outlander PHEV,RVR,Mirage,Outlander Sport,Mirage G4,L200',
  },
  {
    year: 2021,
    make: 'Nissan',
    models:
      'Rogue,Titan XD,Kicks,March,Maxima,NV200,Versa,Pathfinder,Titan,Leaf,370Z,V-Drive,Sentra,NV1500,X-Trail,Frontier,Murano,NV2500,GT-R,Armada,NV350 Urvan,Rogue Sport,NP300,Altima,Qashqai',
  },
  {
    year: 2021,
    make: 'Peugeot',
    models: 'Partner,208,301,Rifter,Expert,Landtrek,308,5008,Manager,2008,Traveller,3008',
  },
  {
    year: 2021,
    make: 'Polestar',
    models: 'Polestar 1,Polestar 2',
  },
  {
    year: 2021,
    make: 'Porsche',
    models: '911,718 Spyder,718 Cayman,Panamera,Cayenne,718 Boxster,Macan,Taycan',
  },
  {
    year: 2021,
    make: 'Ram',
    models: 'ProMaster City,1500,2500,ProMaster 1500,ProMaster 2500,ProMaster Rapid,1500 Classic',
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    models: 'Phantom,Wraith,Dawn,Ghost,Cullinan',
  },
  {
    year: 2021,
    make: 'Subaru',
    models: 'Crosstrek,Legacy,Impreza,WRX STI,Ascent,Forester,Outback,XV,WRX',
  },
  {
    year: 2021,
    make: 'Suzuki',
    models: 'Ertiga,S-Cross,Vitara,Ignis,Ciaz,Swift',
  },
  {
    year: 2021,
    make: 'Toyota',
    models:
      'Sienna,Hilux,Hiace,Supra,Prius Prime,Yaris,Prius C,Prius AWD-e,Tacoma,Highlander,Mirai,Prius,Tundra,Corolla,Land Cruiser,Avanza,RAV4 Prime,C-HR,RAV4,Sequoia,4Runner,Avalon,Venza,Camry,GR Supra',
  },
  {
    year: 2021,
    make: 'Volkswagen',
    models:
      'Vento,Arteon,Atlas,Virtus,Taos,Saveiro,Cross Sport,GTI,Golf,Passat,Crafter Van,Transporter,Jetta,T-Cross,Polo,Atlas Cross Sport,ID.4,Tiguan',
  },
  {
    year: 2021,
    make: 'Volvo',
    models: 'S60,V90 Cross Country,V60,V60 Cross Country,XC40,XC60,S90,V90,XC90',
  },
  {
    year: 2020,
    make: 'Acura',
    models: 'MDX,RDX,TLX,RLX,NSX,ILX',
  },
  {
    year: 2020,
    make: 'Alfa Romeo',
    models: 'Giulia,Stelvio,4C,Giulietta',
  },
  {
    year: 2020,
    make: 'Aston Martin',
    models: 'DBS,Vantage,DB11',
  },
  {
    year: 2020,
    make: 'Audi',
    models:
      'A4,A6 allroad,A1,S3,TTS Quattro,TT RS Quattro,e-tron Sportback,RS Q8,A3 Quattro,A5 Sportback,S5,A6 Quattro,S7,A4 Quattro,Q5 PHEV,Q3,SQ7,e-tron Quattro,A7 Sportback,Q7,RS3,Q2,R8,TT Quattro,SQ8,A5 Quattro,S7 Sportback,A3,S5 Sportback,S4,S6,SQ5,Q8,Q2 Quattro,A8 Quattro,A4 allroad,S8,Q5',
  },
  {
    year: 2020,
    make: 'Bentley',
    models: 'Flying Spur,Continental,Mulsanne,Bentayga',
  },
  {
    year: 2020,
    make: 'BMW',
    models:
      'M135i,Z4,840i Gran Coupe,M850i xDrive,530i xDrive,X7,530e xDrive,i3s,420i Gran Coupe,520i,X4,750i xDrive,840i,440i xDrive,430i,330i,530i,430i xDrive,118i,M2,M8,M240i,Alpina B7,X5,M240i xDrive,M850i xDrive Gran Coupe,430i Gran Coupe,M550i xDrive,745e xDrive,X3,X6,230i,X2,745e,430i xDrive Gran Coupe,M340i xDrive,740i xDrive,420i,740i,X1,750Li xDrive,230i xDrive,440i,440i Gran Coupe,M8 Gran Coupe,745Le xDrive,M340i,i3,540i,i8,330i xDrive,840i xDrive Gran Coupe,440i xDrive Gran Coupe,M235i xDrive Gran Coupe,540i xDrive,330e,M760Li xDrive,220i,M4,M5,320i,840i xDrive,228i xDrive Gran Coupe,120i,M760i xDrive,530e',
  },
  {
    year: 2020,
    make: 'Bugatti',
    models: 'Divo,Chiron',
  },
  {
    year: 2020,
    make: 'Buick',
    models: 'Regal TourX,Envision,Encore,Enclave,Regal Sportback,Encore GX',
  },
  {
    year: 2020,
    make: 'Cadillac',
    models: 'Escalade,XT5,CT5,XT4,CT6,CT4,Escalade ESV,XT6',
  },
  {
    year: 2020,
    make: 'Chevrolet',
    models:
      'Impala,Blazer,Sonic,Aveo,Tahoe,Suburban,Cavalier,Corvette,Silverado 2500 HD,Cheyenne,Camaro,Tornado,Colorado,Malibu,Equinox,Traverse,Trax,Spark,Express 2500,Express 3500,Bolt EV,Silverado 1500,Beat',
  },
  {
    year: 2020,
    make: 'Chrysler',
    models: '300,Pacifica,Voyager',
  },
  {
    year: 2020,
    make: 'Dodge',
    models: 'Challenger,Charger,Neon,Durango,Grand Caravan,Attitude,Journey',
  },
  {
    year: 2020,
    make: 'Ferrari',
    models:
      'Roma,F8 Spider,812 Superfast,488 Pista Spider,F8 Tributo,488 Pista,SF90 Stradale,GTC4Lusso,812 GTS,Portofino,GTC4Lusso T',
  },
  {
    year: 2020,
    make: 'Fiat',
    models: '500X,500L,Uno,124 Spider,Mobi,Palio,Ducato',
  },
  {
    year: 2020,
    make: 'Ford',
    models:
      'Mustang,EcoSport,Transit,Fusion,SSV Plug-In Hybrid,Escape,F-250 Super Duty,Figo,Lobo,Transit-150,GT,Ranger,Transit Connect,Transit Custom,F-150,Edge,Transit-250,Police Interceptor Utility,Explorer,Police Responder Hybrid,Expedition',
  },
  {
    year: 2020,
    make: 'Freightliner',
    models: 'Sprinter 2500,Sprinter 1500,Sprinter 3500XD',
  },
  {
    year: 2020,
    make: 'Genesis',
    models: 'G90,G70,G80',
  },
  {
    year: 2020,
    make: 'GMC',
    models: 'Yukon XL,Sierra,Terrain,Sierra 2500 HD,Canyon,Sierra 1500,Yukon,Acadia,Savana 2500',
  },
  {
    year: 2020,
    make: 'Honda',
    models: 'HR-V,Pilot,Civic,City,Insight,Odyssey,Ridgeline,Passport,Accord,CR-V,BR-V,Fit,Clarity',
  },
  {
    year: 2020,
    make: 'Hyundai',
    models:
      'Kona Electric,Creta,Ioniq,Elantra,Santa Fe,Nexo,Veloster N,Venue,H300,Tucson,Starex,Grand i10,Kona,Sonata,Accent,Palisade,Elantra GT,Veloster',
  },
  {
    year: 2020,
    make: 'Infiniti',
    models: 'Q60,QX60,QX80,QX50,QX70,Q50',
  },
  {
    year: 2020,
    make: 'JAC',
    models: 'SEI 2,ESEI 1,SEI 3,SEI 7,SEI 4,ESEI 2,ESEI 4,J4,EJ4',
  },
  {
    year: 2020,
    make: 'Jaguar',
    models: 'XE,I-Pace,F-Type,E-Pace,F-Pace,XF',
  },
  {
    year: 2020,
    make: 'Jeep',
    models: 'Cherokee,Wrangler,Gladiator,Renegade,Grand Cherokee,Compass',
  },
  {
    year: 2020,
    make: 'JMC',
    models: 'Vigus',
  },
  {
    year: 2020,
    make: 'Karma',
    models: 'Revero',
  },
  {
    year: 2020,
    make: 'Kia',
    models:
      'Sorento,Stinger,Soul EV,Forte,Optima,Cadenza,Niro EV,K900,Sportage,Sedona,Soul,Seltos,Forte5,Rio,Niro,Telluride',
  },
  {
    year: 2020,
    make: 'Lamborghini',
    models: 'Huracan,Urus,Aventador',
  },
  {
    year: 2020,
    make: 'Land Rover',
    models: 'Discovery Sport,Range Rover Velar,Range Rover Evoque,Range Rover,Range Rover Sport,Discovery',
  },
  {
    year: 2020,
    make: 'Lexus',
    models:
      'RC350,GX460,RX450h,LS500h,ES350,GS F,RX350L,UX250h,GS350,RC300,RX450hL,ES300h,RX350,LX570,RC F,LC500,UX200,LC500h,NX300h,IS350,IS300,LS500,NX300',
  },
  {
    year: 2020,
    make: 'Lincoln',
    models: 'Nautilus,Navigator,Aviator,MKZ,Continental,Corsair',
  },
  {
    year: 2020,
    make: 'Lotus',
    models: 'Evora GT',
  },
  {
    year: 2020,
    make: 'Maserati',
    models: 'Ghibli,Levante,Quattroporte',
  },
  {
    year: 2020,
    make: 'Mazda',
    models: 'CX-9,3,CX-5,MX-5,CX-30,6,MX-5 Miata,CX-3,3 Sport,2',
  },
  {
    year: 2020,
    make: 'McLaren',
    models: 'GT,570S,570GT,720S,600LT',
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    models:
      'GLA200,Sprinter 1500,GLS450,AMG GT R Pro,GLC63 AMG,AMG GT S,C63 AMG S,C63 AMG,GLC350e,GLE53 AMG,SL450,E350,V250,S65 AMG,E200,AMG GT R,S560,G500,CLA35 AMG,GLS580,CLA200,C300,Maybach S650,GLE43 AMG,GLE580,Sprinter 3500XD,AMG GT 63 S,CLS53 AMG,S560e,A35 AMG,SLC180,GLA250,G63 AMG,S450,CLA250,A220,C200,SL550,S450L,SLC43 AMG,GLE63 AMG,E63 AMG,Sprinter,S560L,GLB250,GLC63 AMG S,A250,GLC300,GLE350,CLA45 AMG,S63 AMG,AMG GT,E43 AMG,C43 AMG,E53 AMG,E300,AMG GT C,Maybach S560,S600L,E63 AMG S,GLC43 AMG,AMG GT 63,CLS450,E450,AMG GT 53,GLE450,Metris,Sprinter 2500,A200,G550,SLC300,V220d',
  },
  {
    year: 2020,
    make: 'MG',
    models: 'MG5,ZS,HS',
  },
  {
    year: 2020,
    make: 'Mini',
    models: 'Cooper Clubman,Cooper Countryman,Cooper',
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    models: 'Outlander Sport,Mirage G4,Eclipse Cross,L200,Montero Sport,Outlander,Outlander PHEV,RVR,Mirage',
  },
  {
    year: 2020,
    make: 'Nissan',
    models:
      'NV2500,GT-R,Armada,NV350 Urvan,Rogue Sport,NP300,Altima,Qashqai,Rogue,Titan XD,NP300 Frontier,Kicks,March,Maxima,NV200,Versa,Titan,Leaf,370Z,V-Drive,Sentra,NV1500,X-Trail,Frontier,Murano,Pathfinder',
  },
  {
    year: 2020,
    make: 'Peugeot',
    models: 'Traveller,3008,Partner,208,301,Rifter,Expert,308,5008,Manager,2008',
  },
  {
    year: 2020,
    make: 'Polestar',
    models: 'Polestar 1',
  },
  {
    year: 2020,
    make: 'Porsche',
    models: 'Macan,Taycan,911,718 Spyder,718 Cayman,Panamera,Cayenne,718 Boxster',
  },
  {
    year: 2020,
    make: 'Ram',
    models: '1500 Classic,ProMaster City,1500,2500,ProMaster 1500,ProMaster 2500,ProMaster Rapid',
  },
  {
    year: 2020,
    make: 'Rolls-Royce',
    models: 'Dawn,Ghost,Cullinan,Phantom,Wraith',
  },
  {
    year: 2020,
    make: 'Subaru',
    models: 'Ascent,Forester,Outback,XV,WRX,Crosstrek,Legacy,BRZ,Impreza,WRX STI',
  },
  {
    year: 2020,
    make: 'Suzuki',
    models: 'Ciaz,Swift,Ertiga,S-Cross,Vitara,Ignis',
  },
  {
    year: 2020,
    make: 'Toyota',
    models:
      'Avanza,C-HR,RAV4,Sequoia,4Runner,Avalon,Camry,GR Supra,Sienna,Yaris R,86,Hilux,Hiace,Prius Prime,Yaris,Prius C,Prius AWD-e,Tacoma,Highlander,Mirai,Prius,Tundra,Corolla,Land Cruiser',
  },
  {
    year: 2020,
    make: 'Volkswagen',
    models:
      'Passat,Transporter,Jetta,T-Cross,Polo,Caddy,Atlas Cross Sport,Tiguan,Gol,Vento,Arteon,Atlas,Amarok,Virtus,Crafter,Saveiro,GTI,Golf,e-Golf',
  },
  {
    year: 2020,
    make: 'Volvo',
    models: 'V90,XC90,S60,V90 Cross Country,V60,V60 Cross Country,XC40,XC60,S90',
  },
  {
    year: 2019,
    make: 'Acura',
    models: 'NSX,ILX,MDX,RDX,TLX,RLX',
  },
  {
    year: 2019,
    make: 'Alfa Romeo',
    models: '4C,Giulietta,Giulia,Mito,Stelvio',
  },
  {
    year: 2019,
    make: 'Aston Martin',
    models: 'DB11,Rapide,DBS,Vantage',
  },
  {
    year: 2019,
    make: 'Audi',
    models:
      'RS5,S5 Sportback,S4,SQ5,Q8,Q2 Quattro,A8 Quattro,A4 allroad,Q5,A4,S3,A3 Quattro,A5 Sportback,S5,A6 Quattro,A4 Quattro,Q3,e-tron Quattro,A7 Sportback,Q7,RS3,Q2,TTS Quattro,TT Quattro,A5 Quattro,TT RS Quattro,RS5 Sportback,A5,A3',
  },
  {
    year: 2019,
    make: 'Bentley',
    models: 'Flying Spur,Continental,Mulsanne,Bentayga',
  },
  {
    year: 2019,
    make: 'BMW',
    models:
      '420i,740i,X1,750Li xDrive,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,650i Gran Coupe,i3,540i,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,650i xDrive Gran Coupe,540i xDrive,M760Li xDrive,220i,M4,M5,330i GT xDrive,120i,M760i xDrive,530e,Z4,Alpina B6 xDrive Gran Coupe,M850i xDrive,530i xDrive,750i,X7,530e xDrive,i3s,640i Gran Coupe,420i Gran Coupe,520i,X4,750i xDrive,i8,440i xDrive,640i xDrive Gran Turismo,M140i,430i,330i,530i,430i xDrive,118i,750Li,M2,M240i,Alpina B7,X5,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,M550i xDrive,X3,X6,230i,X2,430i xDrive Gran Coupe,740e xDrive,740i xDrive',
  },
  {
    year: 2019,
    make: 'Buick',
    models: 'Enclave,Regal Sportback,Cascada,LaCrosse,Regal TourX,Envision,Encore',
  },
  {
    year: 2019,
    make: 'Cadillac',
    models: 'CTS,Escalade,XT5,XT4,CT6,XTS,ATS,Escalade ESV',
  },
  {
    year: 2019,
    make: 'Chevrolet',
    models:
      'Trax,Spark,Silverado 1500 LD,Express 2500,Express 3500,Bolt EV,Volt,Silverado 1500,Beat,Impala,Blazer,Sonic,Aveo,Tahoe,Cruze,Suburban,Cavalier,Corvette,Silverado 2500 HD,Cheyenne,Camaro,Tornado,Colorado,Malibu,Equinox,Traverse',
  },
  {
    year: 2019,
    make: 'Chrysler',
    models: '300,Pacifica',
  },
  {
    year: 2019,
    make: 'Dodge',
    models: 'Durango,Grand Caravan,Attitude,Journey,Challenger,Charger',
  },
  {
    year: 2019,
    make: 'Ferrari',
    models: '488 Spider,GTC4Lusso T,812 Superfast,488 Pista Spider,488 Pista,488 GTB,GTC4Lusso,Portofino',
  },
  {
    year: 2019,
    make: 'Fiat',
    models: '500,Mobi,Palio,Ducato,500X,500L,Uno,124 Spider',
  },
  {
    year: 2019,
    make: 'Ford',
    models:
      'Transit Connect,F-150,Edge,Transit-250,Fiesta,Lobo,Transit Custom,Police Interceptor Utility,Explorer,Police Responder Hybrid,Expedition,Mustang,Ranger,EcoSport,Transit,Fusion,SSV Plug-In Hybrid,Escape,F-250 Super Duty,Figo,Taurus,Flex,Transit-150,GT,Police Interceptor Sedan',
  },
  {
    year: 2019,
    make: 'Freightliner',
    models: 'Sprinter 2500,Sprinter 1500,Sprinter 3500XD',
  },
  {
    year: 2019,
    make: 'Genesis',
    models: 'G80,G90,G70',
  },
  {
    year: 2019,
    make: 'GMC',
    models: 'Canyon,Sierra 1500,Yukon,Acadia,Savana 2500,Sierra 1500 Limited,Yukon XL,Sierra,Terrain,Sierra 2500 HD',
  },
  {
    year: 2019,
    make: 'Honda',
    models: 'Insight,Odyssey,Ridgeline,Passport,Accord,CR-V,BR-V,Fit,Clarity,HR-V,Pilot,Civic,City',
  },
  {
    year: 2019,
    make: 'Hyundai',
    models:
      'Grand i10,Kona,Sonata,Accent,Elantra GT,Veloster,Kona Electric,Creta,Ioniq,Elantra,Santa Fe,Nexo,Veloster N,H300,Tucson,Starex,Santa Fe XL',
  },
  {
    year: 2019,
    make: 'Infiniti',
    models: 'QX50,QX70,Q50,Q60,Q70L,Q70,QX60,QX80,QX30',
  },
  {
    year: 2019,
    make: 'JAC',
    models: 'J4,SEI 7,SEI 2,SEI 3',
  },
  {
    year: 2019,
    make: 'Jaguar',
    models: 'XJ,F-Pace,XF,XE,I-Pace,XJR575,F-Type,E-Pace',
  },
  {
    year: 2019,
    make: 'Jeep',
    models: 'Grand Cherokee,Compass,Cherokee,Wrangler,Renegade',
  },
  {
    year: 2019,
    make: 'Karma',
    models: 'Revero',
  },
  {
    year: 2019,
    make: 'Kia',
    models: 'K900,Sportage,Sedona,Soul,Rio,Niro,Sorento,Stinger,Soul EV,Forte,Optima,Cadenza,Niro EV',
  },
  {
    year: 2019,
    make: 'Lamborghini',
    models: 'Aventador,Huracan,Urus',
  },
  {
    year: 2019,
    make: 'Land Rover',
    models: 'Range Rover Sport,Discovery,Discovery Sport,Range Rover Velar,Range Rover Evoque,Range Rover',
  },
  {
    year: 2019,
    make: 'Lexus',
    models:
      'LX570,RC F,LC500,UX200,LC500h,NX300h,IS350,IS300,LS500,NX300,RC350,GX460,GS300,RX450h,LS500h,ES350,GS F,RX350L,UX250h,GS350,RC300,RX450hL,ES300h,RX350',
  },
  {
    year: 2019,
    make: 'Lincoln',
    models: 'Continental,MKZ,Nautilus,MKC,Navigator,MKT',
  },
  {
    year: 2019,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2019,
    make: 'Maserati',
    models: 'GranTurismo,Quattroporte,Ghibli,GranCabrio,Levante',
  },
  {
    year: 2019,
    make: 'Mazda',
    models: '2,CX-9,3,CX-5,MX-5,6,MX-5 Miata,CX-3,3 Sport',
  },
  {
    year: 2019,
    make: 'McLaren',
    models: '720S,600LT,570S,GT,570GT',
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    models:
      'A250,GLC300,GLE350,GLE63 AMG S,SL63 AMG,CLA45 AMG,S63 AMG,AMG GT,E43 AMG,C43 AMG,E53 AMG,E250,E300,AMG GT C,Maybach S560,GLE500,S600L,E63 AMG S,GLC43 AMG,Sprinter 3500XD,AMG GT 63,CLS450,E450,AMG GT 53,Metris,A200,G550,SLC300,V220d,GLE400,GLA45 AMG,GLA200,C180,GLS450,GLE450,GLC63 AMG,AMG GT S,C63 AMG S,C63 AMG,GLC350e,SL450,V250,S65 AMG,E200,AMG GT R,GLE500e,S560,G500,GLS550,SL65 AMG,CLA200,Sprinter 2500,C300,Maybach S650,SL500,GLE43 AMG,AMG GT 63 S,CLS53 AMG,S560e,SLC180,GLA250,B250,G63 AMG,S450,CLA250,GLS500,A220,C200,SL550,S450L,Sprinter 1500,GLS63 AMG,SLC43 AMG,GLE63 AMG,Sprinter,S560L,GLC63 AMG S',
  },
  {
    year: 2019,
    make: 'Mini',
    models: 'Cooper,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    models: 'Montero,Outlander,Outlander PHEV,RVR,Mirage,Outlander Sport,Mirage G4,Eclipse Cross,L200,Montero Sport',
  },
  {
    year: 2019,
    make: 'Nissan',
    models:
      'Titan,Leaf,Note,Micra,370Z,Sentra,NV1500,X-Trail,Frontier,Murano,Pathfinder,NV2500,GT-R,Armada,NV350 Urvan,Rogue Sport,NP300,Altima,Qashqai,Rogue,Titan XD,NP300 Frontier,Versa Note,Kicks,March,Maxima,NV200,Versa',
  },
  {
    year: 2019,
    make: 'Peugeot',
    models: '308,5008,Manager,2008,Traveller,3008,Partner,208,301,Expert',
  },
  {
    year: 2019,
    make: 'Porsche',
    models: '911,718 Cayman,Panamera,Cayenne,718 Boxster,Macan',
  },
  {
    year: 2019,
    make: 'Ram',
    models: '2500,ProMaster 1500,ProMaster 2500,ProMaster Rapid,1500 Classic,ProMaster City,1500',
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    models: 'Phantom,Wraith,Ghost,Cullinan,Dawn',
  },
  {
    year: 2019,
    make: 'Smart',
    models: 'EQ fortwo',
  },
  {
    year: 2019,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2019,
    make: 'Subaru',
    models: 'BRZ,Impreza,WRX STI,Ascent,Forester,Outback,XV,WRX,Crosstrek,Legacy',
  },
  {
    year: 2019,
    make: 'Suzuki',
    models: 'S-Cross,Vitara,Ignis,Ciaz,Swift,Ertiga',
  },
  {
    year: 2019,
    make: 'Toyota',
    models:
      'Hilux,Hiace,Prius Prime,Yaris,Prius C,Prius AWD-e,Tacoma,Highlander,Mirai,Prius,Tundra,Corolla,Land Cruiser,Avanza,C-HR,RAV4,Sequoia,4Runner,Avalon,Camry,Yaris R,Sienna,86',
  },
  {
    year: 2019,
    make: 'Volkswagen',
    models:
      'Crafter Van,Saveiro,Beetle,Teramont,GTI,Golf,e-Golf,Golf SportWagen,Passat,Transporter,Jetta,Polo,Caddy,Tiguan,Golf Alltrack,Gol,Vento,Arteon,Atlas,Amarok,Golf R',
  },
  {
    year: 2019,
    make: 'Volvo',
    models: 'V40,V90 Cross Country,V60,V60 Cross Country,XC40,XC60,S90,V90,XC90,S60',
  },
  {
    year: 2018,
    make: 'Acura',
    models: 'TLX,RLX,NSX,ILX,MDX,RDX',
  },
  {
    year: 2018,
    make: 'Alfa Romeo',
    models: 'Stelvio,4C,Giulietta,Giulia,Mito',
  },
  {
    year: 2018,
    make: 'Aston Martin',
    models: 'Vantage,DB11,Vanquish,Rapide',
  },
  {
    year: 2018,
    make: 'Audi',
    models:
      'S5,Q3 Quattro,A6 Quattro,A4 Quattro,Q3,Q7,Q2,S6,TT Quattro,RS7,RS Q3,A5 Quattro,TT RS Quattro,S8,A5,A3,TT,A6,TTS Quattro,A1,RS5,S5 Sportback,RS3,SQ5,Q2 Quattro,A8 Quattro,A4 allroad,S7,Q5,R8,A4,S3,A3 Sportback e-tron,S4,A7 Quattro,A3 Quattro,A5 Sportback',
  },
  {
    year: 2018,
    make: 'Bentley',
    models: 'Mulsanne,Bentayga,Continental,Flying Spur',
  },
  {
    year: 2018,
    make: 'BMW',
    models:
      '330i,340i,530i,430i xDrive,118i,750Li,M760i xDrive,M2,320i xDrive,M240i,Alpina B7,X5,i8,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,540d xDrive,M550i xDrive,330e,650i,X3,X6,M6,320i,230i,X2,430i xDrive Gran Coupe,640i xDrive,740e xDrive,740i xDrive,420i,740i,640i,X1,750Li xDrive,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,650i Gran Coupe,i3,540i,M3,318i,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,650i xDrive Gran Coupe,540i xDrive,220i,M4,M5,328d,330i GT xDrive,120i,530e,650i xDrive,Alpina B6 xDrive Gran Coupe,328d xDrive,530i xDrive,750i,530e xDrive,i3s,640i Gran Coupe,550i xDrive,420i Gran Coupe,520i,340i xDrive,X4,750i xDrive,440i xDrive,640i xDrive Gran Turismo,M140i,430i,M760Li xDrive',
  },
  {
    year: 2018,
    make: 'Bugatti',
    models: 'Chiron',
  },
  {
    year: 2018,
    make: 'Buick',
    models: 'Envision,Encore,Enclave,Regal Sportback,Cascada,LaCrosse,Regal TourX',
  },
  {
    year: 2018,
    make: 'Cadillac',
    models: 'CT6,XTS,ATS,Escalade ESV,CTS,Escalade,XT5',
  },
  {
    year: 2018,
    make: 'Chevrolet',
    models:
      'Cavalier,Corvette,Silverado 2500 HD,Cheyenne,Camaro,Tornado,Colorado,City Express,Malibu,Equinox,Traverse,Trax,Spark,LCF 3500,Express 2500,Express 3500,Bolt EV,Volt,Silverado 1500,Beat,Impala,Sonic,Aveo,Silverado 2500,Tahoe,Suburban,Cruze',
  },
  {
    year: 2018,
    make: 'Chrysler',
    models: '300,Pacifica',
  },
  {
    year: 2018,
    make: 'Dodge',
    models: 'Challenger,Charger,Vision,Durango,Grand Caravan,Attitude,Journey,Neon',
  },
  {
    year: 2018,
    make: 'Ferrari',
    models: 'California T,GTC4Lusso T,488 GTB,GTC4Lusso,Portofino,488 Spider,812 Superfast',
  },
  {
    year: 2018,
    make: 'Fiat',
    models: '124 Spider,500,Mobi,Palio,Ducato,500X,500L,Uno',
  },
  {
    year: 2018,
    make: 'Ford',
    models:
      'F-250 Super Duty,Focus,Figo,Ranger,Taurus,Flex,Transit-150,GT,Police Interceptor Sedan,Transit Connect,F-150,Edge,Transit-250,Fiesta,Special Service Police Sedan,Lobo,Transit Custom,Police Interceptor Utility,Explorer,Expedition,Mustang,EcoSport,Transit,C-Max,Fusion,Escape',
  },
  {
    year: 2018,
    make: 'Freightliner',
    models: 'Sprinter 2500,Sprinter 3500XD',
  },
  {
    year: 2018,
    make: 'Genesis',
    models: 'G90,G80',
  },
  {
    year: 2018,
    make: 'GMC',
    models: 'Sierra,Terrain,Sierra 2500 HD,Canyon,Sierra 1500,Yukon,Acadia,Savana 2500,Yukon XL',
  },
  {
    year: 2018,
    make: 'Honda',
    models: 'Pilot,Civic,City,Odyssey,Ridgeline,Accord,CR-V,BR-V,Fit,Clarity,HR-V',
  },
  {
    year: 2018,
    make: 'Hyundai',
    models:
      'Santa Fe,H300,Tucson,H200,Santa Fe XL,Santa Fe Sport,Grand i10,Kona,Sonata,Accent,Elantra GT,Creta,Ioniq,Elantra',
  },
  {
    year: 2018,
    make: 'Infiniti',
    models: 'QX60,QX80,QX30,QX70,Q50,Q60,Q70L,Q70',
  },
  {
    year: 2018,
    make: 'JAC',
    models: 'SEI 2,SEI 3,J4',
  },
  {
    year: 2018,
    make: 'Jaguar',
    models: 'F-Type,E-Pace,XJ,F-Pace,XF,XE,XJR575',
  },
  {
    year: 2018,
    make: 'Jeep',
    models: 'Wrangler,Renegade,Wrangler JK,Grand Cherokee,Compass,Cherokee',
  },
  {
    year: 2018,
    make: 'Karma',
    models: 'Revero',
  },
  {
    year: 2018,
    make: 'Kia',
    models: 'Soul EV,Forte,Optima,Cadenza,Forte5,Sportage,Sedona,Soul,Rio,Niro,Sorento,Stinger',
  },
  {
    year: 2018,
    make: 'Lamborghini',
    models: 'Huracan,Urus,Veneno,Aventador,Centenario',
  },
  {
    year: 2018,
    make: 'Land Rover',
    models: 'Range Rover Evoque,Range Rover,Range Rover Sport,Discovery,Discovery Sport,Range Rover Velar',
  },
  {
    year: 2018,
    make: 'Lexus',
    models:
      'RX350L,GS350,RC300,RX450hL,ES300h,RX350,LX570,RC F,LC500,LC500h,GS450h,NX300h,IS350,IS300,LS500,NX300,RC350,GX460,GS300,RX450h,LS500h,ES350,GS F',
  },
  {
    year: 2018,
    make: 'Lincoln',
    models: 'MKT,MKX,MKZ,Continental,MKC,Navigator',
  },
  {
    year: 2018,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2018,
    make: 'Maserati',
    models: 'Levante,GranTurismo,Quattroporte,Ghibli',
  },
  {
    year: 2018,
    make: 'Mazda',
    models: '6,MX-5 Miata,CX-3,2,3 Sport,CX-9,3,CX-5,MX-5',
  },
  {
    year: 2018,
    make: 'McLaren',
    models: '570S,570GT,720S',
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    models:
      'CLA200,G65 AMG,S400L,GLA180,C300,Maybach S650,SL500,GLE43 AMG,SLC180,GLA250,CLS400,B250,G63 AMG,S500,S450,CLA250,CLS500,C200,SL550,GLE500e,GLS63 AMG,SLC43 AMG,GLE63 AMG,E400,Sprinter,GLC63 AMG S,GLC300,GLE350,GLE63 AMG S,SL63 AMG,CLA45 AMG,Sprinter 2500,S63 AMG,G550 4x4,AMG GT,E43 AMG,Sprinter 3500XD,C43 AMG,E250,AMG GT C,Maybach S560,A45 AMG,GLE500,E63 AMG S,CLS63 AMG S,GLC43 AMG,GLC250,Metris,A200,CLS550,G550,SLC300,V220d,GLE400,GLE550,GLA45 AMG,GLA200,C250,S500L,C180,E300,GLS450,GLC63 AMG,GLE550e,C63 AMG S,S600L,C63 AMG,AMG GT S,GLC350e,SL450,V250,S65 AMG,E200,AMG GT R,S560,G500,GLS550,C350e,SL65 AMG',
  },
  {
    year: 2018,
    make: 'Mini',
    models: 'Cooper,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    models: 'L200,Montero Sport,Montero,Outlander,Outlander PHEV,RVR,Mirage,Outlander Sport,Mirage G4,Eclipse Cross',
  },
  {
    year: 2018,
    make: 'Nissan',
    models:
      'Rogue,Titan XD,NP300 Frontier,Versa Note,Kicks,March,Maxima,NV200,Versa,Titan,Leaf,Note,Micra,370Z,NT400 Cabstar,Sentra,NV1500,Tiida,X-Trail,Frontier,Murano,Pathfinder,NV2500,GT-R,Armada,NV350 Urvan,Rogue Sport,NP300,Altima,Qashqai',
  },
  {
    year: 2018,
    make: 'Peugeot',
    models: 'Partner,208,301,Expert,308,3008,Manager,Traveller',
  },
  {
    year: 2018,
    make: 'Porsche',
    models: '911,718 Cayman,Panamera,Cayenne,718 Boxster,Macan',
  },
  {
    year: 2018,
    make: 'Ram',
    models: 'ProMaster City,1500,2500,ProMaster 1500,ProMaster 2500,ProMaster Rapid',
  },
  {
    year: 2018,
    make: 'Rolls-Royce',
    models: 'Ghost,Dawn,Phantom,Wraith',
  },
  {
    year: 2018,
    make: 'Smart',
    models: 'Forfour,Fortwo',
  },
  {
    year: 2018,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2018,
    make: 'Subaru',
    models: 'Crosstrek,Legacy,BRZ,Impreza,WRX STI,Forester,Outback,XV,WRX',
  },
  {
    year: 2018,
    make: 'Suzuki',
    models: 'Ciaz,Swift,S-Cross,Vitara,Ignis',
  },
  {
    year: 2018,
    make: 'Toyota',
    models:
      'Avalon,Camry,Yaris iA,Yaris R,Sienna,86,Hilux,Hiace,Prius Prime,Yaris,Prius V,Prius C,Tacoma,Highlander,Mirai,Prius,Tundra,Corolla,Land Cruiser,Avanza,C-HR,Corolla iM,RAV4,Sequoia,4Runner',
  },
  {
    year: 2018,
    make: 'Volkswagen',
    models:
      'Polo,Caddy,Crafter,Tiguan,Golf Alltrack,Gol,Vento,Atlas,Amarok,Golf R,Crafter Van,Saveiro,Tiguan Limited,Beetle,GTI,Golf,e-Golf,Golf SportWagen,Passat,Up!,Transporter,Touareg,Jetta',
  },
  {
    year: 2018,
    make: 'Volvo',
    models: 'V90,XC90,V40 Cross Country,S60,V40,V90 Cross Country,V60,V60 Cross Country,XC60,S90,S60 Cross Country',
  },
  {
    year: 2017,
    make: 'Acura',
    models: 'ILX,MDX,RDX,TLX,RLX,NSX',
  },
  {
    year: 2017,
    make: 'Alfa Romeo',
    models: 'Giulietta,Giulia,Mito,4C,Stelvio',
  },
  {
    year: 2017,
    make: 'Aston Martin',
    models: 'Rapide,V8 Vantage,V12 Vantage,DB11,Vanquish',
  },
  {
    year: 2017,
    make: 'Audi',
    models:
      'A4 allroad,S7,TTS Quattro,Q5,RS3,A7,R8,A4,S3,A3 Sportback e-tron,A7 Quattro,A3 Quattro,S5,Q3 Quattro,A6 Quattro,A4 Quattro,Q3,Q7,S4,S6,TT Quattro,RS7,RS Q3,A5 Quattro,S8,A3,TT,A6,A1,SQ5,A8 Quattro',
  },
  {
    year: 2017,
    make: 'Bentley',
    models: 'Flying Spur,Continental,Mulsanne,Bentayga',
  },
  {
    year: 2017,
    make: 'BMW',
    models:
      '535i GT xDrive,550i GT xDrive,220i,M4,M5,328d,535i GT,330i GT xDrive,120i,650i xDrive,Alpina B6 xDrive Gran Coupe,328d xDrive,530i xDrive,750i,640i Gran Coupe,340i xDrive,X4,750i xDrive,440i xDrive,M140i,430i,330i,340i,530i,430i xDrive,750Li,M2,320i xDrive,M240i,Alpina B7,X5,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,330e,M760Li xDrive,650i,X3,X6,M6,320i,230i,430i xDrive Gran Coupe,640i xDrive,740e xDrive,M760i xDrive,740i xDrive,420i,740i,640i,X1,750Li xDrive,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,650i Gran Coupe,i3,540i,M3,i8,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,740Le xDrive,650i xDrive Gran Coupe,540i xDrive',
  },
  {
    year: 2017,
    make: 'Buick',
    models: 'LaCrosse,Verano,Envision,Encore,Enclave,Regal,Cascada',
  },
  {
    year: 2017,
    make: 'Cadillac',
    models: 'CTS,Escalade,XT5,CT6,XTS,ATS,Escalade ESV',
  },
  {
    year: 2017,
    make: 'Chevrolet',
    models:
      'Volt,Silverado 1500,Impala,Spark Classic,Sonic,Aveo,Silverado 2500,Tahoe,Spark,Suburban,Cruze,Caprice,Corvette,Silverado 2500 HD,Cheyenne,LCF 3500HD,Camaro,Tornado,Colorado,City Express,SS,Malibu,Equinox,Traverse,Trax,LCF 3500,S10,Express 2500,Express 3500,Bolt EV',
  },
  {
    year: 2017,
    make: 'Chrysler',
    models: '200,300,Pacifica',
  },
  {
    year: 2017,
    make: 'Dodge',
    models: 'Journey,Neon,Challenger,Charger,Vision,Viper,Durango,Grand Caravan,Attitude',
  },
  {
    year: 2017,
    make: 'Ferrari',
    models: '488 Spider,California T,F12 Berlinetta,488 GTB,GTC4Lusso,LaFerrari,F12tdf',
  },
  {
    year: 2017,
    make: 'Fiat',
    models: 'Ducato,500X,500L,Uno,124 Spider,500,Mobi,Palio',
  },
  {
    year: 2017,
    make: 'Ford',
    models:
      'Explorer,Expedition,Mustang,EcoSport,Transit,C-Max,Fusion,Escape,F-250 Super Duty,Focus,Figo,Taurus,Flex,Transit-150,GT,Police Interceptor Sedan,Ranger,Transit Connect,F-150,Edge,Transit-250,Fiesta,Special Service Police Sedan,Lobo,Transit Custom,Police Interceptor Utility',
  },
  {
    year: 2017,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2017,
    make: 'Genesis',
    models: 'G90,G80',
  },
  {
    year: 2017,
    make: 'GMC',
    models: 'Acadia,Savana 2500,Yukon XL,Sierra,Terrain,Sierra 2500 HD,Acadia Limited,Canyon,Sierra 1500,Yukon',
  },
  {
    year: 2017,
    make: 'Honda',
    models: 'Accord,CR-V,Fit,Clarity,HR-V,Pilot,Civic,City,Odyssey,Ridgeline',
  },
  {
    year: 2017,
    make: 'Hyundai',
    models:
      'Elantra GT,Azera,Creta,Ioniq,Elantra,Santa Fe,H300,Tucson,H200,HD55,Santa Fe XL,Santa Fe Sport,Veloster,Grand i10,Sonata,Accent',
  },
  {
    year: 2017,
    make: 'Infiniti',
    models: 'Q60,Q70L,Q70,QX50,QX60,QX80,QX30,QX70,Q50',
  },
  {
    year: 2017,
    make: 'JAC',
    models: 'SEI 2,SEI 3',
  },
  {
    year: 2017,
    make: 'Jaguar',
    models: 'XF,XE,F-Type,XJR,XJ,F-Pace',
  },
  {
    year: 2017,
    make: 'Jeep',
    models: 'Cherokee,Patriot,Wrangler,Renegade,Grand Cherokee,Compass',
  },
  {
    year: 2017,
    make: 'Karma',
    models: 'Revero',
  },
  {
    year: 2017,
    make: 'Kia',
    models: 'Rio,Niro,Sorento,K900,Soul EV,Forte,Forte Koup,Optima,Cadenza,Forte5,Sportage,Rondo,Sedona,Soul',
  },
  {
    year: 2017,
    make: 'Lamborghini',
    models: 'Aventador,Centenario,Huracan',
  },
  {
    year: 2017,
    make: 'Land Rover',
    models: 'Discovery,Discovery Sport,Range Rover Evoque,Range Rover,Range Rover Sport',
  },
  {
    year: 2017,
    make: 'Lexus',
    models:
      'NX300h,IS350,IS300,RC350,GX460,RX450h,ES350,CT200h,LS460,RC200t,GS F,NX200t,IS200t,GS350,RC300,ES300h,RX350,LX570,RC F,GS200t,GS450h',
  },
  {
    year: 2017,
    make: 'Lincoln',
    models: 'MKC,Navigator,MKT,Continental,MKX,MKZ',
  },
  {
    year: 2017,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2017,
    make: 'Maserati',
    models: 'GranTurismo,GranCabrio,Quattroporte,Ghibli,Levante',
  },
  {
    year: 2017,
    make: 'Mazda',
    models: '3 Sport,CX-9,3,CX-5,5,6,MX-5 Miata,CX-3,MX-5,2',
  },
  {
    year: 2017,
    make: 'McLaren',
    models: '540C,675LT,570S,570GT,650S',
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    models:
      'Maybach S600,Metris,S63L AMG,A200,CLS550,G550,G550 4x4,SLC300,V220d,GLE400,GLE550,GLA45 AMG,GLA200,C250,GLS500,Maybach S550,S500L,C180,E300,GLS450,GLE550e,C63 AMG S,S600L,S65L AMG,C63 AMG,AMG GT S,SL450,V250,S65 AMG,E200,G500,GLS550,C350e,SL65 AMG,A250,CLA200,G65 AMG,S400L,GLA180,C300,SL500,GLE43 AMG,CLA180,SLC180,GLE300d,GLE450 AMG,E550,GLA250,CLS400,G63 AMG,S500,CLA250,CLS500,A45,C200,SL550,GLS63 AMG,SLC43 AMG,B250,GLE63 AMG,E400,Sprinter,GLC300,GLE350,GLE63 AMG S,S550e,SL63 AMG,CLA45 AMG,Sprinter 2500,S63 AMG,AMG GT,E43 AMG,C43 AMG,S550,E250,E500,A45 AMG,GLE500,CLS63 AMG S,S600,GLC43 AMG,GLC250,GLS350d,SLC200,B250e',
  },
  {
    year: 2017,
    make: 'Mini',
    models: 'Cooper Clubman,Cooper Countryman,Cooper',
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    models: 'RVR,Mirage,Outlander Sport,Mirage G4,Lancer,L200,Montero,Outlander,i-MiEV',
  },
  {
    year: 2017,
    make: 'Mobility Ventures',
    models: 'MV-1',
  },
  {
    year: 2017,
    make: 'Nissan',
    models:
      'Quest,Murano,Pathfinder,NV2500,GT-R,Tsuru,Armada,NV350 Urvan,Rogue Sport,NP300,Altima,Qashqai,Rogue,Titan XD,NP300 Frontier,Versa Note,Kicks,March,Maxima,NV200,Juke,Versa,Titan,Leaf,Note,Micra,370Z,NT400 Cabstar,Sentra,NV1500,Tiida,X-Trail,Frontier',
  },
  {
    year: 2017,
    make: 'Peugeot',
    models: '208 GT,3008,508,Partner,208,301,308,Manager',
  },
  {
    year: 2017,
    make: 'Porsche',
    models: 'Panamera,Cayenne,718 Boxster,Macan,911,718 Cayman',
  },
  {
    year: 2017,
    make: 'Ram',
    models: 'ProMaster Rapid,ProMaster City,1500,2500,ProMaster 1500,ProMaster 2500',
  },
  {
    year: 2017,
    make: 'Rolls-Royce',
    models: 'Wraith,Ghost,Phantom,Dawn',
  },
  {
    year: 2017,
    make: 'Smart',
    models: 'Forfour,Fortwo',
  },
  {
    year: 2017,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2017,
    make: 'Subaru',
    models: 'Forester,Outback,WRX,Crosstrek,Legacy,BRZ,Impreza,WRX STI',
  },
  {
    year: 2017,
    make: 'Suzuki',
    models: 'Ignis,Ciaz,Grand Vitara,Swift,S-Cross,Vitara',
  },
  {
    year: 2017,
    make: 'Toyota',
    models:
      'Mirai,Prius,Tundra,Corolla,Land Cruiser,Avanza,Corolla iM,RAV4,Sequoia,4Runner,Yaris R,Avalon,Camry,Yaris iA,Sienna,86,Hilux,Hiace,Prius Prime,Yaris,Prius V,Prius C,Tacoma,Highlander',
  },
  {
    year: 2017,
    make: 'Volkswagen',
    models:
      'Golf,e-Golf,Tiguan Limited,Golf SportWagen,Passat,Up!,Transporter,Touareg,Jetta,Polo,Crafter,Golf R,Tiguan,Golf Alltrack,CrossFox,Gol,Vento,Amarok,Crafter Van,Caddy,Saveiro,Beetle,GTI,CC',
  },
  {
    year: 2017,
    make: 'Volvo',
    models: 'S90,S60 Cross Country,XC90,V40 Cross Country,S60,V40,V90 Cross Country,V60,V60 Cross Country,XC60',
  },
  {
    year: 2016,
    make: 'Acura',
    models: 'ILX,MDX,RDX,TLX,RLX',
  },
  {
    year: 2016,
    make: 'Alfa Romeo',
    models: '4C,Giulietta,Mito',
  },
  {
    year: 2016,
    make: 'Aston Martin',
    models: 'V12 Vantage,Vanquish,DB9,Rapide,V8 Vantage',
  },
  {
    year: 2016,
    make: 'Audi',
    models:
      'A5 Quattro,S8,A3,TT,A6,A8 Quattro,A1,S4,SQ5,A4 allroad,S7,Q5,A7,R8,A4,S3,A3 Sportback e-tron,A7 Quattro,A8,TTS Quattro,A5,A3 Quattro,S5,Q3 Quattro,A6 Quattro,allroad,A4 Quattro,RS5,Q3,Q7,S6,TT Quattro,RS7',
  },
  {
    year: 2016,
    make: 'Bentley',
    models: 'Flying Spur,Mulsanne,Continental',
  },
  {
    year: 2016,
    make: 'BMW',
    models:
      '528i,X3,X6,M6,320i,640i xDrive,Alpina B6 Gran Coupe,228i,528i xDrive,535i xDrive,420i,740i,428i xDrive Gran Coupe,228i xDrive,640i,X1,640i xDrive Gran Coupe,435i xDrive,650i Gran Coupe,ActiveHybrid 5,550i xDrive,420i Gran Coupe,520i,i3,M3,i8,650i xDrive Gran Coupe,428i xDrive,535i GT xDrive,550i GT xDrive,220i,M4,428i Gran Coupe,M235i xDrive,M5,328d,535i GT,328i xDrive,120i,435i,550i,328i,650i xDrive,Alpina B6 xDrive Gran Coupe,335i GT xDrive,328d xDrive,535d xDrive,750i,428i,640i Gran Coupe,340i xDrive,535i,435i Gran Coupe,X4,750i xDrive,328i GT xDrive,535d,330i,340i,M135i,Z4,750Li,M2,320i xDrive,M235i,435i xDrive Gran Coupe,X5,M6 Gran Coupe,330e,650i',
  },
  {
    year: 2016,
    make: 'Buick',
    models: 'Encore,Enclave,Regal,Cascada,LaCrosse,Verano,Envision',
  },
  {
    year: 2016,
    make: 'Cadillac',
    models: 'ATS,Escalade ESV,SRX,CTS,Escalade,CT6,ELR,XTS',
  },
  {
    year: 2016,
    make: 'Chevrolet',
    models:
      'Colorado,City Express,SS,Malibu,Equinox,Traverse,Malibu Limited,Spark EV,Trax,LCF 3500,S10,Express 2500,Express 3500,Cruze Limited,Volt,Silverado 1500,Impala,Suburban,Spark Classic,Sonic,Aveo,Tahoe,Spark,Cruze,Impala Limited,Caprice,Corvette,Silverado 2500 HD,Cheyenne,LCF 3500HD,Camaro,Silverado 2500,Tornado',
  },
  {
    year: 2016,
    make: 'Chrysler',
    models: '200,300,Town & Country',
  },
  {
    year: 2016,
    make: 'Dodge',
    models: 'Viper,Durango,Grand Caravan,Attitude,Journey,Challenger,Charger,Vision,Dart',
  },
  {
    year: 2016,
    make: 'Ferrari',
    models: '488 GTB,F12tdf,LaFerrari,FF,488 Spider,California T,F12 Berlinetta',
  },
  {
    year: 2016,
    make: 'Fiat',
    models: '500,500X,500L,Palio,Ducato,Uno',
  },
  {
    year: 2016,
    make: 'Ford',
    models:
      'Transit Custom,Ranger,Transit Connect,F-150,Edge,Transit-250,Fiesta,Lobo,Police Interceptor Utility,Explorer,Expedition,Mustang,Transit,Special Service Police Sedan,C-Max,Fusion,Escape,F-250 Super Duty,Focus,Figo,Taurus,Flex,Transit-150,EcoSport,Police Interceptor Sedan',
  },
  {
    year: 2016,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2016,
    make: 'GMC',
    models: 'Canyon,Sierra 1500,Yukon,Acadia,Savana 2500,Yukon XL,Sierra,Terrain,Sierra 2500 HD',
  },
  {
    year: 2016,
    make: 'Honda',
    models: 'Civic,Odyssey,Accord,CR-V,Fit,HR-V,CR-Z,Pilot,City',
  },
  {
    year: 2016,
    make: 'Hyundai',
    models:
      'Santa Fe XL,Santa Fe Sport,Veloster,Grand i10,Sonata,Accent,Equus,Elantra GT,Genesis Coupe,Azera,Elantra,Genesis,Santa Fe,H300,Tucson,H200,HD55',
  },
  {
    year: 2016,
    make: 'Infiniti',
    models: 'QX70,Q50,Q60,Q70L,Q70,QX50,QX60,QX80',
  },
  {
    year: 2016,
    make: 'Jaguar',
    models: 'XJ,XF,XE,F-Type,XJR',
  },
  {
    year: 2016,
    make: 'Jeep',
    models: 'Grand Cherokee,Compass,Cherokee,Patriot,Wrangler,Renegade',
  },
  {
    year: 2016,
    make: 'Kia',
    models: 'Sportage,Rondo,Sedona,Soul,Rio,Sorento,K900,Soul EV,Forte,Forte Koup,Optima,Cadenza,Forte5',
  },
  {
    year: 2016,
    make: 'Lamborghini',
    models: 'Huracan,Aventador',
  },
  {
    year: 2016,
    make: 'Land Rover',
    models: 'Range Rover,LR4,Range Rover Sport,Discovery,Discovery Sport,Range Rover Evoque,Defender',
  },
  {
    year: 2016,
    make: 'Lexus',
    models:
      'RX350,LS600h,LX570,RC F,GS200t,GS450h,NX300h,IS350,IS300,RC350,GX460,RX450h,ES350,CT200h,LS460,RC200t,GS F,NX200t,IS200t,GS350,RC300,ES300h',
  },
  {
    year: 2016,
    make: 'Lincoln',
    models: 'MKX,MKZ,MKC,MKS,Navigator,MKT',
  },
  {
    year: 2016,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2016,
    make: 'Maserati',
    models: 'GranCabrio,GranTurismo,Quattroporte,Ghibli',
  },
  {
    year: 2016,
    make: 'Mazda',
    models: 'CX-3,MX-5,5,2,CX-9,3,CX-5,3 Sport,6,MX-5 Miata',
  },
  {
    year: 2016,
    make: 'McLaren',
    models: '650S,570S,675LT',
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    models:
      'GLE63 AMG,C63 AMG S,GL350,E400,SL65 AMG,SLK350,CLA200,GLC300,GLE350,GLE63 AMG S,S550e,SLK300,SL63 AMG,CLA45 AMG,S63 AMG,S550,E250,A45 AMG,GLE500,GLA250,A180,CLS63 AMG S,S500,S600,E500,B250e,Maybach S600,Metris,C450 AMG,S63L AMG,GLE350d,CLS550,G550,V220d,GLE400,GLE550,GLA45 AMG,GLA200,SL400,C250,E350,Sprinter,C180,SLK55 AMG,GLE550e,S600L,S65L AMG,C63 AMG,AMG GT S,CLS500,S65 AMG,E200,G500,C350e,G65 AMG,S400L,GLA180,A200,C300,SL500,CLA180,GL450,GLE300d,GL550,GLE450 AMG,E550,CLS400,Sprinter 2500,E63 AMG,E63 AMG S,G63 AMG,GL63 AMG,CLA250,C200,SLK200,SL550,E300,B250',
  },
  {
    year: 2016,
    make: 'Mini',
    models: 'Cooper,Cooper Paceman,Cooper Clubman,Cooper Countryman',
  },
  {
    year: 2016,
    make: 'Mitsubishi',
    models: 'Outlander,i-MiEV,RVR,Mirage,Outlander Sport,Montero,Lancer,L200',
  },
  {
    year: 2016,
    make: 'Mobility Ventures',
    models: 'MV-1',
  },
  {
    year: 2016,
    make: 'Nissan',
    models:
      'Juke,Versa,Leaf,Note,Micra,370Z,Sentra,NV1500,Tiida,Frontier,Tsuru,Quest,Murano,Pathfinder,NV2500,GT-R,NV350 Urvan,NP300,X-Trail,Altima,Rogue,Titan XD,NP300 Frontier,Versa Note,March,Maxima,NV200',
  },
  {
    year: 2016,
    make: 'Peugeot',
    models: '308,Manager,208 GT,RCZ,3008,508,Partner,2008,208,301',
  },
  {
    year: 2016,
    make: 'Porsche',
    models: '911,Cayman,Boxster,Panamera,Cayenne,Macan',
  },
  {
    year: 2016,
    make: 'Ram',
    models: '1500,ProMaster 1500,ProMaster 2500,2500,ProMaster City',
  },
  {
    year: 2016,
    make: 'Rolls-Royce',
    models: 'Wraith,Dawn,Ghost,Phantom',
  },
  {
    year: 2016,
    make: 'Scion',
    models: 'tC,iA,iM,FR-S',
  },
  {
    year: 2016,
    make: 'Smart',
    models: 'Fortwo,Forfour',
  },
  {
    year: 2016,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2016,
    make: 'Subaru',
    models: 'Legacy,BRZ,Impreza,XV,WRX STI,Forester,Outback,WRX,Crosstrek',
  },
  {
    year: 2016,
    make: 'Suzuki',
    models: 'Kizashi,S-Cross,Vitara,Ciaz,Grand Vitara,Swift',
  },
  {
    year: 2016,
    make: 'Toyota',
    models:
      'Sienna,Yaris R,Hiace,Yaris,Prius V,Prius C,Tacoma,Highlander,Venza,Mirai,Prius,Tundra,Corolla,Land Cruiser,Hilux,RAV4,Sequoia,4Runner,Avalon,Camry,Avanza',
  },
  {
    year: 2016,
    make: 'Volkswagen',
    models:
      'Vento,Amarok,Crafter Van,Saveiro,Beetle,GTI,CC,Golf,e-Golf,Caddy,Golf SportWagen,Passat,Eos,Nuevo Gol,Up!,Transporter,Touareg,Jetta,Crafter,Polo,Golf R,Tiguan,CrossFox,Gol',
  },
  {
    year: 2016,
    make: 'Volvo',
    models: 'S80,V40,V60,V60 Cross Country,XC60,S60 Cross Country,XC70,XC90,V40 Cross Country,S60',
  },
  {
    year: 2015,
    make: 'Acura',
    models: 'MDX,RDX,TLX,RLX,ILX',
  },
  {
    year: 2015,
    make: 'Alfa Romeo',
    models: '4C,Giulietta,Mito',
  },
  {
    year: 2015,
    make: 'Aston Martin',
    models: 'V8 Vantage,V12 Vantage,Vanquish,DB9,Rapide',
  },
  {
    year: 2015,
    make: 'Audi',
    models:
      'A7 Quattro,A8,A5,S5,Q3 Quattro,A6 Quattro,allroad,A4 Quattro,RS5,Q3,Q7,S6,TTS Quattro,TT Quattro,RS7,A5 Quattro,S8,A3,A6,A8 Quattro,A1,S4,SQ5,A4 allroad,S7,Q5,A3 Quattro,R8,A4,S3',
  },
  {
    year: 2015,
    make: 'Bentley',
    models: 'Mulsanne,Continental,Flying Spur',
  },
  {
    year: 2015,
    make: 'BMW',
    models:
      '535i,435i Gran Coupe,X4,750i xDrive,328i GT xDrive,i8,535d,ActiveHybrid 3,M135i,760Li,750Li xDrive,Z4,Alpina B7L,740Li,Alpina B7 xDrive,750Li,320i xDrive,M235i,435i xDrive Gran Coupe,X5,Alpina B7L xDrive,M6 Gran Coupe,650i,528i,X3,X6,M6,320i,640i xDrive,118i,228i,528i xDrive,535i xDrive,740i,428i xDrive Gran Coupe,228i xDrive,640i,X1,640i xDrive Gran Coupe,435i xDrive,650i Gran Coupe,ActiveHybrid 5,550i xDrive,520i,i3,M3,550i GT,740Ld xDrive,650i xDrive Gran Coupe,428i xDrive,535i GT xDrive,335i,550i GT xDrive,220i,M4,428i Gran Coupe,M235i xDrive,M5,328d,535i GT,328i xDrive,335i xDrive,435i,740Li xDrive,550i,328i,650i xDrive,Alpina B7,Alpina B6 xDrive Gran Coupe,335i GT xDrive,328d xDrive,535d xDrive,750i,428i,ActiveHybrid 7,640i Gran Coupe',
  },
  {
    year: 2015,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2015,
    make: 'Buick',
    models: 'Verano,Encore,Enclave,Regal,LaCrosse',
  },
  {
    year: 2015,
    make: 'Cadillac',
    models: 'XTS,ELR,ATS,Escalade ESV,SRX,CTS,Escalade',
  },
  {
    year: 2015,
    make: 'Chevrolet',
    models:
      'Cruze,Impala Limited,Caprice,Corvette,Silverado 2500 HD,Camaro,Silverado 2500,Cheyenne,Tornado,City Express,SS,Malibu,Equinox,Captiva Sport,Traverse,Spark EV,Matiz,Trax,Express 2500,Express 3500,Volt,Colorado,Silverado 1500,Impala,Suburban,Sonic,Aveo,Tahoe,Spark',
  },
  {
    year: 2015,
    make: 'Chrysler',
    models: '200,300,Town & Country',
  },
  {
    year: 2015,
    make: 'Dodge',
    models: 'Challenger,Charger,Vision,Dart,Viper,Durango,Grand Caravan,Attitude,Journey',
  },
  {
    year: 2015,
    make: 'Ferrari',
    models: 'LaFerrari,California T,F12 Berlinetta,458 Spider,458 Italia,458 Speciale,FF',
  },
  {
    year: 2015,
    make: 'Fiat',
    models: 'Uno,500,500L,Palio,Ducato',
  },
  {
    year: 2015,
    make: 'Ford',
    models:
      'Special Service Police Sedan,Lobo,C-Max,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex,Transit-150,Fiesta Ikon,EcoSport,Police Interceptor Sedan,Transit Custom,Ranger,Transit Connect,F-150,Edge,Transit-250,Fiesta,Police Interceptor Utility,Explorer,Expedition,Mustang,Transit',
  },
  {
    year: 2015,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2015,
    make: 'GMC',
    models: 'Yukon XL,Sierra,Terrain,Sierra 2500 HD,Canyon,Sierra 1500,Yukon,Acadia,Savana 2500',
  },
  {
    year: 2015,
    make: 'Honda',
    models: 'CR-Z,Pilot,City,Civic,Odyssey,Accord,CR-V,Fit,Crosstour',
  },
  {
    year: 2015,
    make: 'Hyundai',
    models:
      'Elantra,Genesis,Santa Fe,Tucson,Santa Fe XL,Santa Fe Sport,Veloster,Grand i10,Sonata,Accent,Equus,Elantra GT,Genesis Coupe,Azera,ix35',
  },
  {
    year: 2015,
    make: 'Infiniti',
    models: 'Q70L,Q70,QX50,QX60,QX80,Q40,QX70,Q50,Q60',
  },
  {
    year: 2015,
    make: 'Jaguar',
    models: 'XK,F-Type,XJR,XFR-S,XKR-S,XKR,XF,XFR,XJ',
  },
  {
    year: 2015,
    make: 'Jeep',
    models: 'Patriot,Wrangler,Renegade,Grand Cherokee,Compass,Cherokee',
  },
  {
    year: 2015,
    make: 'Kia',
    models: 'K900,Soul EV,Forte,Forte Koup,Optima,Cadenza,Forte5,Sportage,Rondo,Sedona,Soul,Rio,Sorento',
  },
  {
    year: 2015,
    make: 'Lamborghini',
    models: 'Huracan,Aventador',
  },
  {
    year: 2015,
    make: 'Land Rover',
    models: 'LR2,Range Rover Evoque,Defender,Range Rover,LR4,Range Rover Sport,Discovery,Discovery Sport',
  },
  {
    year: 2015,
    make: 'Lexus',
    models:
      'CT200h,LS460,NX200t,GS350,ES300h,RX350,LS600h,LX570,RC F,IS250,GS450h,NX300h,IS350,RC350,GX460,RX450h,ES350',
  },
  {
    year: 2015,
    make: 'Lincoln',
    models: 'MKC,MKS,Navigator,MKT,MKX,MKZ',
  },
  {
    year: 2015,
    make: 'Maserati',
    models: 'GranCabrio,GranTurismo,Quattroporte,Ghibli',
  },
  {
    year: 2015,
    make: 'Mazda',
    models: '6,MX-5 Miata,MX-5,3 Sport,2,5,CX-9,3,CX-5',
  },
  {
    year: 2015,
    make: 'McLaren',
    models: 'P1,650S',
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    models:
      'C63 AMG,S400,CLS500,S65 AMG,GLK300,Sprinter,E200,G65 AMG,A200,C300,SL500,CLA180,ML350,GL450,ML550,GL550,E550,GLA45 AMG,CLS400,C400,E63 AMG,E63 AMG S,G63 AMG,GL63 AMG,CLA250,C200,SLK200,SL550,E300,B250,S550e,GL350,E400,SLS AMG,GLK350,SLK250,S500L,G500,SL65 AMG,SLK350,CLA200,SL63 AMG,CLA45 AMG,S63 AMG,Viano,S550,E250,B180,C350,ML500,A45 AMG,C63 AMG S,GLA250,ML250,Sprinter 2500,A180,CLS63 AMG S,S500,S600,E500,ML400,ML63 AMG,CLS550,G550,GL500,GLA200,B Electric Drive,SL400,C250,GLK250,E350,C180,SLK55 AMG',
  },
  {
    year: 2015,
    make: 'Mini',
    models: 'Cooper Countryman,Cooper,Cooper Paceman',
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    models: 'Montero Sport,Lancer,L200,ASX,Outlander,RVR,Mirage,Outlander Sport,Montero',
  },
  {
    year: 2015,
    make: 'Mobility Ventures',
    models: 'MV-1',
  },
  {
    year: 2015,
    make: 'Nissan',
    models:
      'NP300,X-Trail,Altima,Titan,Rogue,Versa Note,March,Maxima,NV200,Juke,Rogue Select,Versa,Leaf,Note,Armada,Micra,370Z,Tsuru,Sentra,NV1500,Tiida,Frontier,Quest,Murano,Pathfinder,Xterra,NV2500,GT-R,NV350 Urvan',
  },
  {
    year: 2015,
    make: 'Peugeot',
    models: 'RCZ,3008,508,Partner,2008,208,301,Manager,208 GT',
  },
  {
    year: 2015,
    make: 'Porsche',
    models: 'Macan,911,Cayman,Boxster,Panamera,Cayenne,918 Spyder',
  },
  {
    year: 2015,
    make: 'Ram',
    models: 'ProMaster City,1500,C/V,ProMaster 1500,ProMaster 2500,2500',
  },
  {
    year: 2015,
    make: 'Rolls-Royce',
    models: 'Ghost,Phantom,Wraith',
  },
  {
    year: 2015,
    make: 'Scion',
    models: 'xB,FR-S,tC,iQ',
  },
  {
    year: 2015,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2015,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2015,
    make: 'Subaru',
    models: 'WRX,XV Crosstrek,Legacy,BRZ,Impreza,XV,WRX STI,Forester,Outback',
  },
  {
    year: 2015,
    make: 'Suzuki',
    models: 'Grand Vitara,Swift,Kizashi,S-Cross',
  },
  {
    year: 2015,
    make: 'Toyota',
    models:
      'RAV4,Sequoia,4Runner,Avalon,Camry,Sienna,Hiace,Yaris,Prius V,Prius C,Tacoma,Highlander,Venza,Prius,Tundra,Corolla,Land Cruiser,Hilux,Prius Plug-In,Avanza',
  },
  {
    year: 2015,
    make: 'Volkswagen',
    models:
      'Transporter,Touareg,Jetta,Polo,Caddy,Golf R,Tiguan,CrossFox,Vento,Amarok,Crafter Van,Saveiro,Beetle,Clasico,GTI,CC,Golf,e-Golf,Golf SportWagen,Passat,Eos,Nuevo Gol',
  },
  {
    year: 2015,
    make: 'Volvo',
    models: 'XC70,V40 Cross Country,S60,S80,V40,V60,V60 Cross Country,XC60',
  },
  {
    year: 2014,
    make: 'Acura',
    models: 'ILX,MDX,RDX,TL,RLX,TSX',
  },
  {
    year: 2014,
    make: 'Alfa Romeo',
    models: 'Giulietta',
  },
  {
    year: 2014,
    make: 'Aston Martin',
    models: 'DB9,Rapide,V8 Vantage,Vanquish',
  },
  {
    year: 2014,
    make: 'Audi',
    models:
      'S4,TT RS Quattro,SQ5,A4 allroad,S7,Q5,R8,A4,S3,A7 Quattro,A5,TT,S5,A6 Quattro,allroad,A4 Quattro,RS5,Q3,Q7,S6,TT Quattro,RS7,A5 Quattro,TTS Quattro,S8,A3,A6,A8 Quattro,A1',
  },
  {
    year: 2014,
    make: 'Bentley',
    models: 'Mulsanne,Continental,Flying Spur',
  },
  {
    year: 2014,
    make: 'BMW',
    models:
      '550i xDrive,520i,i3,550i GT,428i xDrive,535i GT xDrive,335i,550i GT xDrive,220i,235i,M5,328d,535i GT,328i xDrive,335i xDrive,435i,740Li xDrive,550i,328i,650i xDrive,Alpina B7,335i GT xDrive,328d xDrive,535d xDrive,750i,428i,ActiveHybrid 7,640i Gran Coupe,535i,750i xDrive,328i GT xDrive,535d,650i xDrive Gran Coupe,ActiveHybrid 3,M135i,760Li,750Li xDrive,Z4,Alpina B7L,740Li,Alpina B7 xDrive,750Li,320i xDrive,M235i,X5,i8,Alpina B7L xDrive,M6 Gran Coupe,650i,528i,X3,X6,M6,320i,640i xDrive,118i,228i,528i xDrive,535i xDrive,740i,640i,X1,640i xDrive Gran Coupe,435i xDrive,650i Gran Coupe,ActiveHybrid 5',
  },
  {
    year: 2014,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2014,
    make: 'Buick',
    models: 'LaCrosse,Verano,Encore,Enclave,Regal',
  },
  {
    year: 2014,
    make: 'Cadillac',
    models: 'SRX,ELR,CTS,Escalade,XTS,ATS,Escalade ESV',
  },
  {
    year: 2014,
    make: 'Chevrolet',
    models:
      'Suburban 1500,Express 2500,Express 3500,Orlando,Volt,Silverado 1500,Impala,Suburban,Sonic,Aveo,Tahoe,Spark,Colorado,Express 1500,Cruze,Impala Limited,Caprice,Corvette,Silverado 2500 HD,Camaro,Silverado 2500,Cheyenne,Tornado,SS,Malibu,Equinox,Captiva Sport,Traverse,Spark EV,Matiz,Trax',
  },
  {
    year: 2014,
    make: 'Chrysler',
    models: '200,300,Town & Country',
  },
  {
    year: 2014,
    make: 'Dodge',
    models: 'Attitude,Journey,Challenger,Charger,Dart,i10,Avenger,Durango,Grand Caravan',
  },
  {
    year: 2014,
    make: 'Ferrari',
    models: '458 Italia,FF,LaFerrari,F12 Berlinetta,458 Spider,California',
  },
  {
    year: 2014,
    make: 'Fiat',
    models: '500L,Palio,Ducato,Uno,Strada,500',
  },
  {
    year: 2014,
    make: 'Ford',
    models:
      'F-150,Edge,Fiesta,Econoline Wagon,Police Interceptor Utility,Explorer,Expedition,Mustang,Special Service Police Sedan,Transit,Lobo,C-Max,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex,Fiesta Ikon,EcoSport,E-150,Police Interceptor Sedan,Transit Custom,Ranger,Transit Connect,Econoline Van,E-250',
  },
  {
    year: 2014,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2014,
    make: 'GMC',
    models: 'Sierra 1500,Yukon,Acadia,Savana 2500,Savana 1500,Yukon XL 1500,Sierra,Terrain,Sierra 2500 HD',
  },
  {
    year: 2014,
    make: 'Honda',
    models: 'Accord,CR-V,Ridgeline,Fit,Crosstour,CR-Z,Insight,City,Pilot,Civic,Odyssey',
  },
  {
    year: 2014,
    make: 'Hyundai',
    models:
      'Equus,Elantra Coupe,Santa Fe XL,Elantra GT,Genesis Coupe,Azera,Elantra,Genesis,Santa Fe,Tucson,Santa Fe Sport,Veloster,Sonata,Accent',
  },
  {
    year: 2014,
    make: 'Infiniti',
    models: 'Q60,Q70,QX50,QX60,QX80,QX70,Q50',
  },
  {
    year: 2014,
    make: 'Jaguar',
    models: 'XFR-S,XKR-S,XKR,XF,XFR,XJ,XK,F-Type,XJR',
  },
  {
    year: 2014,
    make: 'Jeep',
    models: 'Compass,Cherokee,Patriot,Wrangler,Grand Cherokee',
  },
  {
    year: 2014,
    make: 'Kia',
    models: 'Rondo,Sedona,Soul,Rio,Sorento,Forte,Forte Koup,Optima,Cadenza,Forte5,Sportage',
  },
  {
    year: 2014,
    make: 'Lamborghini',
    models: 'Aventador,Gallardo',
  },
  {
    year: 2014,
    make: 'Land Rover',
    models: 'LR4,Range Rover Sport,Discovery,LR2,Range Rover Evoque,Defender,Range Rover',
  },
  {
    year: 2014,
    make: 'Lexus',
    models: 'GS450h,IS350,GX460,RX450h,ES350,CT200h,IS F,LS460,GS350,ES300h,RX350,LS600h,LX570,IS250',
  },
  {
    year: 2014,
    make: 'Lincoln',
    models: 'MKZ,MKS,Navigator,MKT,MKX,Mark LT',
  },
  {
    year: 2014,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2014,
    make: 'Maserati',
    models: 'GranTurismo,Quattroporte,Ghibli,GranCabrio',
  },
  {
    year: 2014,
    make: 'Mazda',
    models: '5,CX-9,3,CX-5,6,MX-5 Miata,MX-5,2,3 Sport',
  },
  {
    year: 2014,
    make: 'McLaren',
    models: 'MP4-12C,P1',
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    models:
      'A45 AMG,Sprinter 2500,CL65 AMG,A180,CLS63 AMG S,S500,E500,A250,E400,ML63 AMG,CLS550,G550,GL500,GLA200,B Electric Drive,C250,GLK250,E350,C180,SLK55 AMG,C63 AMG,S400,CLS500,GLK300,Sprinter,CLS350,E200,E300,G65 AMG,CL63 AMG,A200,C300,SL500,ML350,GL450,ML550,GL550,E550,CL550,CLS63 AMG,E63 AMG,E63 AMG S,G63 AMG,GL63 AMG,CLA250,C200,SLK200,SL550,B250,GL350,SLS AMG,GLK350,SLK250,G500,SL65 AMG,SLK350,CLA200,GLA250,GLA45 AMG,SL63 AMG,CLA45 AMG,S63 AMG,Viano,CL600,S550,E250,B180,C350,ML500',
  },
  {
    year: 2014,
    make: 'Mini',
    models: 'Cooper Paceman,Cooper Countryman,Cooper',
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    models: 'Outlander,RVR,Mirage,Outlander Sport,Montero,Montero Sport,Lancer,L200,ASX,i-MiEV',
  },
  {
    year: 2014,
    make: 'Mobility Ventures',
    models: 'MV-1',
  },
  {
    year: 2014,
    make: 'Nissan',
    models:
      'Sentra,NV1500,Tiida,Frontier,Quest,Murano,Pathfinder,Xterra,NV2500,GT-R,NV350 Urvan,NP300,X-Trail,Altima,Titan,Rogue,Versa Note,March,Maxima,NV200,Juke,Rogue Select,Versa,Leaf,Note,Armada,Cube,Tsuru,370Z',
  },
  {
    year: 2014,
    make: 'Peugeot',
    models: 'Manager,208 GT,207,RCZ,308,2008,3008,508,208,Partner,301',
  },
  {
    year: 2014,
    make: 'Porsche',
    models: 'Cayman,Boxster,Panamera,Cayenne,911',
  },
  {
    year: 2014,
    make: 'Ram',
    models: '2500,1500,C/V,ProMaster 1500,ProMaster 2500',
  },
  {
    year: 2014,
    make: 'Rolls-Royce',
    models: 'Phantom,Wraith,Ghost',
  },
  {
    year: 2014,
    make: 'Scion',
    models: 'iQ,xB,FR-S,tC,xD',
  },
  {
    year: 2014,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2014,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2014,
    make: 'SRT',
    models: 'Viper',
  },
  {
    year: 2014,
    make: 'Subaru',
    models: 'Impreza,XV,WRX STI,Tribeca,Forester,Outback,WRX,XV Crosstrek,Legacy,BRZ',
  },
  {
    year: 2014,
    make: 'Suzuki',
    models: 'S-Cross,Grand Vitara,Swift,Kizashi,SX4',
  },
  {
    year: 2014,
    make: 'Toyota',
    models:
      'FJ Cruiser,Tacoma,Highlander,Venza,Prius,Tundra,Corolla,Land Cruiser,Hilux,Prius Plug-In,RAV4,Sequoia,4Runner,Avanza,Avalon,Camry,Matrix,Prius V,Sienna,Hiace,Yaris,Prius C',
  },
  {
    year: 2014,
    make: 'Volkswagen',
    models:
      'Beetle,Clasico,GTI,CC,Golf,Passat,Eos,Nuevo Gol,Transporter,Touareg,Jetta,Polo,Caddy,Tiguan,CrossFox,Crafter Van,Vento,Amarok,Routan,Saveiro',
  },
  {
    year: 2014,
    make: 'Volvo',
    models: 'XC60,XC90,XC70,V40 Cross Country,S60,S80,V40,V60',
  },
  {
    year: 2013,
    make: 'Acura',
    models: 'TL,ZDX,TSX,ILX,MDX,RDX',
  },
  {
    year: 2013,
    make: 'Alfa Romeo',
    models: 'Mito,Giulietta',
  },
  {
    year: 2013,
    make: 'Aston Martin',
    models: 'V8 Vantage,DB9,Rapide,V12 Vantage',
  },
  {
    year: 2013,
    make: 'Audi',
    models:
      'S6,TT Quattro,A5 Quattro,S8,A3,A3 Quattro,A6,A8 Quattro,A1,S4,TTS Quattro,A4 allroad,S7,Q5,A4,A7 Quattro,TT RS Quattro,A5,TT,S5,A6 Quattro,allroad,A4 Quattro,RS5,Q3,Q7',
  },
  {
    year: 2013,
    make: 'Bentley',
    models: 'Mulsanne,Continental,Flying Spur',
  },
  {
    year: 2013,
    make: 'BMW',
    models:
      'M3,X1,X5,335is,Alpina B7L xDrive,650i xDrive Gran Coupe,650i,528i,X3,X6,320i,118i,528i xDrive,535i xDrive,640i,125i,650i Gran Coupe,ActiveHybrid 5,550i xDrive,135i,M135i,550i GT,535i GT xDrive,335i,550i GT xDrive,535i GT,328i xDrive,335i xDrive,135is,740Li xDrive,550i,328i,740i,128i,650i xDrive,Alpina B7,750i,ActiveHybrid 7,640i Gran Coupe,530i,535i,750i xDrive,325i,ActiveHybrid 3,760Li,M5,M6,750Li xDrive,Z4,Alpina B7L,740Li,Alpina B7 xDrive,750Li,320i xDrive',
  },
  {
    year: 2013,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2013,
    make: 'Buick',
    models: 'Encore,Enclave,Regal,LaCrosse,Verano',
  },
  {
    year: 2013,
    make: 'Cadillac',
    models: 'XTS,ATS,Escalade ESV,Escalade EXT,SRX,CTS,Escalade',
  },
  {
    year: 2013,
    make: 'Chevrolet',
    models:
      'Camaro,Silverado 2500,Spark,Cheyenne,Tornado,Colorado,Malibu,Equinox,Traverse,Cruze,Avalanche,Matiz,Trax,Suburban 1500,Express 2500,Express 3500,Orlando,Volt,Silverado 1500,Impala,Captiva Sport,Sonic,Aveo,Tahoe,Express 1500,Suburban 2500,Caprice,Corvette,Silverado 2500 HD',
  },
  {
    year: 2013,
    make: 'Chrysler',
    models: '200,300,Town & Country',
  },
  {
    year: 2013,
    make: 'Dodge',
    models: 'Dart,i10,Avenger,Durango,Grand Caravan,Attitude,Journey,Challenger,Charger',
  },
  {
    year: 2013,
    make: 'Ferrari',
    models: '458 Spider,California,458 Italia,FF,F12 Berlinetta',
  },
  {
    year: 2013,
    make: 'Fiat',
    models: 'Strada,500,Palio,Uno,Ducato,Punto',
  },
  {
    year: 2013,
    make: 'Ford',
    models:
      'Fiesta Ikon,EcoSport,E-150,Police Interceptor Sedan,Ranger,Transit Connect,Econoline Van,E-250,F-150,Edge,Fiesta,Econoline Wagon,Police Interceptor Utility,Explorer,Expedition,Mustang,Transit,Lobo,C-Max,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex',
  },
  {
    year: 2013,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2013,
    make: 'GMC',
    models:
      'Sierra,Terrain,Sierra 2500 HD,Yukon XL 2500,Sierra 1500,Yukon,Acadia,Savana 2500,Savana 1500,Yukon XL 1500',
  },
  {
    year: 2013,
    make: 'Honda',
    models: 'Civic,Odyssey,Accord,CR-V,Ridgeline,City,Fit,Crosstour,CR-Z,Insight,Pilot',
  },
  {
    year: 2013,
    make: 'Hyundai',
    models:
      'Tucson,Santa Fe Sport,Veloster,Sonata,Accent,Equus,Elantra GT,Genesis Coupe,Azera,Elantra Coupe,Santa Fe XL,Elantra,Genesis,Santa Fe',
  },
  {
    year: 2013,
    make: 'Infiniti',
    models: 'FX50,M37,M35h,FX37,G37,JX35,EX37,QX56,M56',
  },
  {
    year: 2013,
    make: 'Jaguar',
    models: 'XFR-S,XKR-S,XKR,XF,XFR,XJ,XK',
  },
  {
    year: 2013,
    make: 'Jeep',
    models: 'Grand Cherokee,Liberty,Compass,Patriot,Wrangler',
  },
  {
    year: 2013,
    make: 'Kia',
    models: 'Optima,Forte5,Sportage,Soul,Rio,Sorento,Forte,Forte Koup',
  },
  {
    year: 2013,
    make: 'Lamborghini',
    models: 'Aventador,Gallardo',
  },
  {
    year: 2013,
    make: 'Land Rover',
    models: 'Defender,Range Rover,LR4,Range Rover Sport,LR2,Range Rover Evoque',
  },
  {
    year: 2013,
    make: 'Lexus',
    models: 'GS350,ES300h,RX350,LS600h,LX570,IS250,GS450h,IS350,GX460,RX450h,ES350,CT200h,IS F,LS460',
  },
  {
    year: 2013,
    make: 'Lincoln',
    models: 'MKT,MKX,Mark LT,MKZ,MKS,Navigator',
  },
  {
    year: 2013,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2013,
    make: 'Maserati',
    models: 'GranTurismo,Quattroporte,GranCabrio',
  },
  {
    year: 2013,
    make: 'Mazda',
    models: '6,MX-5 Miata,MX-5,2,3 Sport,5,CX-9,3,CX-5',
  },
  {
    year: 2013,
    make: 'McLaren',
    models: 'MP4-12C',
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    models:
      'GL450,ML550,GL550,E550,CL550,SL65 AMG,CLS63 AMG,E63 AMG,G63 AMG,S600,GL63 AMG,CLA250,C200,SLK200,SL550,E300,R350,B250,GL350,Sprinter 2500,SLS AMG,GLK350,SLK250,CLS500,G500,SLK350,SL63 AMG,S63 AMG,Viano,CL600,S550,E250,B180,C350,ML500,CL65 AMG,A200,S65 AMG,GL500,E500,ML63 AMG,CLS550,G550,C250,GLK250,E350,GLK300,C180,SLK55 AMG,A250,CLA200,C63 AMG,E400,S400,Vito,Sprinter,CLS350,E200,S350,CL63 AMG,A180,C300,S500,SL500,CL500,ML350',
  },
  {
    year: 2013,
    make: 'Mini',
    models: 'Cooper,Cooper Paceman,Cooper Countryman',
  },
  {
    year: 2013,
    make: 'Mitsubishi',
    models: 'ASX,Outlander,RVR,Outlander Sport,Montero,Montero Sport,Lancer,L200',
  },
  {
    year: 2013,
    make: 'Nissan',
    models:
      'Rogue,March,Maxima,NV200,Juke,Versa,Leaf,Armada,Cube,Tsuru,370Z,Sentra,NV1500,Urvan,Tiida,Frontier,Quest,Murano,Pathfinder,Xterra,NV2500,GT-R,NP300,X-Trail,Altima,Titan',
  },
  {
    year: 2013,
    make: 'Peugeot',
    models: 'Partner,301,Manager,207,RCZ,308,3008,508',
  },
  {
    year: 2013,
    make: 'Porsche',
    models: '911,Boxster,Panamera,Cayenne',
  },
  {
    year: 2013,
    make: 'Ram',
    models: '1500,C/V,2500',
  },
  {
    year: 2013,
    make: 'Rolls-Royce',
    models: 'Ghost,Phantom',
  },
  {
    year: 2013,
    make: 'Scion',
    models: 'tC,xD,iQ,xB,FR-S',
  },
  {
    year: 2013,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2013,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2013,
    make: 'SRT',
    models: 'Viper',
  },
  {
    year: 2013,
    make: 'Subaru',
    models: 'XV Crosstrek,Legacy,WRX,BRZ,Impreza,XV,WRX STI,Tribeca,Forester,Outback',
  },
  {
    year: 2013,
    make: 'Suzuki',
    models: 'Kizashi,SX4,Grand Vitara,Swift',
  },
  {
    year: 2013,
    make: 'Toyota',
    models:
      'Prius V,Avanza,Sienna,Hiace,Yaris,Prius C,FJ Cruiser,Tacoma,Highlander,Venza,Tundra,Corolla,Land Cruiser,Prius,Hilux,Prius Plug-In,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix',
  },
  {
    year: 2013,
    make: 'Volkswagen',
    models:
      'CrossFox,Golf R,Amarok,Routan,Golf SportWagen,Gol,Multivan,Saveiro,Beetle,Clasico,GTI,CC,Golf,Passat,Eos,Transporter,Touareg,Jetta,Gol Sedan,Polo,Crafter Van,Tiguan',
  },
  {
    year: 2013,
    make: 'Volvo',
    models: 'S60,S80,V60,C30,XC60,XC90,C70,XC70,V40 Cross Country',
  },
  {
    year: 2012,
    make: 'Acura',
    models: 'MDX,RDX,TL,RL,ZDX,TSX',
  },
  {
    year: 2012,
    make: 'Alfa Romeo',
    models: '159,Mito,Giulietta',
  },
  {
    year: 2012,
    make: 'Aston Martin',
    models: 'V12 Vantage,DBS,Virage,V8 Vantage,DB9,Rapide',
  },
  {
    year: 2012,
    make: 'Audi',
    models:
      'A4,A7 Quattro,A5,TT,S5,A6 Quattro,A4 Quattro,Q7,TT Quattro,A5 Quattro,TTS Quattro,TT RS Quattro,A3,A3 Quattro,R8,A6,A8 Quattro,A1,S3,S4,Q5',
  },
  {
    year: 2012,
    make: 'Bentley',
    models: 'Mulsanne,Continental',
  },
  {
    year: 2012,
    make: 'BMW',
    models:
      '328i,740i,128i,650i xDrive,Alpina B7,750i,530i,535i,750i xDrive,M6,325i,760Li,750Li xDrive,Z4,Alpina B7L,740Li,Alpina B7 xDrive,750Li,M3,X1,X5,335is,Alpina B7L xDrive,650i,528i,X3,X6,M5,320i,118i,528i xDrive,535i xDrive,640i,ActiveHybrid 5,550i xDrive,135i,550i GT,120i,535i GT xDrive,335i,550i GT xDrive,535i GT,328i xDrive,335i xDrive,125i,550i',
  },
  {
    year: 2012,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2012,
    make: 'Buick',
    models: 'Verano,Enclave,Regal,LaCrosse',
  },
  {
    year: 2012,
    make: 'Cadillac',
    models: 'Escalade,Escalade ESV,Escalade EXT,SRX,CTS',
  },
  {
    year: 2012,
    make: 'Chevrolet',
    models:
      'Impala,Matiz,Captiva Sport,Sonic,Aveo,Tahoe,Express 1500,Suburban 2500,Caprice,Corvette,Silverado 2500 HD,Camaro,Silverado 2500,Cheyenne,Tornado,Colorado,Malibu,Equinox,Traverse,Cruze,Avalanche,Spark,Chevy,Suburban 1500,Express 2500,Express 3500,Orlando,Volt,Silverado 1500',
  },
  {
    year: 2012,
    make: 'Chrysler',
    models: '300,Town & Country,200',
  },
  {
    year: 2012,
    make: 'Dodge',
    models: 'Nitro,Caliber,Challenger,Charger,i10,Atos,Avenger,Durango,Grand Caravan,Dakota,Attitude,Journey',
  },
  {
    year: 2012,
    make: 'Ferrari',
    models: 'FF,458 Spider,California,458 Italia',
  },
  {
    year: 2012,
    make: 'Fiat',
    models: '500,Panda,Ducato,Strada',
  },
  {
    year: 2012,
    make: 'Ford',
    models:
      'Mustang,Transit,Lobo,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex,Courier,Fiesta Ikon,EcoSport,E-150,Ranger,Transit Connect,Econoline Van,E-250,F-150,Edge,Fiesta,Econoline Wagon,Explorer,Expedition',
  },
  {
    year: 2012,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2012,
    make: 'GMC',
    models:
      'Savana 1500,Yukon XL 1500,Sierra,Terrain,Sierra 2500 HD,Yukon XL 2500,Sierra 1500,Yukon,Acadia,Canyon,Savana 2500',
  },
  {
    year: 2012,
    make: 'Honda',
    models: 'Crosstour,CR-Z,Insight,Pilot,Civic,Odyssey,Accord,CR-V,Ridgeline,City,Fit',
  },
  {
    year: 2012,
    make: 'Hyundai',
    models: 'Elantra,Genesis,Santa Fe,Tucson,Veracruz,Veloster,Sonata,Accent,Equus,Genesis Coupe,Azera',
  },
  {
    year: 2012,
    make: 'Infiniti',
    models: 'EX35,FX35,G25,QX56,M56,FX50,M37,M35h,G37',
  },
  {
    year: 2012,
    make: 'Jaguar',
    models: 'XJ,XK,XKR-S,XKR,XF,XFR',
  },
  {
    year: 2012,
    make: 'Jeep',
    models: 'Patriot,Wrangler,Grand Cherokee,Liberty,Compass',
  },
  {
    year: 2012,
    make: 'Kia',
    models: 'Sorento,Forte,Forte Koup,Sedona,Forte5,Optima,Sportage,Rondo,Soul,Rio',
  },
  {
    year: 2012,
    make: 'Lamborghini',
    models: 'Gallardo,Aventador',
  },
  {
    year: 2012,
    make: 'Land Rover',
    models: 'LR2,Range Rover Evoque,Defender,Range Rover,LR4,Range Rover Sport',
  },
  {
    year: 2012,
    make: 'Lexus',
    models: 'GX460,RX450h,ES350,CT200h,IS F,LS460,HS250h,RX350,LS600h,IS250,LFA,IS350',
  },
  {
    year: 2012,
    make: 'Lincoln',
    models: 'MKS,Navigator,MKT,MKX,Mark LT,MKZ',
  },
  {
    year: 2012,
    make: 'Lotus',
    models: 'Evora',
  },
  {
    year: 2012,
    make: 'Maserati',
    models: 'GranTurismo,Quattroporte',
  },
  {
    year: 2012,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2012,
    make: 'Mazda',
    models: 'CX-9,3,6,MX-5 Miata,MX-5,2,3 Sport,CX-7,5',
  },
  {
    year: 2012,
    make: 'McLaren',
    models: 'MP4-12C',
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    models:
      'C250,E350,C180,G500,SLK55 AMG,C63 AMG,S400,CLS350,E200,ML500,S350,CL63 AMG,C300,SL500,CL500,ML350,GL450,ML550,GL550,E550,CL550,CLS63 AMG,E63 AMG,S600,C200,SLK200,SL550,R350,GL350,SLS AMG,GLK350,SLK250,Vito,GL500,SLK350,SL63 AMG,S63 AMG,CL600,S550,E250,B180,E300,C350,SL65 AMG,CL65 AMG,CLS500,S65 AMG,GLK300,Sprinter 2500,ML63 AMG,CLS550,G550,SL600,Sprinter,E500',
  },
  {
    year: 2012,
    make: 'Mini',
    models: 'Cooper Countryman,Cooper',
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    models: 'Outlander Sport,Montero,i-MiEV,Montero Sport,Lancer,L200,Galant,Eclipse,Outlander,RVR',
  },
  {
    year: 2012,
    make: 'Nissan',
    models:
      'Xterra,NV2500,GT-R,NP300,X-Trail,Altima,Titan,Rogue,March,Maxima,Juke,Versa,Leaf,Armada,Cube,Tsuru,370Z,Sentra,NV1500,Urvan,Tiida,Frontier,Quest,Murano,Pathfinder',
  },
  {
    year: 2012,
    make: 'Peugeot',
    models: '207,RCZ,308,3008,508,Partner,Manager,Grand Raid',
  },
  {
    year: 2012,
    make: 'Porsche',
    models: 'Cayman,911,Boxster,Panamera,Cayenne',
  },
  {
    year: 2012,
    make: 'Ram',
    models: '2500,1500,C/V',
  },
  {
    year: 2012,
    make: 'Rolls-Royce',
    models: 'Ghost,Phantom',
  },
  {
    year: 2012,
    make: 'Scion',
    models: 'xB,tC,xD,iQ',
  },
  {
    year: 2012,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2012,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2012,
    make: 'Subaru',
    models: 'Forester,Outback,Legacy,Impreza,WRX,Tribeca',
  },
  {
    year: 2012,
    make: 'Suzuki',
    models: 'Grand Vitara,Swift,Equator,Kizashi,SX4,SX4 Crossover',
  },
  {
    year: 2012,
    make: 'Toyota',
    models:
      'Hilux,Prius Plug-In,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Prius V,Sienna,Hiace,Yaris,Prius C,Avanza,FJ Cruiser,Tacoma,Highlander,Venza,Tundra,Corolla,Prius',
  },
  {
    year: 2012,
    make: 'Volkswagen',
    models:
      'Passat,Eos,Crafter Van,Transporter,Touareg,Jetta,Passat CC,Beetle,Gol Sedan,Tiguan,CrossFox,Amarok,Routan,Golf SportWagen,Gol,Saveiro,Golf R,Clasico,GTI,CC,Golf',
  },
  {
    year: 2012,
    make: 'Volvo',
    models: 'C70,XC70,S60,S40,S80,C30,XC60,XC90',
  },
  {
    year: 2012,
    make: 'VPG',
    models: 'MV-1',
  },
  {
    year: 2011,
    make: 'Acura',
    models: 'TSX,CSX,MDX,RDX,TL,RL,ZDX',
  },
  {
    year: 2011,
    make: 'Aston Martin',
    models: 'DB9,Rapide,One-77,DBS,V8 Vantage,V12 Vantage',
  },
  {
    year: 2011,
    make: 'Audi',
    models:
      'R8,A6,A8 Quattro,A1,S3,S4,TTS Quattro,Q5,RS5,S6,A4,TT RS Quattro,A5,TT,S5,A6 Quattro,A4 Quattro,Q7,TT Quattro,A5 Quattro,A3,A3 Quattro',
  },
  {
    year: 2011,
    make: 'Bentley',
    models: 'Mulsanne,Continental',
  },
  {
    year: 2011,
    make: 'BMW',
    models:
      '550i xDrive,135i,1 Series M,550i GT,120i,535i GT xDrive,335i,X1,328i xDrive,335i xDrive,550i,328i,740i,128i,Alpina B7,750i,650Ci,535i,750i xDrive,M6,325i,125i,550i GT xDrive,760Li,750Li xDrive,Z4,335d,535i GT,Alpina B7L,323i,740Li,Alpina B7 xDrive,750Li,M3,X5,335is,Alpina B7L xDrive,528i,X3,X6,535i xDrive',
  },
  {
    year: 2011,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2011,
    make: 'Buick',
    models: 'Enclave,Regal,LaCrosse,Lucerne',
  },
  {
    year: 2011,
    make: 'Cadillac',
    models: 'Escalade EXT,SRX,CTS,Escalade,STS,DTS,Escalade ESV',
  },
  {
    year: 2011,
    make: 'Chevrolet',
    models:
      'Chevy,Captiva Sport,Suburban 1500,Express 2500,Express 3500,Volt,Silverado 1500,Impala,Matiz,Aveo,Tahoe,Express 1500,Suburban 2500,Caprice,Corvette,Silverado 2500 HD,HHR,Camaro,Silverado 2500,Spark,Cheyenne,Tornado,Colorado,Malibu,Equinox,Aveo5,Traverse,Cruze,Avalanche',
  },
  {
    year: 2011,
    make: 'Chrysler',
    models: '200,300,Town & Country',
  },
  {
    year: 2011,
    make: 'Dodge',
    models: 'Avenger,Durango,Grand Caravan,Attitude,Journey,Nitro,Caliber,Dakota,Challenger,Charger,Atos',
  },
  {
    year: 2011,
    make: 'Ferrari',
    models: '458 Italia,599 GTB,612 Scaglietti,California,599 GTO,FF',
  },
  {
    year: 2011,
    make: 'Fiat',
    models: 'Palio,Albea,Panda,500,Ducato,Linea,Strada',
  },
  {
    year: 2011,
    make: 'Ford',
    models:
      'Ranger,Transit Connect,Econoline Van,E-250,F-150,Edge,Econoline Wagon,Explorer,Expedition,Mustang,Transit,Fiesta,Lobo,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex,Courier,Fiesta Ikon,EcoSport,Crown Victoria,E-150',
  },
  {
    year: 2011,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2011,
    make: 'GMC',
    models:
      'Sierra 1500,Yukon,Acadia,Canyon,Savana 2500,Savana 1500,Yukon XL 1500,Sierra,Terrain,Sierra 2500 HD,Yukon XL 2500',
  },
  {
    year: 2011,
    make: 'Honda',
    models: 'Odyssey,Accord,CR-V,Ridgeline,City,Fit,CR-Z,Insight,Element,Pilot,Accord Crosstour,Civic',
  },
  {
    year: 2011,
    make: 'Hyundai',
    models: 'Sonata,Accent,Equus,Genesis Coupe,Azera,Elantra,Genesis,Santa Fe,Tucson,Veracruz',
  },
  {
    year: 2011,
    make: 'Infiniti',
    models: 'G37,EX35,FX35,G25,QX56,M56,FX50,M37',
  },
  {
    year: 2011,
    make: 'Jaguar',
    models: 'XKR,XF,XFR,XJ,XK',
  },
  {
    year: 2011,
    make: 'Jeep',
    models: 'Grand Cherokee,Liberty,Compass,Patriot,Wrangler',
  },
  {
    year: 2011,
    make: 'Kia',
    models: 'Soul,Rio,Rondo,Sorento,Forte,Forte Koup,Sedona,Optima,Rio5,Sportage,Borrego',
  },
  {
    year: 2011,
    make: 'Lamborghini',
    models: 'Gallardo',
  },
  {
    year: 2011,
    make: 'Land Rover',
    models: 'LR4,Range Rover Sport,LR2,Defender,Range Rover',
  },
  {
    year: 2011,
    make: 'Lexus',
    models: 'IS250,IS350,GS350,GX460,RX450h,ES350,CT200h,IS F,LS460,LX570,GS460,GS450h,HS250h,RX350,LS600h',
  },
  {
    year: 2011,
    make: 'Lincoln',
    models: 'Mark LT,MKZ,Town Car,MKS,Navigator,MKT,MKX',
  },
  {
    year: 2011,
    make: 'Lotus',
    models: 'Evora,Elise,Exige',
  },
  {
    year: 2011,
    make: 'Maserati',
    models: 'GranCabrio,GranTurismo,Quattroporte',
  },
  {
    year: 2011,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2011,
    make: 'Mazda',
    models: '2,Tribute,CX-7,3 Sport,CX-9,3,6,MX-5 Miata,RX-8,MX-5',
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    models:
      'S63 AMG,B200,CL600,S550,E250,C350,CL65 AMG,S65 AMG,GLK300,SL550,Sprinter 2500,ML63 AMG,CLS550,G550,SL600,Sprinter,SL63 AMG,C250,S500,E350,C180,G55 AMG,SLK55 AMG,S350,C63 AMG,SLK300,S400,CLS350,E300,E500,G500,ML450,CL63 AMG,C300,SL500,CL500,ML350,GL450,ML550,GL550,E550,CL550,SL65 AMG,CLS63 AMG,E63 AMG,S600,C200,SLK200,R350,ML500,GL350,SLS AMG,GLK350,S450,CLS500,Vito,GL500,SLK350',
  },
  {
    year: 2011,
    make: 'Mercury',
    models: 'Mariner,Milan,Grand Marquis',
  },
  {
    year: 2011,
    make: 'Mini',
    models: 'Cooper,Cooper Countryman',
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    models: 'Eclipse,Endeavor,Outlander,RVR,Outlander Sport,Montero,Montero Sport,Lancer,L200,Galant',
  },
  {
    year: 2011,
    make: 'Nissan',
    models:
      'Leaf,Armada,Cube,Tsuru,370Z,Sentra,Urvan,Tiida,Frontier,Quest,Murano,Pathfinder,Xterra,GT-R,NP300,X-Trail,Altima,Titan,Rogue,Maxima,Juke,Versa',
  },
  {
    year: 2011,
    make: 'Peugeot',
    models: 'Manager,207 Compact,Grand Raid,207,RCZ,308,3008,Partner',
  },
  {
    year: 2011,
    make: 'Porsche',
    models: '911,Boxster,Panamera,Cayenne,Cayman',
  },
  {
    year: 2011,
    make: 'Ram',
    models: 'Dakota,2500,1500',
  },
  {
    year: 2011,
    make: 'Rolls-Royce',
    models: 'Ghost,Phantom',
  },
  {
    year: 2011,
    make: 'Saab',
    models: '9-3X,9-4X,9-3,9-5',
  },
  {
    year: 2011,
    make: 'Scion',
    models: 'xB,tC,xD',
  },
  {
    year: 2011,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2011,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2011,
    make: 'Subaru',
    models: 'Impreza,Tribeca,Forester,Outback,Legacy',
  },
  {
    year: 2011,
    make: 'Suzuki',
    models: 'SX4,Grand Vitara,Swift,Equator,Kizashi',
  },
  {
    year: 2011,
    make: 'Think',
    models: 'City',
  },
  {
    year: 2011,
    make: 'Toyota',
    models:
      'Hiace,Yaris,FJ Cruiser,Tacoma,Highlander,Venza,Tundra,Corolla,Prius,Hilux,Avanza,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Sienna,Land Cruiser',
  },
  {
    year: 2011,
    make: 'Volkswagen',
    models:
      'Golf SportWagen,Gol,Saveiro,Clasico,GTI,CC,Golf,Eos,Crafter Van,Transporter,Touareg,Jetta,Passat CC,Gol Sedan,Beetle Cabrio,Tiguan,CrossFox,Beetle,Amarok,Routan',
  },
  {
    year: 2011,
    make: 'Volvo',
    models: 'S80,XC60,XC90,C70,XC70,C30,S60,S40,V50',
  },
  {
    year: 2011,
    make: 'VPG',
    models: 'MV-1',
  },
  {
    year: 2010,
    make: 'Acura',
    models: 'TL,RL,ZDX,TSX,CSX,MDX,RDX',
  },
  {
    year: 2010,
    make: 'Aston Martin',
    models: 'V8 Vantage,V12 Vantage,DB9,Rapide,DBS',
  },
  {
    year: 2010,
    make: 'Audi',
    models:
      'S5,A6 Quattro,A4 Quattro,Q7,TT Quattro,A5 Quattro,A3,A3 Quattro,R8,A6,A8 Quattro,RS6,S3,S4,S8,Q5,S6,A4,TTS Quattro,A5,TT',
  },
  {
    year: 2010,
    make: 'Bentley',
    models: 'Continental,Azure',
  },
  {
    year: 2010,
    make: 'BMW',
    models:
      '760Li,750Li xDrive,Z4,335d,323i,750Li,M3,X5,550i GT xDrive,525i,X3,740i,X6,M6,535i GT,535i xDrive,530i,135i,550i GT,120i,335i,528i,M5,X1,328i xDrive,335i xDrive,130i,125i,550i,328i,128i,750i,650Ci,650i,535i,750i xDrive,325i,528i xDrive',
  },
  {
    year: 2010,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2010,
    make: 'Buick',
    models: 'Lucerne,Enclave,LaCrosse,Allure',
  },
  {
    year: 2010,
    make: 'Cadillac',
    models: 'STS,DTS,Escalade ESV,Escalade EXT,SRX,CTS,Escalade',
  },
  {
    year: 2010,
    make: 'Chevrolet',
    models:
      'Corvette,Silverado 2500 HD,Optra,HHR,Camaro,Cheyenne,Tornado,Colorado,Malibu,Equinox,Aveo5,Traverse,Avalanche,Cobalt,Chevy,Captiva Sport,Silverado 2500,Suburban 1500,Express 2500,Express 3500,Silverado 1500,Impala,Aveo,Tahoe,Cruze,Express 1500,Suburban 2500',
  },
  {
    year: 2010,
    make: 'Chrysler',
    models: 'Cirrus,Sebring,PT Cruiser,300,Town & Country',
  },
  {
    year: 2010,
    make: 'Dodge',
    models:
      'Ram 1500,Challenger,Charger,Ram 2500,Atos,Avenger,Grand Caravan,Viper,Dakota,H100,Attitude,Journey,Nitro,Caliber',
  },
  {
    year: 2010,
    make: 'Ferrari',
    models: '612 Scaglietti,California,458 Italia,599 GTB',
  },
  {
    year: 2010,
    make: 'Fiat',
    models: 'Strada,500,Palio,Albea',
  },
  {
    year: 2010,
    make: 'Ford',
    models:
      'F-250 Super Duty,Focus,Taurus,Flex,Courier,Explorer Sport Trac,Fiesta,EcoSport,Crown Victoria,E-150,Ranger,Transit Connect,Econoline Van,E-250,F-150,Edge,Econoline Wagon,Explorer,Expedition,Mustang,Transit,Lobo,Fusion,Escape',
  },
  {
    year: 2010,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2010,
    make: 'GMC',
    models:
      'Sierra,Terrain,Sierra 2500 HD,Yukon XL 2500,Sierra 1500,Yukon,Acadia,Canyon,Savana 2500,Savana 1500,Yukon XL 1500',
  },
  {
    year: 2010,
    make: 'Honda',
    models: 'Element,Pilot,Accord Crosstour,Civic,Odyssey,Accord,CR-V,Ridgeline,City,Fit,Insight',
  },
  {
    year: 2010,
    make: 'Hummer',
    models: 'H3,H3T',
  },
  {
    year: 2010,
    make: 'Hyundai',
    models: 'Genesis,Santa Fe,Tucson,Veracruz,Sonata,Accent,Genesis Coupe,Azera,Entourage,Elantra',
  },
  {
    year: 2010,
    make: 'Infiniti',
    models: 'QX56,M45,FX50,G37,M35,EX35,FX35',
  },
  {
    year: 2010,
    make: 'Jaguar',
    models: 'XJ,XKR,XF,XFR,XK',
  },
  {
    year: 2010,
    make: 'Jeep',
    models: 'Wrangler,Commander,Grand Cherokee,Liberty,Compass,Patriot',
  },
  {
    year: 2010,
    make: 'Kia',
    models: 'Forte,Forte Koup,Rondo,Sedona,Optima,Rio5,Sportage,Borrego,Soul,Magentis,Rio',
  },
  {
    year: 2010,
    make: 'Lamborghini',
    models: 'Gallardo,Murcielago',
  },
  {
    year: 2010,
    make: 'Land Rover',
    models: 'LR2,Range Rover,LR4,Range Rover Sport',
  },
  {
    year: 2010,
    make: 'Lexus',
    models: 'LX570,GS460,GS450h,HS250h,RX350,LS600h,SC430,IS250,IS350,GS350,GX460,RX450h,ES350,IS F,LS460',
  },
  {
    year: 2010,
    make: 'Lincoln',
    models: 'MKT,MKX,Mark LT,MKZ,Town Car,MKS,Navigator',
  },
  {
    year: 2010,
    make: 'Lotus',
    models: 'Evora,Elise,Exige',
  },
  {
    year: 2010,
    make: 'Maserati',
    models: 'GranCabrio,GranTurismo,Quattroporte',
  },
  {
    year: 2010,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2010,
    make: 'Mazda',
    models: '6,3 Sport,MX-5 Miata,RX-8,MX-5,5,Tribute,CX-7,B2300,B4000,CX-9,3',
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    models:
      'SL63 AMG,ML450,CL63 AMG,C300,SL500,CL500,ML350,GL450,ML550,GL550,E550,CL550,CLS63 AMG,E63 AMG,C200,SLK200,E500,R350,GL350,S450,CLS500,Vito,GL500,SLK350,SL65 AMG,S63 AMG,B200,CL600,S550,E300,C350,ML500,CL65 AMG,S600,GLK300,ML63 AMG,CLS550,GLK350,G550,SL600,Sprinter,Sprinter 2500,C250,S500,E350,SL550,G55 AMG,SLK55 AMG,C63 AMG,SLK300,S400,S65 AMG,CLS350,G500',
  },
  {
    year: 2010,
    make: 'Mercury',
    models: 'Grand Marquis,Mountaineer,Mariner,Milan',
  },
  {
    year: 2010,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    models: 'L200,Galant,Eclipse,Endeavor,Outlander,Montero,Montero Sport,Lancer',
  },
  {
    year: 2010,
    make: 'Nissan',
    models:
      'Titan,Rogue,Maxima,Versa,Armada,Cube,Tsuru,370Z,Sentra,Urvan,Tiida,Pickup,Frontier,Murano,Pathfinder,Xterra,GT-R,Platina,Aprio,NP300,X-Trail,Altima',
  },
  {
    year: 2010,
    make: 'Peugeot',
    models: '308,307,Partner,Grand Raid,Manager,207 Compact,207',
  },
  {
    year: 2010,
    make: 'Pontiac',
    models: 'G6,G5,Matiz,Vibe,G3',
  },
  {
    year: 2010,
    make: 'Porsche',
    models: 'Cayman,911,Boxster,Panamera,Cayenne',
  },
  {
    year: 2010,
    make: 'Ram',
    models: '2500',
  },
  {
    year: 2010,
    make: 'Rolls-Royce',
    models: 'Phantom,Ghost',
  },
  {
    year: 2010,
    make: 'Saab',
    models: '9-3,9-5,9-3X',
  },
  {
    year: 2010,
    make: 'Saturn',
    models: 'Sky,Outlook,Vue',
  },
  {
    year: 2010,
    make: 'Scion',
    models: 'tC,xD,xB',
  },
  {
    year: 2010,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2010,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2010,
    make: 'Subaru',
    models: 'Legacy,Impreza,Tribeca,Forester,Outback',
  },
  {
    year: 2010,
    make: 'Suzuki',
    models: 'Grand Vitara,Swift,Equator,Kizashi,SX4,Swift+',
  },
  {
    year: 2010,
    make: 'Toyota',
    models:
      'Avanza,Avalon,Camry,Matrix,Sienna,Land Cruiser,Hiace,Yaris,FJ Cruiser,Tacoma,Highlander,Venza,Tundra,Corolla,Prius,Hilux,RAV4,Sequoia,4Runner',
  },
  {
    year: 2010,
    make: 'Volkswagen',
    models:
      'Beetle Cabrio,GLI,Routan,Golf SportWagen,Passat,Gol,Saveiro,SportVan,Beetle,Bora,GTI,CC,Golf,Eos,Crafter Van,Transporter,Touareg,Jetta,Golf City,Tiguan,Passat CC,Gol Sedan',
  },
  {
    year: 2010,
    make: 'Volvo',
    models: 'V70,C70,V50,S80,XC60,S40,XC90,XC70,C30',
  },
  {
    year: 2009,
    make: 'Acura',
    models: 'CSX,MDX,RDX,TL,RL,TSX',
  },
  {
    year: 2009,
    make: 'Aston Martin',
    models: 'V8 Vantage,DBS,DB9',
  },
  {
    year: 2009,
    make: 'Audi',
    models:
      'TTS Quattro,Q5,TT,A6,A4,A5,S5,A6 Quattro,A4 Quattro,Q7,A5 Quattro,A3,A3 Quattro,R8,A8 Quattro,S3,S4,S6,TT Quattro,S8',
  },
  {
    year: 2009,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage,Brooklands',
  },
  {
    year: 2009,
    make: 'BMW',
    models:
      '335i,528i,M5,328i xDrive,335i xDrive,130i,125i,550i,328i,128i,323i,750i,650Ci,650i,535i,325i,528i xDrive,760Li,Z4,335d,750Li,M3,X5,525i,X3,X6,M6,535i xDrive,530i,135i,120i',
  },
  {
    year: 2009,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2009,
    make: 'Buick',
    models: 'LaCrosse,Allure,Lucerne,Enclave',
  },
  {
    year: 2009,
    make: 'Cadillac',
    models: 'SRX,CTS,XLR,Escalade,STS,DTS,Escalade ESV,Escalade EXT',
  },
  {
    year: 2009,
    make: 'Chevrolet',
    models:
      'Silverado 1500,Impala,Aveo,Silverado 2500,Tahoe,Cheyenne,Captiva Sport,Express 1500,Suburban 2500,Aveo5,Corvette,Silverado 2500 HD,Optra,HHR,Uplander,Tornado,Colorado,Malibu,Equinox,Traverse,Avalanche,Cobalt,Suburban 1500,Chevy,Trailblazer,Express 2500,Express 3500,Epica',
  },
  {
    year: 2009,
    make: 'Chrysler',
    models: 'PT Cruiser,300,Town & Country,Aspen,Cirrus,Sebring',
  },
  {
    year: 2009,
    make: 'Dodge',
    models:
      'Journey,Nitro,Caliber,Ram 1500,Challenger,Charger,Durango,Ram 2500,Atos,Van 1000,Sprinter 2500,Avenger,Grand Caravan,Viper,Dakota,H100,Attitude',
  },
  {
    year: 2009,
    make: 'Ferrari',
    models: 'F430,599 GTB,612 Scaglietti,California',
  },
  {
    year: 2009,
    make: 'Fiat',
    models: 'Bravo,Palio,Ducato,Idea,Strada,Punto,500,Panda,Albea',
  },
  {
    year: 2009,
    make: 'Ford',
    models:
      'Explorer,Expedition,Mustang,Transit,Taurus X,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Flex,Courier,Explorer Sport Trac,F-250,EcoSport,Crown Victoria,E-150,Lobo,Ranger,E-250,F-150,Edge,Fiesta',
  },
  {
    year: 2009,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2009,
    make: 'GMC',
    models:
      'Acadia,Canyon,Savana 2500,Savana 1500,Yukon XL 1500,Envoy,Sierra,Sierra 2500 HD,Yukon XL 2500,Sierra 1500,Yukon',
  },
  {
    year: 2009,
    make: 'Honda',
    models: 'Accord,CR-V,Ridgeline,Element,Pilot,Fit,Civic,Odyssey,S2000',
  },
  {
    year: 2009,
    make: 'Hummer',
    models: 'H2,H3,H3T',
  },
  {
    year: 2009,
    make: 'Hyundai',
    models: 'Azera,Entourage,Elantra,Genesis,Santa Fe,Tucson,Veracruz,Sonata,Accent',
  },
  {
    year: 2009,
    make: 'Infiniti',
    models: 'EX35,FX35,QX56,M45,FX50,G37,M35',
  },
  {
    year: 2009,
    make: 'Jaguar',
    models: 'XF,XK,Vanden Plas,Super V8,XJ8,XJR,XKR',
  },
  {
    year: 2009,
    make: 'Jeep',
    models: 'Patriot,Wrangler,Commander,Grand Cherokee,Liberty,Compass',
  },
  {
    year: 2009,
    make: 'Kia',
    models: 'Magentis,Rio,Spectra5,Rondo,Sedona,Spectra,Optima,Amanti,Rio5,Sportage,Borrego,Sorento',
  },
  {
    year: 2009,
    make: 'Lamborghini',
    models: 'Murcielago,Gallardo',
  },
  {
    year: 2009,
    make: 'Land Rover',
    models: 'LR2,LR3,Range Rover,Range Rover Sport',
  },
  {
    year: 2009,
    make: 'Lexus',
    models: 'IS350,GS350,ES350,IS F,LS460,LX570,RX350,GS460,GS450h,LS600h,SC430,IS250,GX470',
  },
  {
    year: 2009,
    make: 'Lincoln',
    models: 'MKS,Navigator,MKX,MKZ,Town Car',
  },
  {
    year: 2009,
    make: 'Lotus',
    models: 'Elise,Exige',
  },
  {
    year: 2009,
    make: 'Maserati',
    models: 'GranTurismo,Quattroporte',
  },
  {
    year: 2009,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2009,
    make: 'Mazda',
    models: 'B2300,B4000,CX-9,3,MX-5,6,3 Sport,MX-5 Miata,RX-8,5,Tribute,CX-7',
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    models:
      'ML63 AMG,CLS550,G550,GLK280,CLK350,ML320,SL65 AMG,CLK550,S500,C230,E280,E350,CL600,Vito,Sprinter,E300,G55 AMG,SLK55 AMG,C63 AMG,SLK300,GLK350,S65 AMG,CLS350,E320,G500,CL63 AMG,C300,SL500,SL600,C280,CL500,CLK280,ML350,GL450,ML550,GL550,E550,CL550,GL320,CLS63 AMG,E63 AMG,CLS500,SLK200,SL550,R350,CLK63 AMG,S450,GL500,CLK500,SLK350,ML500,R320,SL63 AMG,S63 AMG,B200,SLR McLaren,S550,E500,C350,CL65 AMG,S600',
  },
  {
    year: 2009,
    make: 'Mercury',
    models: 'Mariner,Sable,Milan,Grand Marquis,Mountaineer',
  },
  {
    year: 2009,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    models: 'Montero,Grandis,Lancer,L200,Galant,Montero Sport,Endeavor,Raider,Eclipse,Outlander',
  },
  {
    year: 2009,
    make: 'Nissan',
    models:
      'Urvan,Pathfinder,Xterra,GT-R,Platina,NP300,X-Trail,Altima,Aprio,Titan,Murano,Rogue,Maxima,Cabstar E,Quest,350Z,Versa,Armada,Cube,Tsuru,370Z,Sentra,Tiida,Pickup,Frontier',
  },
  {
    year: 2009,
    make: 'Peugeot',
    models: '207 Compact,407,308,307,Partner,Grand Raid,Expert,206,Manager',
  },
  {
    year: 2009,
    make: 'Pontiac',
    models: 'G8,Solstice,G3,G3 Wave,Vibe,Torrent,G6,G5,Matiz,Montana',
  },
  {
    year: 2009,
    make: 'Porsche',
    models: 'Cayenne,Cayman,911,Boxster',
  },
  {
    year: 2009,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2009,
    make: 'Saab',
    models: '9-7x,9-3,9-5',
  },
  {
    year: 2009,
    make: 'Saturn',
    models: 'Outlook,Vue,Astra,Aura,Sky',
  },
  {
    year: 2009,
    make: 'Scion',
    models: 'xB,tC,xD',
  },
  {
    year: 2009,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2009,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2009,
    make: 'Subaru',
    models: 'Tribeca,Forester,Outback,Legacy,Impreza',
  },
  {
    year: 2009,
    make: 'Suzuki',
    models: 'XL-7,Grand Vitara,Swift,Equator,SX4,Swift+',
  },
  {
    year: 2009,
    make: 'Toyota',
    models:
      'Tundra,Corolla,Prius,Hilux,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Avanza,Yaris,Sienna,Land Cruiser,Rush,Hiace,FJ Cruiser,Tacoma,Highlander,Venza',
  },
  {
    year: 2009,
    make: 'Volkswagen',
    models:
      'Lupo,Rabbit,CrossFox,Eos,Touareg,Jetta,Golf City,Crafter Van,Tiguan,Passat CC,EuroVan,Van,GTI,Routan,Jetta City,Pointer,Passat,Gol,Derby,SportVan,Beetle,Bora,CC',
  },
  {
    year: 2009,
    make: 'Volvo',
    models: 'S40,XC90,S60,XC70,C30,V70,C70,V50,S80',
  },
  {
    year: 2008,
    make: 'Acura',
    models: 'CSX,RL,TSX,MDX,RDX,TL',
  },
  {
    year: 2008,
    make: 'Alfa Romeo',
    models: '8C,Brera,159,Spider,GT',
  },
  {
    year: 2008,
    make: 'Aston Martin',
    models: 'DBS,DB9,V8 Vantage',
  },
  {
    year: 2008,
    make: 'Audi',
    models: 'A5 Quattro,A3,A3 Quattro,R8,A8 Quattro,S3,S4,S6,TT Quattro,S8,RS4,TT,A6,A4,A5,S5,A6 Quattro,A4 Quattro,Q7',
  },
  {
    year: 2008,
    make: 'Bentley',
    models: 'Arnage,Brooklands,Azure,Continental',
  },
  {
    year: 2008,
    make: 'BMW',
    models:
      '525i,X3,X6,M6,535xi,328xi,125i,530i,135i,120i,335i,528i,M5,528xi,130i,550i,128i,323i,328i,750i,650Ci,650i,535i,325i,335xi,760Li,Alpina B7,Z4,750Li,M3,X5',
  },
  {
    year: 2008,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2008,
    make: 'Buick',
    models: 'Enclave,LaCrosse,Allure,Lucerne',
  },
  {
    year: 2008,
    make: 'Cadillac',
    models: 'Escalade ESV,Escalade EXT,SRX,BLS,CTS,XLR,Escalade,STS,DTS',
  },
  {
    year: 2008,
    make: 'Chevrolet',
    models:
      'Colorado,Malibu,Equinox,Avalanche,Cobalt,Suburban 1500,Chevy,Corsa,Trailblazer,Express 2500,Express 3500,Silverado 1500,Impala,Aveo,Silverado 2500,Tahoe,Cheyenne,Tracker,Aveo5,Express 1500,Suburban 2500,Vectra,Astra,Corvette,Silverado 2500 HD,Optra,HHR,Meriva,Captiva Sport,Uplander,Tornado',
  },
  {
    year: 2008,
    make: 'Chrysler',
    models: 'Cirrus,Sebring,Crossfire,Voyager,Pacifica,PT Cruiser,300,Town & Country,Aspen',
  },
  {
    year: 2008,
    make: 'Dodge',
    models:
      'Ram 2500,Atos,Van 1000,Sprinter 2500,Avenger,Grand Caravan,Viper,Dakota,H100,Attitude,Nitro,Caliber,Magnum,Ram 1500,Challenger,Charger,Durango',
  },
  {
    year: 2008,
    make: 'Ferrari',
    models: '360,F430,599 GTB,612 Scaglietti',
  },
  {
    year: 2008,
    make: 'Fiat',
    models: 'Ducato,Punto,Idea,Strada,Palio,Panda',
  },
  {
    year: 2008,
    make: 'Ford',
    models:
      'Ranger,E-150 Econoline,E-250,F-150,Edge,Fiesta,Ka,Lobo,Explorer,Expedition,Mustang,EcoSport,Transit,Taurus X,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Courier,Explorer Sport Trac,F-250,Crown Victoria,E-150',
  },
  {
    year: 2008,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2008,
    make: 'GMC',
    models:
      'Yukon XL 2500,Sierra 1500,Yukon,Acadia,Canyon,Savana 2500,Savana 1500,Yukon XL 1500,Envoy,Sierra,Sierra 2500 HD',
  },
  {
    year: 2008,
    make: 'Honda',
    models: 'Civic,Odyssey,S2000,Accord,CR-V,Ridgeline,Element,Pilot,Fit',
  },
  {
    year: 2008,
    make: 'Hummer',
    models: 'H2,H3',
  },
  {
    year: 2008,
    make: 'Hyundai',
    models: 'Veracruz,Sonata,Accent,Azera,Entourage,Elantra,Tiburon,Santa Fe,Tucson',
  },
  {
    year: 2008,
    make: 'Infiniti',
    models: 'G35,G37,M35,EX35,FX35,QX56,M45,FX45',
  },
  {
    year: 2008,
    make: 'Isuzu',
    models: 'i-290,i-370,Ascender',
  },
  {
    year: 2008,
    make: 'Jaguar',
    models: 'X-Type,S-Type,XJR,XKR,XK,Vanden Plas,Super V8,XJ8',
  },
  {
    year: 2008,
    make: 'Jeep',
    models: 'Commander,Grand Cherokee,Liberty,Compass,Patriot,Wrangler',
  },
  {
    year: 2008,
    make: 'Kia',
    models: 'Amanti,Rio5,Sportage,Sorento,Spectra5,Magentis,Rio,Rondo,Sedona,Spectra,Optima',
  },
  {
    year: 2008,
    make: 'Lamborghini',
    models: 'Murcielago,Gallardo',
  },
  {
    year: 2008,
    make: 'Land Rover',
    models: 'Range Rover,Range Rover Sport,LR2,LR3',
  },
  {
    year: 2008,
    make: 'Lexus',
    models: 'LS600h,SC430,IS250,GX470,IS350,GS350,ES350,IS F,LS460,RX400h,LX570,RX350,GS460,GS450h',
  },
  {
    year: 2008,
    make: 'Lincoln',
    models: 'MKX,MKZ,Town Car,Navigator,Mark LT',
  },
  {
    year: 2008,
    make: 'Lotus',
    models: 'Exige,Elise',
  },
  {
    year: 2008,
    make: 'Maserati',
    models: 'GranSport,Quattroporte,GranTurismo',
  },
  {
    year: 2008,
    make: 'Maybach',
    models: '62,57',
  },
  {
    year: 2008,
    make: 'Mazda',
    models: 'RX-8,B3000,5,Tribute,CX-7,B2300,B4000,CX-9,3,MX-5,6,MX-5 Miata',
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    models:
      'CLK63 AMG,Sprinter,GL500,CLK500,SLK350,ML500,R320,S63 AMG,B200,SLR McLaren,S550,E500,C350,CL65 AMG,R550,S600,ML63 AMG,CLS550,SL55 AMG,C230,CLK350,ML320,SL65 AMG,CLK550,S500,S450,E280,E350,CL600,G55 AMG,SLK55 AMG,C63 AMG,S65 AMG,CLS350,E320,CL63 AMG,C300,SL500,SL600,C280,CL500,CLK280,E300,G500,SLK280,ML350,GL450,ML550,GL550,E550,CL550,GL320,CLS63 AMG,E63 AMG,CLS500,SLK200,SL550,R350,R500',
  },
  {
    year: 2008,
    make: 'Mercury',
    models: 'Mountaineer,Mariner,Sable,Milan,Grand Marquis',
  },
  {
    year: 2008,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    models: 'Raider,Lancer,Montero,Eclipse,Montero Sport,Outlander,Grandis,Endeavor,L200,Galant',
  },
  {
    year: 2008,
    make: 'Nissan',
    models:
      'Cabstar E,Quest,350Z,Versa,Murano,Armada,Aprio,Tsuru,Sentra,Tiida,Pickup,X-Trail,Frontier,Pathfinder,Xterra,Platina,Altima,Titan,Rogue,Urvan,Maxima',
  },
  {
    year: 2008,
    make: 'Peugeot',
    models: 'Expert,206,607,207,407,307,Partner,Grand Raid',
  },
  {
    year: 2008,
    make: 'Pontiac',
    models: 'G6,G5,Matiz,Montana,G3,G8,Solstice,Grand Prix,Wave,Vibe,Torrent',
  },
  {
    year: 2008,
    make: 'Porsche',
    models: '911,Boxster,Cayenne,Cayman',
  },
  {
    year: 2008,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2008,
    make: 'Saab',
    models: '9-7x,9-3,9-5',
  },
  {
    year: 2008,
    make: 'Saturn',
    models: 'Sky,Outlook,Vue,Astra,Aura',
  },
  {
    year: 2008,
    make: 'Scion',
    models: 'tC,xD,xB',
  },
  {
    year: 2008,
    make: 'Smart',
    models: 'Fortwo',
  },
  {
    year: 2008,
    make: 'Spyker',
    models: 'C8,C12',
  },
  {
    year: 2008,
    make: 'Subaru',
    models: 'Legacy,Impreza,Tribeca,Forester,Outback',
  },
  {
    year: 2008,
    make: 'Suzuki',
    models: 'SX4,Swift+,Reno,Forenza,XL-7,Grand Vitara,Swift',
  },
  {
    year: 2008,
    make: 'Toyota',
    models:
      'Sienna,Solara,Land Cruiser,Hilux,Hiace,FJ Cruiser,Yaris,Tacoma,Highlander,Tundra,Corolla,Prius,Avanza,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix',
  },
  {
    year: 2008,
    make: 'Volkswagen',
    models:
      'Van,GTI,Jetta City,Pointer,Sharan,Passat,Crafter Van,Derby,SportVan,Beetle,Bora,Lupo,Rabbit,CrossFox,Eos,Touareg,Jetta,Golf City,R32,EuroVan',
  },
  {
    year: 2008,
    make: 'Volvo',
    models: 'C70,V50,S80,S40,XC90,S60,XC70,C30,V70',
  },
  {
    year: 2007,
    make: 'Acura',
    models: 'MDX,RDX,TL,RL,TSX,CSX',
  },
  {
    year: 2007,
    make: 'Alfa Romeo',
    models: '147,Spider,GT,Brera,159',
  },
  {
    year: 2007,
    make: 'Aston Martin',
    models: 'V8 Vantage,DB9',
  },
  {
    year: 2007,
    make: 'Audi',
    models: 'A6 Quattro,A4 Quattro,Q7,TT,A3,A3 Quattro,A8 Quattro,TT Quattro,S4,S6,S8,RS4,A6,A4',
  },
  {
    year: 2007,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage',
  },
  {
    year: 2007,
    make: 'BMW',
    models:
      '325i,525xi,335xi,330i,760Li,Alpina B7,Z4,750Li,X5,525i,X3,M6,328xi,530i,120i,335i,M5,530xi,130i,550i,323i,328i,750i,650Ci,650i',
  },
  {
    year: 2007,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2007,
    make: 'Buick',
    models: 'Allure,Lucerne,Rainier,Rendezvous,LaCrosse,Terraza',
  },
  {
    year: 2007,
    make: 'Cadillac',
    models: 'STS,DTS,Escalade ESV,Escalade EXT,SRX,BLS,CTS,XLR,Escalade',
  },
  {
    year: 2007,
    make: 'Chevrolet',
    models:
      'Tracker,Aveo5,Monte Carlo,Express 1500,Suburban 2500,Vectra,Silverado 2500 HD Classic,Silverado 1500 HD Classic,Astra,Corvette,Silverado 2500 HD,Optra,HHR,Meriva,Uplander,Tornado,Colorado,Malibu,Equinox,Avalanche,Silverado 1500 Classic,Cobalt,Suburban 1500,Chevy,Corsa,Trailblazer,Express 2500,Express 3500,Silverado 3500 Classic,Silverado 1500,Impala,Aveo,Silverado 2500,Tahoe,Cheyenne',
  },
  {
    year: 2007,
    make: 'Chrysler',
    models: 'Aspen,Cirrus,Sebring,Crossfire,Voyager,Pacifica,PT Cruiser,300,Town & Country',
  },
  {
    year: 2007,
    make: 'Dodge',
    models:
      'Magnum,Ram 1500,Caravan,Charger,Durango,Ram 2500,Atos,Sprinter 2500,Grand Caravan,Dakota,H100,Attitude,Nitro,Caliber',
  },
  {
    year: 2007,
    make: 'Ferrari',
    models: 'F430,599 GTB,612 Scaglietti',
  },
  {
    year: 2007,
    make: 'Fiat',
    models: 'Strada,Palio,Panda,Stilo,Punto',
  },
  {
    year: 2007,
    make: 'Ford',
    models:
      'Freestyle,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Five Hundred,Courier,Explorer Sport Trac,F-250,Freestar,Mondeo,Crown Victoria,E-150,Ikon,Ranger,E-150 Econoline,E-250,F-150,Edge,Fiesta,Ka,Lobo,Expedition,Explorer,Mustang,EcoSport,Transit',
  },
  {
    year: 2007,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2007,
    make: 'GMC',
    models:
      'Sierra 2500 HD Classic,Yukon XL 1500,Envoy,Sierra,Sierra 2500 HD,Sierra 1500 Classic,Yukon,Yukon XL 2500,Sierra 3500 Classic,Sierra 1500,Sierra 1500 HD Classic,Acadia,Canyon,Savana 2500,Savana 1500',
  },
  {
    year: 2007,
    make: 'Honda',
    models: 'Element,Pilot,Fit,Civic,Odyssey,S2000,Accord,CR-V,Ridgeline',
  },
  {
    year: 2007,
    make: 'Hummer',
    models: 'H2,H3',
  },
  {
    year: 2007,
    make: 'Hyundai',
    models: 'Elantra,Tiburon,Santa Fe,Tucson,Veracruz,Sonata,Accent,Azera,Entourage',
  },
  {
    year: 2007,
    make: 'Infiniti',
    models: 'FX35,QX56,M45,FX45,G35,M35',
  },
  {
    year: 2007,
    make: 'Isuzu',
    models: 'Ascender,i-290,i-370',
  },
  {
    year: 2007,
    make: 'Jaguar',
    models: 'XK,Vanden Plas,Super V8,XJ8,X-Type,S-Type,XJR,XKR',
  },
  {
    year: 2007,
    make: 'Jeep',
    models: 'Patriot,Wrangler,Commander,Grand Cherokee,Liberty,Compass',
  },
  {
    year: 2007,
    make: 'Kia',
    models: 'Rondo,Sedona,Spectra,Optima,Amanti,Rio5,Sportage,Sorento,Spectra5,Magentis,Rio',
  },
  {
    year: 2007,
    make: 'Lamborghini',
    models: 'Gallardo,Murcielago',
  },
  {
    year: 2007,
    make: 'Land Rover',
    models: 'LR3,Range Rover,Range Rover Sport',
  },
  {
    year: 2007,
    make: 'Lexus',
    models: 'LS460,RX400h,LX470,RX350,GS450h,SC430,IS250,GX470,GS430,IS350,GS350,ES350',
  },
  {
    year: 2007,
    make: 'Lincoln',
    models: 'Navigator,Mark LT,MKX,MKZ,Town Car',
  },
  {
    year: 2007,
    make: 'Lotus',
    models: 'Elise,Exige',
  },
  {
    year: 2007,
    make: 'Maserati',
    models: 'Coupe,GranSport,Quattroporte',
  },
  {
    year: 2007,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2007,
    make: 'Mazda',
    models: 'MX-5,6,MX-5 Miata,RX-8,5,CX-7,B2300,B3000,B4000,CX-9,3',
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    models:
      'S65 AMG,CLS350,E320,SL500,SL600,C280,CL500,CLK280,G500,SLK280,ML350,GL450,E550,CL550,GL320,CLS63 AMG,E63 AMG,CLS500,SLK200,SL550,R350,R500,C55 AMG,CLK63 AMG,R63 AMG,Sprinter,CLK500,SLK350,ML500,R320,B200,SLR McLaren,C230,S550,E500,C350,S600,ML63 AMG,CLS550,SL55 AMG,CLK350,ML320,SL65 AMG,CLK550,S500,E280,E350,CL600,G55 AMG,SLK55 AMG',
  },
  {
    year: 2007,
    make: 'Mercury',
    models: 'Milan,Grand Marquis,Monterey,Mountaineer,Montego,Mariner',
  },
  {
    year: 2007,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    models: 'Endeavor,Galant,Raider,Eclipse,Montero Sport,Outlander,Lancer,Grandis',
  },
  {
    year: 2007,
    make: 'Nissan',
    models:
      'Altima,Titan,Urvan,Maxima,Quest,350Z,Versa,Armada,Tsuru,Sentra,Tiida,Pickup,X-Trail,Frontier,Murano,Pathfinder,Xterra,Platina,Micra',
  },
  {
    year: 2007,
    make: 'Peugeot',
    models: '407,307,Partner,Grand Raid,206,607',
  },
  {
    year: 2007,
    make: 'Pontiac',
    models: 'Torrent,Wave5,G6,G5,Matiz,Montana,G3,Solstice,Grand Prix,Wave,Vibe',
  },
  {
    year: 2007,
    make: 'Porsche',
    models: 'Cayman,911,Boxster',
  },
  {
    year: 2007,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2007,
    make: 'Saab',
    models: '9-7x,9-3,9-5',
  },
  {
    year: 2007,
    make: 'Saturn',
    models: 'Aura,Relay,Sky,Ion,Outlook,Vue',
  },
  {
    year: 2007,
    make: 'Scion',
    models: 'tC',
  },
  {
    year: 2007,
    make: 'Smart',
    models: 'Forfour,Fortwo',
  },
  {
    year: 2007,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2007,
    make: 'Subaru',
    models: 'Legacy,Impreza,B9 Tribeca,Forester,Outback',
  },
  {
    year: 2007,
    make: 'Suzuki',
    models: 'XL-7,Grand Vitara,Swift,SX4,Swift+,Reno,Aerio,Forenza',
  },
  {
    year: 2007,
    make: 'Toyota',
    models:
      'RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Avanza,Sienna,Solara,Land Cruiser,Hilux,Hiace,FJ Cruiser,Yaris,Tacoma,Highlander,Tundra,Corolla,Prius',
  },
  {
    year: 2007,
    make: 'Volkswagen',
    models:
      'Touareg,Jetta,Golf City,Golf,EuroVan,Polo,Van,Jetta City,Pointer,Sharan,Passat,Derby,SportVan,Beetle,Bora,Lupo,Rabbit,CrossFox,Eos,GTI',
  },
  {
    year: 2007,
    make: 'Volvo',
    models: 'XC70,C30,V70,C70,V50,S80,S40,XC90,S60',
  },
  {
    year: 2006,
    make: 'Acura',
    models: 'CSX,MDX,RSX,TL,RL,TSX',
  },
  {
    year: 2006,
    make: 'Aston Martin',
    models: 'DB9,Vanquish,V8 Vantage',
  },
  {
    year: 2006,
    make: 'Audi',
    models: 'S4,A6,A4,A6 Quattro,A4 Quattro,TT Quattro,A3,A3 Quattro,TT,A8 Quattro',
  },
  {
    year: 2006,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage',
  },
  {
    year: 2006,
    make: 'BMW',
    models:
      '530i,120i,M5,325i,530xi,130i,550i,760i,330xi,323i,750i,650Ci,650i,525xi,330i,760Li,Z4,325xi,750Li,M3,X5,325Ci,330Ci,525i,X3,545i,M6',
  },
  {
    year: 2006,
    make: 'Bugatti',
    models: 'Veyron 16.4',
  },
  {
    year: 2006,
    make: 'Buick',
    models: 'LaCrosse,Terraza,Allure,Lucerne,Rainier,Rendezvous',
  },
  {
    year: 2006,
    make: 'Cadillac',
    models: 'SRX,CTS,XLR,Escalade,STS,DTS,Escalade ESV,Escalade EXT',
  },
  {
    year: 2006,
    make: 'Chevrolet',
    models:
      'Trailblazer,Express 2500,Express 3500,Silverado 1500,Impala,Avalanche 1500,Aveo,Silverado 1500 HD,Silverado 2500,Tahoe,Epica,Trailblazer EXT,Zafira,Tracker,Avalanche 2500,Monte Carlo,Express 1500,Suburban 2500,Vectra,Astra,Aveo5,SSR,Corvette,Silverado 2500 HD,HHR,Meriva,Uplander,Tornado,Colorado,Malibu,Equinox,Sonora,Cobalt,Suburban 1500,Optra,Chevy,Corsa',
  },
  {
    year: 2006,
    make: 'Chrysler',
    models: 'Crossfire,Voyager,Pacifica,PT Cruiser,300,Town & Country,Cirrus,Sebring',
  },
  {
    year: 2006,
    make: 'Dodge',
    models:
      'Dakota,H100,Attitude,Sprinter 2500,Stratus,Verna,Ram 1500,Caravan,Charger,Durango,Magnum,Viper,Ram 2500,Atos,Grand Caravan',
  },
  {
    year: 2006,
    make: 'Ferrari',
    models: '599 GTB,F430,612 Scaglietti,360',
  },
  {
    year: 2006,
    make: 'Fiat',
    models: 'Palio',
  },
  {
    year: 2006,
    make: 'Ford',
    models:
      'F-150,Fiesta,Ka,Lobo,Expedition,Explorer,Mustang,Econoline,EcoSport,Freestyle,Fusion,Escape,F-250 Super Duty,Focus,Taurus,Five Hundred,Courier,F-250,GT,Freestar,Mondeo,Crown Victoria,E-150,Ikon,Ranger,E-250',
  },
  {
    year: 2006,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2006,
    make: 'GMC',
    models:
      'Sierra 1500,Canyon,Savana 2500,Envoy XL,Sierra 1500 HD,Savana 1500,Envoy,Yukon XL 1500,Sierra 2500 HD,Yukon,Yukon XL 2500',
  },
  {
    year: 2006,
    make: 'Honda',
    models: 'Fit,Accord,CR-V,Ridgeline,Insight,Element,Pilot,Civic,Odyssey,S2000',
  },
  {
    year: 2006,
    make: 'Hummer',
    models: 'H2,H3',
  },
  {
    year: 2006,
    make: 'Hyundai',
    models: 'Azera,Elantra,Tiburon,Santa Fe,Tucson,Sonata,Accent',
  },
  {
    year: 2006,
    make: 'Infiniti',
    models: 'M35,FX35,Q45,QX56,M45,FX45,G35',
  },
  {
    year: 2006,
    make: 'Isuzu',
    models: 'i-280,Ascender,i-350',
  },
  {
    year: 2006,
    make: 'Jaguar',
    models: 'XJR,XKR,Vanden Plas,Super V8,XJ8,XK8,X-Type,S-Type',
  },
  {
    year: 2006,
    make: 'Jeep',
    models: 'TJ,Wrangler,Commander,Grand Cherokee,Liberty',
  },
  {
    year: 2006,
    make: 'Kia',
    models: 'Rio,Magentis,Sedona,Spectra,Optima,Amanti,Rio5,Sportage,Sorento,Spectra5',
  },
  {
    year: 2006,
    make: 'Lamborghini',
    models: 'Murcielago,Gallardo',
  },
  {
    year: 2006,
    make: 'Land Rover',
    models: 'Range Rover Sport,Freelander,LR3,Range Rover',
  },
  {
    year: 2006,
    make: 'Lexus',
    models: 'GS430,IS350,GS300,RX330,ES330,RX400h,LX470,LS430,SC430,IS250,GX470',
  },
  {
    year: 2006,
    make: 'Lincoln',
    models: 'Town Car,Navigator,LS,Mark LT,Zephyr',
  },
  {
    year: 2006,
    make: 'Lotus',
    models: 'Elise,Exige',
  },
  {
    year: 2006,
    make: 'Maserati',
    models: 'GranSport,Quattroporte,Coupe,Spyder',
  },
  {
    year: 2006,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2006,
    make: 'Mazda',
    models: 'B2300,B3000,B4000,3,MX-5,MPV,Tribute,6,MX-5 Miata,RX-8,5',
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    models:
      'S600,CLS500,SL55 AMG,E55 AMG,CLK350,SL65 AMG,S500,E350,CL55 AMG,CL600,G55 AMG,SLK55 AMG,S65 AMG,CLK55 AMG,E320,S55 AMG,SL500,SL600,C280,G500,SLK280,ML350,S350,S430,CL65 AMG,SLK200,E500,R350,R500,C55 AMG,CLS55 AMG,Sprinter,CLK500,SLK350,ML500,B200,SLR McLaren,C230,CL500,C350',
  },
  {
    year: 2006,
    make: 'Mercury',
    models: 'Mountaineer,Montego,Mariner,Milan,Grand Marquis,Monterey',
  },
  {
    year: 2006,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    models: 'Montero Sport,Outlander,Lancer,Montero,Endeavor,Galant,Raider,Eclipse',
  },
  {
    year: 2006,
    make: 'Nissan',
    models:
      'Sentra,Pickup,Frontier,X-Trail,Murano,Pathfinder,Xterra,Platina,Micra,Altima,Titan,Urvan,Maxima,Quest,350Z,Armada,Tsuru',
  },
  {
    year: 2006,
    make: 'Peugeot',
    models: '407,307,Partner,206,607',
  },
  {
    year: 2006,
    make: 'Pontiac',
    models: 'Montana,Solstice,Grand Prix,Wave,Vibe,Pursuit,Torrent,G4,Wave5,G6,GTO,Matiz',
  },
  {
    year: 2006,
    make: 'Porsche',
    models: 'Boxster,Carrera GT,Cayman,Cayenne,911',
  },
  {
    year: 2006,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2006,
    make: 'Saab',
    models: '9-2X,9-7x,9-3,9-5',
  },
  {
    year: 2006,
    make: 'Saturn',
    models: 'Ion,Vue,Relay',
  },
  {
    year: 2006,
    make: 'Scion',
    models: 'xB,xA,tC',
  },
  {
    year: 2006,
    make: 'Smart',
    models: 'Forfour,Fortwo',
  },
  {
    year: 2006,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2006,
    make: 'Subaru',
    models: 'Impreza,B9 Tribeca,Forester,Baja,Outback,Legacy',
  },
  {
    year: 2006,
    make: 'Suzuki',
    models: 'Forenza,Verona,XL-7,Grand Vitara,Swift+,Aerio,Reno',
  },
  {
    year: 2006,
    make: 'Toyota',
    models:
      'Tacoma,Highlander,MR2 Spyder,Tundra,Corolla,Prius,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Sienna,Solara,Land Cruiser,Hilux,Hiace,Yaris',
  },
  {
    year: 2006,
    make: 'Volkswagen',
    models: 'Derby,Beetle,Bora,Lupo,Rabbit,GTI,Touareg,Jetta,Golf,Phaeton,EuroVan,Polo,Van,Pointer,Sharan,Passat',
  },
  {
    year: 2006,
    make: 'Volvo',
    models: 'S40,XC90,S60,XC70,V70,C70,V50,S80',
  },
  {
    year: 2005,
    make: 'Acura',
    models: 'RSX,TL,NSX,RL,TSX,EL,MDX',
  },
  {
    year: 2005,
    make: 'Alfa Romeo',
    models: '147',
  },
  {
    year: 2005,
    make: 'Aston Martin',
    models: 'Vanquish,DB9',
  },
  {
    year: 2005,
    make: 'Audi',
    models: 'TT Quattro,A3 Quattro,Allroad Quattro,TT,A8 Quattro,S4,A4,A3,A6,A6 Quattro,A4 Quattro',
  },
  {
    year: 2005,
    make: 'Bentley',
    models: 'Arnage,Continental',
  },
  {
    year: 2005,
    make: 'BMW',
    models: '745i,M3,X5,325Ci,330Ci,525i,X3,545i,320i,530i,120i,745Li,325i,760i,330xi,330i,645Ci,760Li,Z4,325xi',
  },
  {
    year: 2005,
    make: 'Buick',
    models: 'Rainier,Rendezvous,LeSabre,LaCrosse,Terraza,Allure,Century,Park Avenue',
  },
  {
    year: 2005,
    make: 'Cadillac',
    models: 'Escalade ESV,Escalade EXT,DeVille,SRX,CTS,XLR,Escalade,STS',
  },
  {
    year: 2005,
    make: 'Chevrolet',
    models:
      'Classic,P30,Uplander,Epica,Tornado,Astro,Colorado,Malibu,Equinox,Sonora,Cobalt,Suburban 1500,Optra,Chevy,Corsa,Venture,Trailblazer,Cavalier,Express 2500,Express 3500,Silverado 1500,Impala,Avalanche 1500,LUV,Aveo,Silverado 1500 HD,Silverado 2500,Tahoe,Trailblazer EXT,Zafira,Tracker,Avalanche 2500,Blazer,Monte Carlo,Express 1500,Suburban 2500,Vectra,Astra,SSR,Corvette,Silverado 2500 HD,Meriva',
  },
  {
    year: 2005,
    make: 'Chrysler',
    models: 'Cirrus,Sebring,Crossfire,Voyager,Pacifica,PT Cruiser,300,Town & Country',
  },
  {
    year: 2005,
    make: 'Dodge',
    models:
      'Viper,SX 2.0,Ram 2500,Atos,Grand Caravan,Dakota,H100,Sprinter 2500,Stratus,Verna,Neon,Ram 1500,Caravan,Durango,Magnum',
  },
  {
    year: 2005,
    make: 'Ferrari',
    models: 'Superamerica,360,F430,575 M Maranello,612 Scaglietti',
  },
  {
    year: 2005,
    make: 'Fiat',
    models: 'Palio',
  },
  {
    year: 2005,
    make: 'Ford',
    models:
      'F-250,Freestar,Mondeo,Crown Victoria,E-150,E-350 Club Wagon,Ikon,E-150 Club Wagon,Ranger,Thunderbird,E-250,F-150,Fiesta,GT,Ka,Lobo,Explorer Sport Trac,Expedition,Explorer,Mustang,Econoline,EcoSport,Freestyle,Escape,F-250 Super Duty,Focus,Taurus,Five Hundred,Courier,Excursion',
  },
  {
    year: 2005,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2005,
    make: 'GMC',
    models:
      'Envoy XUV,Safari,Sierra 2500 HD,Yukon,Yukon XL 2500,Sierra 1500,Canyon,Savana 2500,Envoy XL,Sierra 1500 HD,Savana 1500,Jimmy,Envoy,Yukon XL 1500',
  },
  {
    year: 2005,
    make: 'Honda',
    models: 'Civic,Odyssey,S2000,Accord,CR-V,Insight,Element,Pilot',
  },
  {
    year: 2005,
    make: 'Hummer',
    models: 'H2',
  },
  {
    year: 2005,
    make: 'Hyundai',
    models: 'Tucson,Sonata,Accent,XG350,Elantra,Tiburon,Santa Fe',
  },
  {
    year: 2005,
    make: 'Infiniti',
    models: 'FX45,G35,FX35,Q45,QX56',
  },
  {
    year: 2005,
    make: 'Isuzu',
    models: 'Ascender',
  },
  {
    year: 2005,
    make: 'Jaguar',
    models: 'XJ8,XK8,X-Type,S-Type,XJR,XKR,Vanden Plas,Super V8',
  },
  {
    year: 2005,
    make: 'Jeep',
    models: 'Grand Cherokee,Liberty,TJ,Wrangler',
  },
  {
    year: 2005,
    make: 'Kia',
    models: 'Sedona,Spectra,Optima,Magentis,Amanti,Sportage,Sorento,Rio,Spectra5',
  },
  {
    year: 2005,
    make: 'Lamborghini',
    models: 'Murcielago,Gallardo',
  },
  {
    year: 2005,
    make: 'Land Rover',
    models: 'LR3,Range Rover,Freelander',
  },
  {
    year: 2005,
    make: 'Lexus',
    models: 'LS430,SC430,GX470,GS430,GS300,RX330,ES330,IS300,LX470',
  },
  {
    year: 2005,
    make: 'Lincoln',
    models: 'Town Car,Aviator,Navigator,LS',
  },
  {
    year: 2005,
    make: 'Lotus',
    models: 'Elise,Exige',
  },
  {
    year: 2005,
    make: 'Maserati',
    models: 'Spyder,GranSport,Quattroporte,Coupe',
  },
  {
    year: 2005,
    make: 'Maybach',
    models: '62,57',
  },
  {
    year: 2005,
    make: 'Mazda',
    models: 'MPV,Tribute,6,RX-8,B2300,B3000,B4000,Miata,3',
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    models:
      'CL65 AMG,A190,SLK200,E500,C55 AMG,CL230,Sprinter,CLK500,SLK350,ML500,SLR McLaren,C230,CL500,S600,C320,SL55 AMG,C240,S65 AMG,CLK320,E55 AMG,SL65 AMG,S500,CLS500,CL55 AMG,CL600,G55 AMG,SLK55 AMG,CLK55 AMG,E320,S55 AMG,SL500,SL600,A160,G500,ML350,S430',
  },
  {
    year: 2005,
    make: 'Mercury',
    models: 'Sable,Monterey,Mountaineer,Montego,Mariner,Grand Marquis',
  },
  {
    year: 2005,
    make: 'MG',
    models: 'ZR,ZT,TF',
  },
  {
    year: 2005,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    models: 'Galant,Eclipse,Montero Sport,Outlander,Lancer,Montero,Endeavor',
  },
  {
    year: 2005,
    make: 'Nissan',
    models:
      'Urvan,Maxima,Almera,Quest,350Z,Armada,Tsuru,Sentra,Pickup,Frontier,X-Trail,Murano,Pathfinder,Xterra,Platina,Micra,Altima,Titan',
  },
  {
    year: 2005,
    make: 'Peugeot',
    models: '307,Partner,206,607,406',
  },
  {
    year: 2005,
    make: 'Pontiac',
    models: 'G4,Wave5,G6,GTO,Matiz,Montana,Aztek,Grand Am,Grand Prix,Wave,Vibe,Pursuit,Sunfire,Bonneville',
  },
  {
    year: 2005,
    make: 'Porsche',
    models: 'Cayenne,911,Boxster,Carrera GT',
  },
  {
    year: 2005,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2005,
    make: 'Rover',
    models: '75',
  },
  {
    year: 2005,
    make: 'Saab',
    models: '9-5,9-2X,9-7x,9-3',
  },
  {
    year: 2005,
    make: 'Saturn',
    models: 'Relay,L300,Ion,Vue',
  },
  {
    year: 2005,
    make: 'Scion',
    models: 'tC,xB,xA',
  },
  {
    year: 2005,
    make: 'Smart',
    models: 'Roadster,Fortwo,Cabrio,Forfour',
  },
  {
    year: 2005,
    make: 'Spyker',
    models: 'C8',
  },
  {
    year: 2005,
    make: 'Subaru',
    models: 'Baja,Outback,Legacy,Impreza,Forester',
  },
  {
    year: 2005,
    make: 'Suzuki',
    models: 'Swift+,Aerio,Reno,Forenza,Verona,XL-7,Grand Vitara',
  },
  {
    year: 2005,
    make: 'Toyota',
    models:
      'Sienna,Solara,Echo,Land Cruiser,Hilux,Tacoma,Highlander,MR2 Spyder,Tundra,Celica,Corolla,Prius,RAV4,Sequoia,4Runner,Yaris,Avalon,Camry,Matrix',
  },
  {
    year: 2005,
    make: 'Volkswagen',
    models: 'Polo,Van,Bora,Pointer,Sharan,Passat,Derby,Beetle,Lupo,Touareg,Jetta,Golf,Phaeton',
  },
  {
    year: 2005,
    make: 'Volvo',
    models: 'V70,V50,S80,S40,XC90,S60,XC70,C70',
  },
  {
    year: 2004,
    make: 'Acura',
    models: 'MDX,RSX,TL,NSX,RL,TSX,EL',
  },
  {
    year: 2004,
    make: 'Alfa Romeo',
    models: '156,147',
  },
  {
    year: 2004,
    make: 'Aston Martin',
    models: 'Vanquish,DB7',
  },
  {
    year: 2004,
    make: 'Audi',
    models: 'A4,A3,S6,A6 Quattro,A4 Quattro,TT Quattro,A3 Quattro,Allroad Quattro,S3,TT,A6,A8 Quattro,S4,RS6',
  },
  {
    year: 2004,
    make: 'Bentley',
    models: 'Continental,Arnage',
  },
  {
    year: 2004,
    make: 'BMW',
    models: '330xi,320i,330i,645Ci,760Li,Z4,325xi,745i,M3,X5,325Ci,330Ci,525i,X3,545i,530i,760i,745Li,325i',
  },
  {
    year: 2004,
    make: 'Buick',
    models: 'Century,Park Avenue,Rainier,Regal,Rendezvous,LeSabre',
  },
  {
    year: 2004,
    make: 'Cadillac',
    models: 'XLR,Escalade,Seville,Escalade ESV,Escalade EXT,DeVille,SRX,CTS',
  },
  {
    year: 2004,
    make: 'Chevrolet',
    models:
      'Impala,Avalanche 1500,LUV,Aveo,Silverado 2500,Tahoe,Trailblazer EXT,Zafira,Avalanche 2500,Blazer,Monte Carlo,Express 1500,Suburban 2500,Vectra,Astra,SSR,Corvette,Silverado 2500 HD,Meriva,Classic,P30,Cargo Van,Tornado,Astro,Colorado,Malibu,Optra,Sonora,S10,Suburban 1500,Tracker,Epica,Chevy,Corsa,Venture,Trailblazer,Cavalier,Express 2500,Express 3500,Silverado 1500',
  },
  {
    year: 2004,
    make: 'Chrysler',
    models: 'Town & Country,Cirrus,300M,Sebring,Intrepid,Concorde,Crossfire,Voyager,Pacifica,PT Cruiser',
  },
  {
    year: 2004,
    make: 'Dodge',
    models:
      'Sprinter 2500,Intrepid,Stratus,Verna,Neon,Ram 1500,Caravan,Durango,Viper,SX 2.0,Ram 2500,Atos,Grand Caravan,Dakota,H100',
  },
  {
    year: 2004,
    make: 'Ferrari',
    models: '575 M Maranello,456 M GTA,456 M GT,360,Enzo',
  },
  {
    year: 2004,
    make: 'Fiat',
    models: 'Palio',
  },
  {
    year: 2004,
    make: 'Ford',
    models:
      'Mustang,Econoline,EcoSport,Fiesta Ikon,Escape,F-250 Super Duty,Focus,Taurus,Courier,Excursion,F-250,Freestar,Mondeo,Crown Victoria,E-150,E-350 Club Wagon,Ikon,E-150 Club Wagon,Grand Marquis,F-150 Heritage,Ranger,Thunderbird,E-250,F-150,Fiesta,Ka,Lobo,Explorer Sport Trac,Expedition,Explorer',
  },
  {
    year: 2004,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2004,
    make: 'GMC',
    models:
      'Savana 1500,Envoy,Sonoma,Yukon XL 1500,Envoy XUV,Safari,Sierra 2500 HD,Jimmy,Yukon,Yukon XL 2500,Sierra 1500,Canyon,Savana 2500,Envoy XL,Sierra 2500',
  },
  {
    year: 2004,
    make: 'Honda',
    models: 'Insight,Element,Pilot,Civic,Odyssey,S2000,Accord,CR-V',
  },
  {
    year: 2004,
    make: 'Hummer',
    models: 'H2',
  },
  {
    year: 2004,
    make: 'Hyundai',
    models: 'XG350,Elantra,Tiburon,Santa Fe,Sonata,Accent',
  },
  {
    year: 2004,
    make: 'Infiniti',
    models: 'FX35,Q45,QX56,FX45,G35,I35,M45',
  },
  {
    year: 2004,
    make: 'Isuzu',
    models: 'Ascender,Rodeo,Axiom',
  },
  {
    year: 2004,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ8,XK8,X-Type,S-Type,XJR,XKR',
  },
  {
    year: 2004,
    make: 'Jeep',
    models: 'Wrangler,Grand Cherokee,Liberty,TJ',
  },
  {
    year: 2004,
    make: 'Kia',
    models: 'Sedona,Spectra,Optima,Amanti,Sorento,Magentis,Rio',
  },
  {
    year: 2004,
    make: 'Lamborghini',
    models: 'Gallardo,Murcielago',
  },
  {
    year: 2004,
    make: 'Land Rover',
    models: 'Freelander,Discovery,Range Rover',
  },
  {
    year: 2004,
    make: 'Lexus',
    models: 'RX330,ES330,IS300,LX470,LS430,SC430,GX470,GS430,GS300',
  },
  {
    year: 2004,
    make: 'Lincoln',
    models: 'Aviator,Navigator,LS,Town Car',
  },
  {
    year: 2004,
    make: 'Lotus',
    models: 'Elise,Esprit',
  },
  {
    year: 2004,
    make: 'Maserati',
    models: 'Coupe,Spyder,4200,Quattroporte',
  },
  {
    year: 2004,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2004,
    make: 'Mazda',
    models: '3,MPV,Tribute,6,RX-8,B2300,B3000,B4000,Miata',
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    models:
      'S500,CL55 AMG,CL600,G55 AMG,CLK55 AMG,E320,S55 AMG,SL500,SL600,SLK230,SLK320,C32 AMG,A160,G500,ML350,S430,A190,SLK200,E500,C200,CL230,Sprinter,CLK500,ML500,C230,CL500,S600,SLK32 AMG,C320,SL55 AMG,C240,CLK320,E55 AMG',
  },
  {
    year: 2004,
    make: 'Mercury',
    models: 'Grand Marquis,Sable,Monterey,Mountaineer,Marauder',
  },
  {
    year: 2004,
    make: 'MG',
    models: 'TF,ZR,ZT',
  },
  {
    year: 2004,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    models: 'Lancer,Montero,Endeavor,Space Star,Galant,Diamante,Eclipse,Montero Sport,Outlander',
  },
  {
    year: 2004,
    make: 'Nissan',
    models:
      'Xterra,Platina,X-Trail,Altima,Tsubame,Titan,Pathfinder Armada,Urvan,Maxima,Almera,Quest,350Z,Tsuru,Sentra,Pickup,Frontier,Murano,Pathfinder',
  },
  {
    year: 2004,
    make: 'Oldsmobile',
    models: 'Silhouette,Alero,Bravada',
  },
  {
    year: 2004,
    make: 'Peugeot',
    models: '406,307,Partner,206,607',
  },
  {
    year: 2004,
    make: 'Pontiac',
    models: 'Grand Am,Grand Prix,Vibe,Sunfire,GTO,Bonneville,Matiz,Montana,Aztek',
  },
  {
    year: 2004,
    make: 'Porsche',
    models: 'Cayenne,911,Boxster,Carrera GT',
  },
  {
    year: 2004,
    make: 'Rolls-Royce',
    models: 'Phantom',
  },
  {
    year: 2004,
    make: 'Rover',
    models: '75',
  },
  {
    year: 2004,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 2004,
    make: 'Saturn',
    models: 'L300,Ion,Vue',
  },
  {
    year: 2004,
    make: 'Scion',
    models: 'xB,xA',
  },
  {
    year: 2004,
    make: 'Smart',
    models: 'City-Coupe,Crossblade,Roadster,Cabrio',
  },
  {
    year: 2004,
    make: 'Subaru',
    models: 'Forester,Baja,Outback,Legacy,Impreza',
  },
  {
    year: 2004,
    make: 'Suzuki',
    models: 'Forenza,Verona,XL-7,Grand Vitara,Swift+,Aerio,Vitara',
  },
  {
    year: 2004,
    make: 'Toyota',
    models:
      'RAV4,Sequoia,4Runner,Yaris,Avalon,Camry,Matrix,Sienna,Solara,Echo,Land Cruiser,Hilux,Tacoma,Highlander,MR2 Spyder,Tundra,Celica,Corolla,Prius',
  },
  {
    year: 2004,
    make: 'Volkswagen',
    models: 'Touareg,Jetta,Golf,Sedan,Phaeton,R32,Polo,Van,Pointer,Sharan,Passat,Derby,Beetle,EuroVan',
  },
  {
    year: 2004,
    make: 'Volvo',
    models: 'S60,C70,XC70,V70,S80,V40,S40,XC90',
  },
  {
    year: 2003,
    make: 'Acura',
    models: 'EL,CL,MDX,RSX,TL,NSX,RL',
  },
  {
    year: 2003,
    make: 'Alfa Romeo',
    models: 'Spider,166,GTV,156,147',
  },
  {
    year: 2003,
    make: 'Aston Martin',
    models: 'Vanquish,DB7',
  },
  {
    year: 2003,
    make: 'Audi',
    models: 'A6,A8 Quattro,S8,RS6,A4,A3,A6 Quattro,A4 Quattro,S6,TT Quattro,Allroad Quattro,S3,TT',
  },
  {
    year: 2003,
    make: 'Bentley',
    models: 'Arnage,Azure,Continental',
  },
  {
    year: 2003,
    make: 'BMW',
    models: '530i,540i,745Li,325i,330xi,320i,M5,330i,760Li,Z4,325xi,745i,M3,X5,Z8,325Ci,330Ci,525i',
  },
  {
    year: 2003,
    make: 'Buick',
    models: 'Century,Rendezvous,LeSabre,Park Avenue,Regal',
  },
  {
    year: 2003,
    make: 'Cadillac',
    models: 'Escalade EXT,CTS,Escalade,DeVille,Seville,Escalade ESV',
  },
  {
    year: 2003,
    make: 'Chevrolet',
    models:
      'S10,Silverado 1500 HD,Suburban 1500,Tracker,Chevy,Corsa,Venture,Trailblazer,SSR,Corvette,Silverado 1500,Express 2500,Express 3500,Avalanche 1500,LUV,Silverado 2500,Tahoe,Trailblazer EXT,Zafira,Impala,Avalanche 2500,Monte Carlo,Suburban 2500,Chevy Pickup,Vectra,Astra,Cavalier,Silverado 2500 HD,Express 1500,P30,Cargo Van,Astro,Blazer,Malibu,Sonora,Chevy Monza',
  },
  {
    year: 2003,
    make: 'Chrysler',
    models: '300M,Concorde,Voyager,Town & Country,PT Cruiser,Intrepid,Sebring,Cirrus',
  },
  {
    year: 2003,
    make: 'Dodge',
    models:
      'Grand Caravan,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Dakota,H100,Intrepid,Neon,Caravan,Stratus,Ram 1500,Durango,Viper,Sprinter 2500,SX 2.0,Ram 2500,Atos',
  },
  {
    year: 2003,
    make: 'Ferrari',
    models: '550 Maranello,Enzo,575 M Maranello,456 M GTA,360,456 M GT',
  },
  {
    year: 2003,
    make: 'Ford',
    models:
      'Grand Marquis,Ranger,Taurus,E-250,Fiesta,Ka,Lobo,Escort,Explorer Sport Trac,Escape,Expedition,Explorer,Econoline,Excursion,Fiesta Ikon,F-150,F-250 Super Duty,Focus,Courier,F-250,Mondeo,Crown Victoria,E-150,E-350 Club Wagon,Mustang,Thunderbird,Windstar,Ikon,Explorer Sport,E-150 Club Wagon',
  },
  {
    year: 2003,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2003,
    make: 'GMC',
    models:
      'Sierra 2500 HD,Savana 2500,Envoy XL,Sierra 1500,Savana 1500,Sierra 2500,Sonoma,Yukon XL 1500,Safari,Envoy,Jimmy,Sierra 1500 HD,Yukon,Yukon XL 2500',
  },
  {
    year: 2003,
    make: 'Honda',
    models: 'Odyssey,S2000,Accord,CR-V,Insight,Element,Pilot,Civic',
  },
  {
    year: 2003,
    make: 'Hummer',
    models: 'H2',
  },
  {
    year: 2003,
    make: 'Hyundai',
    models: 'Sonata,Accent,XG350,Elantra,Tiburon,Santa Fe',
  },
  {
    year: 2003,
    make: 'Infiniti',
    models: 'I35,M45,QX4,FX35,Q45,FX45,G35',
  },
  {
    year: 2003,
    make: 'Isuzu',
    models: 'Rodeo Sport,Ascender,Rodeo,Axiom',
  },
  {
    year: 2003,
    make: 'Jaguar',
    models: 'S-Type,XJR,XKR,Vanden Plas,XJ8,XK8,X-Type',
  },
  {
    year: 2003,
    make: 'Jeep',
    models: 'Grand Cherokee,Liberty,TJ,Wrangler',
  },
  {
    year: 2003,
    make: 'Kia',
    models: 'Sorento,Magentis,Rio,Optima,Sedona,Spectra',
  },
  {
    year: 2003,
    make: 'Lamborghini',
    models: 'Murcielago',
  },
  {
    year: 2003,
    make: 'Land Rover',
    models: 'Freelander,Discovery,Range Rover',
  },
  {
    year: 2003,
    make: 'Lexus',
    models: 'GX470,GS430,GS300,ES300,IS300,LX470,RX300,LS430,SC430',
  },
  {
    year: 2003,
    make: 'Lincoln',
    models: 'Town Car,Aviator,Navigator,LS,Blackwood',
  },
  {
    year: 2003,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 2003,
    make: 'Maserati',
    models: 'Quattroporte,Spyder,3200 GT,Coupe',
  },
  {
    year: 2003,
    make: 'Maybach',
    models: '57,62',
  },
  {
    year: 2003,
    make: 'Mazda',
    models: 'Protege5,B2300,MPV,Miata,B3000,B4000,Protege,Tribute,6',
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    models:
      'SL600,C230,CL500,S600,SLK32 AMG,C320,ML320,SL55 AMG,C240,CLK320,CLK430,S500,CL55 AMG,CL600,G55 AMG,CLK55 AMG,E320,S55 AMG,SL500,SLK230,SLK320,C32 AMG,A160,CL200,G500,ML350,S430,A190,SLK200,E500,E55 AMG,C200,CL230,CLK500,ML500,ML55 AMG',
  },
  {
    year: 2003,
    make: 'Mercury',
    models: 'Mountaineer,Marauder,Grand Marquis,Sable',
  },
  {
    year: 2003,
    make: 'MG',
    models: 'ZT,TF',
  },
  {
    year: 2003,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    models: 'Eclipse,Montero Sport,Outlander,Lancer,Montero,Space Star,Galant,Diamante',
  },
  {
    year: 2003,
    make: 'Nissan',
    models:
      'Tsuru,Sentra,Pickup,Murano,Pathfinder,Xterra,Platina,X-Trail,Altima,Frontier,Tsubame,Urvan,Maxima,Almera,350Z',
  },
  {
    year: 2003,
    make: 'Oldsmobile',
    models: 'Alero,Bravada,Silhouette,Aurora',
  },
  {
    year: 2003,
    make: 'Peugeot',
    models: '206,607,406,307,Partner',
  },
  {
    year: 2003,
    make: 'Pontiac',
    models: 'Montana,Aztek,Grand Prix,Vibe,Bonneville,Grand Am,Sunfire',
  },
  {
    year: 2003,
    make: 'Porsche',
    models: '911,Boxster,Cayenne',
  },
  {
    year: 2003,
    make: 'Rover',
    models: '75',
  },
  {
    year: 2003,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 2003,
    make: 'Saturn',
    models: 'L300,L200,Vue,Ion,LW300,LW200',
  },
  {
    year: 2003,
    make: 'Smart',
    models: 'City-Coupe',
  },
  {
    year: 2003,
    make: 'Subaru',
    models: 'Impreza,Forester,Legacy,Baja,Outback',
  },
  {
    year: 2003,
    make: 'Suzuki',
    models: 'Aerio,Vitara,XL-7,Grand Vitara',
  },
  {
    year: 2003,
    make: 'Toyota',
    models:
      'Tacoma,Highlander,MR2 Spyder,Tundra,Celica,Corolla,Prius,RAV4,Sequoia,4Runner,Avalon,Camry,Matrix,Sienna,Solara,Echo,Land Cruiser',
  },
  {
    year: 2003,
    make: 'Volkswagen',
    models: 'Pointer,Sharan,Passat,Derby,Beetle,EuroVan,Jetta,Sedan,Polo,Golf,R32,Van',
  },
  {
    year: 2003,
    make: 'Volvo',
    models: 'S80,V40,S40,V70,XC90,S60,C70,XC70',
  },
  {
    year: 2002,
    make: 'Acura',
    models: 'RSX,TL,NSX,RL,EL,CL,MDX',
  },
  {
    year: 2002,
    make: 'Alfa Romeo',
    models: '166,147,Sportwagon,156',
  },
  {
    year: 2002,
    make: 'Aston Martin',
    models: 'Vanquish,DB7',
  },
  {
    year: 2002,
    make: 'Audi',
    models: 'A6 Quattro,A4 Quattro,RS4,S4,S6,TT Quattro,Allroad Quattro,S3,TT,A6,A8 Quattro,S8,A4,A3',
  },
  {
    year: 2002,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage',
  },
  {
    year: 2002,
    make: 'BMW',
    models: '330i,Z3,325xi,745i,M3,X5,Z8,325Ci,330Ci,525i,530i,540i,745Li,325i,330xi,320i,M5',
  },
  {
    year: 2002,
    make: 'Buick',
    models: 'Regal,Century,Rendezvous,LeSabre,Park Avenue',
  },
  {
    year: 2002,
    make: 'Cadillac',
    models: 'DeVille,Seville,Escalade EXT,Eldorado,Escalade',
  },
  {
    year: 2002,
    make: 'Chevrolet',
    models:
      'G30,Astra,Cavalier,Silverado 2500 HD,Express 1500,Prizm,Astro,Blazer,Malibu,Sonora,S10,Silverado 1500 HD,Suburban 1500,Tracker,Chevy,Corsa,P30,Trailblazer,C1500,Camaro,Silverado 1500,Corvette,Express 2500,Express 3500,Avalanche 1500,LUV,Silverado 2500,Tahoe,Trailblazer EXT,Zafira,Impala,Venture,Avalanche 2500,Monte Carlo,Suburban 2500,Chevy Pickup',
  },
  {
    year: 2002,
    make: 'Chrysler',
    models: 'Sebring,Cirrus,Prowler,Intrepid,300M,Concorde,Voyager,Town & Country,PT Cruiser,Neon,Grand Voyager',
  },
  {
    year: 2002,
    make: 'Daewoo',
    models: 'Leganza,Lanos,Nubira',
  },
  {
    year: 2002,
    make: 'Dodge',
    models:
      'Neon,Ram 1500,Caravan,Durango,Viper,Ram 2500,Atos,Grand Caravan,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Dakota,Intrepid,Stratus',
  },
  {
    year: 2002,
    make: 'Ferrari',
    models: '456 M GTA,360,456 M GT,550 Maranello,575 M Maranello',
  },
  {
    year: 2002,
    make: 'Ford',
    models:
      'F-250 Super Duty,Focus,Courier,F-250,Mondeo,Crown Victoria,E-250 Econoline,Mustang,Thunderbird,Windstar,E-150,Grand Marquis,Ranger,Taurus,Ka,Lobo,Explorer Sport,Escape,E-150 Econoline,Explorer Sport Trac,E-150 Econoline Club Wagon,E-350 Club Wagon,Escort,Expedition,Explorer,Econoline,Excursion,F-150,Fiesta Ikon',
  },
  {
    year: 2002,
    make: 'Freightliner',
    models: 'Sprinter 2500',
  },
  {
    year: 2002,
    make: 'GMC',
    models:
      'Jimmy,Sierra 1500 HD,Yukon,Yukon XL 2500,Envoy,Sierra 2500 HD,Savana 2500,Envoy XL,Sierra 1500,Savana 1500,Sierra 2500,Sonoma,Safari,Yukon XL 1500',
  },
  {
    year: 2002,
    make: 'Honda',
    models: 'Civic,Odyssey,S2000,Passport,Accord,CR-V,Insight',
  },
  {
    year: 2002,
    make: 'Hyundai',
    models: 'Santa Fe,Sonata,Accent,XG350,Elantra',
  },
  {
    year: 2002,
    make: 'Infiniti',
    models: 'G20,I35,QX4,Q45',
  },
  {
    year: 2002,
    make: 'Isuzu',
    models: 'Rodeo,Trooper,Axiom,Rodeo Sport',
  },
  {
    year: 2002,
    make: 'Jaguar',
    models: 'XJ8,XK8,X-Type,S-Type,XJR,XKR,Vanden Plas',
  },
  {
    year: 2002,
    make: 'Jeep',
    models: 'Grand Cherokee,Liberty,TJ,Wrangler',
  },
  {
    year: 2002,
    make: 'Kia',
    models: 'Sedona,Spectra,Sportage,Magentis,Rio,Optima',
  },
  {
    year: 2002,
    make: 'Lamborghini',
    models: 'Murcielago',
  },
  {
    year: 2002,
    make: 'Land Rover',
    models: 'Discovery,Range Rover,Freelander',
  },
  {
    year: 2002,
    make: 'Lexus',
    models: 'RX300,LS430,SC430,GS430,GS300,ES300,IS300,LX470',
  },
  {
    year: 2002,
    make: 'Lincoln',
    models: 'LS,Blackwood,Town Car,Continental,Navigator',
  },
  {
    year: 2002,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 2002,
    make: 'Maserati',
    models: 'Quattroporte,Spyder,3200 GT',
  },
  {
    year: 2002,
    make: 'Mazda',
    models: 'Protege,Tribute,B2300,MPV,Miata,B3000,Millenia,626,Protege5,B4000',
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    models:
      'S55 AMG,SL500,SL55 AMG,SLK230,SLK320,C32 AMG,A160,CL200,G500,S430,A190,C200,CL230,E55 AMG,ML500,ML55 AMG,C230,CL500,E320,S600,SLK32 AMG,C320,ML320,SL600,C240,CLK320,CLK430,E430,S500,CL55 AMG,CL600,CLK55 AMG,E500',
  },
  {
    year: 2002,
    make: 'Mercury',
    models: 'Grand Marquis,Sable,Villager,Mountaineer,Cougar',
  },
  {
    year: 2002,
    make: 'Mini',
    models: 'Cooper',
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    models: 'Montero,Galant,Diamante,Eclipse,Mirage,Montero Sport,Lancer',
  },
  {
    year: 2002,
    make: 'Nissan',
    models: 'Urvan,Maxima,Almera,Tsuru,Sentra,Pickup,Pathfinder,Quest,Xterra,Platina,X-Trail,Altima,Frontier,Tsubame',
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    models: 'Silhouette,Aurora,Intrigue,Alero,Bravada',
  },
  {
    year: 2002,
    make: 'Peugeot',
    models: '206,607,306,406',
  },
  {
    year: 2002,
    make: 'Pontiac',
    models: 'Bonneville,Grand Am,Sunfire,Montana,Firebird,Aztek,Grand Prix',
  },
  {
    year: 2002,
    make: 'Porsche',
    models: '911,Boxster',
  },
  {
    year: 2002,
    make: 'Rolls-Royce',
    models: 'Park Ward,Silver Seraph,Corniche',
  },
  {
    year: 2002,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 2002,
    make: 'Saturn',
    models: 'SL,SL2,LW300,LW200,SC1,L100,L300,SC2,SL1,L200,Vue',
  },
  {
    year: 2002,
    make: 'Subaru',
    models: 'Outback,Impreza,Forester,Legacy',
  },
  {
    year: 2002,
    make: 'Suzuki',
    models: 'Grand Vitara,Aerio,Vitara,Esteem,XL-7',
  },
  {
    year: 2002,
    make: 'Toyota',
    models:
      'Avalon,Camry,Sienna,Solara,Echo,Land Cruiser,Tacoma,Highlander,MR2 Spyder,Tundra,Celica,Corolla,Prius,RAV4,Sequoia,4Runner',
  },
  {
    year: 2002,
    make: 'Volkswagen',
    models: 'Golf,Pointer,Sharan,Passat,Cabrio,Derby,Beetle,EuroVan,Jetta,Sedan',
  },
  {
    year: 2002,
    make: 'Volvo',
    models: 'V70,S80,V40,S40,S60,C70',
  },
  {
    year: 2001,
    make: 'Acura',
    models: 'EL,CL,MDX,TL,NSX,RL,Integra',
  },
  {
    year: 2001,
    make: 'Aston Martin',
    models: 'Vanquish,DB7',
  },
  {
    year: 2001,
    make: 'Audi',
    models: 'A4,A3,A6 Quattro,A4 Quattro,S4,S6,TT Quattro,Allroad Quattro,S3,TT,A6,A8 Quattro,S8',
  },
  {
    year: 2001,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage',
  },
  {
    year: 2001,
    make: 'BMW',
    models: '540i,750iL,325i,330xi,320i,M5,330i,Z3,323i,325xi,740iL,M3,X5,Z8,325Ci,330Ci,525i,740i,530i',
  },
  {
    year: 2001,
    make: 'Buick',
    models: 'Park Avenue,Century,Regal,LeSabre',
  },
  {
    year: 2001,
    make: 'Cadillac',
    models: 'Catera,DeVille,Seville,Eldorado',
  },
  {
    year: 2001,
    make: 'Chevrolet',
    models:
      'C1500,Camaro,Silverado 1500,Corvette,Express 2500,Express 3500,LUV,Lumina,Silverado 2500,Tahoe,Impala,Metro,Monte Carlo,Suburban 2500,Chevy Pickup,G30,Astra,Cavalier,Silverado 2500 HD,Express 1500,Venture,Prizm,Astro,Blazer,Malibu,Sonora,S10,Silverado 1500 HD,Suburban 1500,Tracker,Chevy,P30',
  },
  {
    year: 2001,
    make: 'Chrysler',
    models: 'PT Cruiser,Neon,Grand Voyager,LHS,Sebring,Prowler,Cirrus,Concorde,300M,Intrepid,Town & Country,Voyager',
  },
  {
    year: 2001,
    make: 'Daewoo',
    models: 'Lanos,Nubira,Leganza',
  },
  {
    year: 2001,
    make: 'Dodge',
    models:
      'Stratus,Ram 1500,Durango,Neon,Viper,Ram 2500,Caravan,Atos,Ramcharger,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Dakota,Grand Caravan,Intrepid',
  },
  {
    year: 2001,
    make: 'Ferrari',
    models: '360,456 M,F355 Spider,550 Maranello',
  },
  {
    year: 2001,
    make: 'Ford',
    models:
      'E-150 Econoline,Explorer Sport Trac,E-150 Econoline Club Wagon,Escort,Expedition,Explorer,Econoline,Explorer Sport,F-150,F-100 Ranger,F-250 Super Duty,Focus,Courier,F-250,Fiesta,Mondeo,Crown Victoria,E-250 Econoline,Sable,Mustang,Windstar,Excursion,Grand Marquis,Ranger,Taurus,Ka,Lobo,Escape',
  },
  {
    year: 2001,
    make: 'GMC',
    models:
      'Savana 2500,Savana 1500,Jimmy,Sierra 1500,Sonoma,Safari,Sierra 2500,Yukon XL 1500,Sierra 1500 HD,Yukon,Yukon XL 2500,Sierra 2500 HD',
  },
  {
    year: 2001,
    make: 'Honda',
    models: 'Accord,CR-V,Insight,Prelude,Civic,Odyssey,S2000,Passport',
  },
  {
    year: 2001,
    make: 'Hyundai',
    models: 'Tiburon,XG300,Elantra,Santa Fe,Sonata,Accent',
  },
  {
    year: 2001,
    make: 'Infiniti',
    models: 'QX4,Q45,G20,I30',
  },
  {
    year: 2001,
    make: 'Isuzu',
    models: 'Rodeo Sport,VehiCROSS,Rodeo,Trooper',
  },
  {
    year: 2001,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ8,XK8,X-Type,S-Type,XJR,XKR',
  },
  {
    year: 2001,
    make: 'Jeep',
    models: 'TJ,Cherokee,Wrangler,Grand Cherokee',
  },
  {
    year: 2001,
    make: 'Kia',
    models: 'Magentis,Rio,Optima,Sephia,Spectra,Sportage',
  },
  {
    year: 2001,
    make: 'Lamborghini',
    models: 'Murcielago,Diablo',
  },
  {
    year: 2001,
    make: 'Land Rover',
    models: 'Freelander,Discovery,Range Rover',
  },
  {
    year: 2001,
    make: 'Lexus',
    models: 'GS300,ES300,IS300,LX470,RX300,LS430,GS430',
  },
  {
    year: 2001,
    make: 'Lincoln',
    models: 'Continental,Navigator,LS,Town Car',
  },
  {
    year: 2001,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 2001,
    make: 'Maserati',
    models: 'Quattroporte,3200 GT',
  },
  {
    year: 2001,
    make: 'Mazda',
    models: 'MPV,Miata,B3000,Millenia,626,B4000,Protege,B2300,B2500,Tribute',
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    models:
      'SL600,C240,CLK320,CLK430,E430,ML430,S500,CL55 AMG,CL600,CLK55 AMG,S55 AMG,SL500,SLK230,SLK320,A160,S430,A190,C200,E55 AMG,G500,ML55 AMG,CL500,E320,S600,C320,ML320',
  },
  {
    year: 2001,
    make: 'Mercury',
    models: 'Cougar,Grand Marquis,Sable,Villager,Mountaineer',
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    models: 'Montero,Galant,Diamante,Eclipse,Mirage,Montero Sport',
  },
  {
    year: 2001,
    make: 'Nissan',
    models: 'Pathfinder,Quest,Xterra,Altra EV,Altima,Frontier,Tsubame,Urvan,Maxima,Almera,Tsuru,Sentra,Pickup',
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    models: 'Bravada,Silhouette,Aurora,Intrigue,Alero',
  },
  {
    year: 2001,
    make: 'Peugeot',
    models: '406,206,306',
  },
  {
    year: 2001,
    make: 'Plymouth',
    models: 'Neon,Prowler',
  },
  {
    year: 2001,
    make: 'Pontiac',
    models: 'Firebird,Aztek,Grand Prix,Bonneville,Grand Am,Sunfire,Montana',
  },
  {
    year: 2001,
    make: 'Porsche',
    models: '911,Boxster',
  },
  {
    year: 2001,
    make: 'Rolls-Royce',
    models: 'Corniche,Silver Seraph',
  },
  {
    year: 2001,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 2001,
    make: 'Saturn',
    models: 'L100,SW2,SL,SL2,LW300,LW200,SC1,L300,L200,SC2,SL1',
  },
  {
    year: 2001,
    make: 'Subaru',
    models: 'Forester,Legacy,Outback,Impreza',
  },
  {
    year: 2001,
    make: 'Suzuki',
    models: 'Grand Vitara,Swift,Vitara,Esteem',
  },
  {
    year: 2001,
    make: 'Toyota',
    models:
      'Tundra,Celica,Corolla,Prius,RAV4,Sequoia,4Runner,Avalon,Camry,Sienna,Solara,Echo,Land Cruiser,Tacoma,Highlander,MR2 Spyder',
  },
  {
    year: 2001,
    make: 'Volkswagen',
    models: 'EuroVan,Jetta,Sedan,Golf,Pointer,Passat,Cabrio,Derby,Beetle',
  },
  {
    year: 2001,
    make: 'Volvo',
    models: 'S40,S60,C70,V70,S80,V40',
  },
  {
    year: 2000,
    make: 'Acura',
    models: 'NSX,RL,Integra,EL,TL',
  },
  {
    year: 2000,
    make: 'Aston Martin',
    models: 'DB7',
  },
  {
    year: 2000,
    make: 'Audi',
    models: 'S3,TT,A6,A8 Quattro,A8,A4,A3,A6 Quattro,A4 Quattro,S4,TT Quattro',
  },
  {
    year: 2000,
    make: 'Bentley',
    models: 'Arnage,Azure,Continental',
  },
  {
    year: 2000,
    make: 'BMW',
    models: '323i,740i,328Ci,328i,540i,750iL,M5,528i,Z3,323Ci,323ti,740iL,X5,Z8',
  },
  {
    year: 2000,
    make: 'Buick',
    models: 'LeSabre,Park Avenue,Century,Regal',
  },
  {
    year: 2000,
    make: 'Cadillac',
    models: 'Escalade,Eldorado,Seville,DeVille,Catera',
  },
  {
    year: 2000,
    make: 'Chevrolet',
    models:
      'Astro,Malibu,Sonora,C35,P30,S10,Suburban 1500,Tracker,Chevy,Cargo Van,Venture,K2500,C1500,C2500,Camaro,Silverado 1500,Corvette,Express 2500,Express 3500,LUV,Lumina,Blazer,Silverado 2500,Tahoe,Impala,Metro,Monte Carlo,Suburban 2500,Chevy Pickup,Astra,Cavalier,Express 1500,L30,Prizm',
  },
  {
    year: 2000,
    make: 'Chrysler',
    models: 'Concorde,300M,Town & Country,Intrepid,Voyager,Grand Voyager,Sebring,Neon,LHS,Cirrus',
  },
  {
    year: 2000,
    make: 'Daewoo',
    models: 'Leganza,Lanos,Nubira',
  },
  {
    year: 2000,
    make: 'Dodge',
    models:
      'Ram 2500,Ramcharger,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Dakota,Intrepid,Avenger,Neon,Stratus,Grand Caravan,Ram 1500,Caravan,Durango,Viper',
  },
  {
    year: 2000,
    make: 'Ferrari',
    models: '550 Maranello,360,456 M,F355 Spider',
  },
  {
    year: 2000,
    make: 'Ford',
    models:
      'Mystique,Sable,Mustang,Ranger,Windstar,Contour,Excursion,Grand Marquis,Taurus,Lobo,E-150 Econoline,E-150 Econoline Club Wagon,Expedition,Explorer,Econoline,F-150,F-100 Ranger,F-250 Super Duty,Focus,Escort,F-250,Fiesta,Crown Victoria,E-250 Econoline',
  },
  {
    year: 2000,
    make: 'GMC',
    models:
      'Yukon XL 2500,K2500,Savana 2500,Savana 1500,Jimmy,Sierra 1500,Sonoma,Safari,Sierra 2500,Yukon,Yukon XL 1500,C2500',
  },
  {
    year: 2000,
    make: 'Honda',
    models: 'Civic,Odyssey,S2000,Passport,Accord,CR-V,Insight,Prelude',
  },
  {
    year: 2000,
    make: 'Hyundai',
    models: 'Sonata,Accent,Tiburon,Elantra',
  },
  {
    year: 2000,
    make: 'Infiniti',
    models: 'G20,I30,QX4,Q45',
  },
  {
    year: 2000,
    make: 'Isuzu',
    models: 'Trooper,VehiCROSS,Hombre,Amigo,Rodeo',
  },
  {
    year: 2000,
    make: 'Jaguar',
    models: 'S-Type,XJR,XKR,Vanden Plas,XJ8,XK8',
  },
  {
    year: 2000,
    make: 'Jeep',
    models: 'TJ,Cherokee,Wrangler,Grand Cherokee',
  },
  {
    year: 2000,
    make: 'Kia',
    models: 'Sportage,Sephia,Spectra',
  },
  {
    year: 2000,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 2000,
    make: 'Land Rover',
    models: 'Discovery,Range Rover,Freelander',
  },
  {
    year: 2000,
    make: 'Lexus',
    models: 'LS400,SC300,GS400,GS300,ES300,SC400,LX470,RX300',
  },
  {
    year: 2000,
    make: 'Lincoln',
    models: 'Town Car,Continental,Navigator,LS',
  },
  {
    year: 2000,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 2000,
    make: 'Maserati',
    models: 'Quattroporte,3200 GT',
  },
  {
    year: 2000,
    make: 'Mazda',
    models: 'B4000,MPV,Miata,B3000,Millenia,626,Protege,B2500',
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    models:
      'E55 AMG,G500,ML55 AMG,S600,C43 AMG,CL500,E320,ML320,SL600,CLK320,CLK430,ML430,E430,S500,SL500,SLK230,C230,A160,S430,C280',
  },
  {
    year: 2000,
    make: 'Mercury',
    models: 'Villager,Mountaineer,Cougar,Grand Marquis,Mystique,Sable',
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    models: 'Eclipse,Mirage,Montero Sport,Montero,Galant,Diamante',
  },
  {
    year: 2000,
    make: 'Nissan',
    models: 'Tsuru,Sentra,Lucino,Pickup,Pathfinder,Quest,Xterra,Altra EV,Altima,Frontier,Tsubame,Urvan,Maxima',
  },
  {
    year: 2000,
    make: 'Oldsmobile',
    models: 'Alero,Bravada,Silhouette,Intrigue',
  },
  {
    year: 2000,
    make: 'Peugeot',
    models: '206,306,406',
  },
  {
    year: 2000,
    make: 'Plymouth',
    models: 'Neon,Voyager,Grand Voyager,Prowler,Breeze',
  },
  {
    year: 2000,
    make: 'Pontiac',
    models: 'Firefly,Montana,Firebird,Grand Prix,Bonneville,Grand Am,Sunfire',
  },
  {
    year: 2000,
    make: 'Porsche',
    models: '911,Boxster',
  },
  {
    year: 2000,
    make: 'Rolls-Royce',
    models: 'Silver Seraph,Corniche',
  },
  {
    year: 2000,
    make: 'Rover',
    models: '75',
  },
  {
    year: 2000,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 2000,
    make: 'Saturn',
    models: 'SC1,LS,LW1,SC2,SL1,SW2,LS2,LW2,SL,SL2,LS1',
  },
  {
    year: 2000,
    make: 'Subaru',
    models: 'Impreza,Legacy,Outback,Forester',
  },
  {
    year: 2000,
    make: 'Suzuki',
    models: 'Vitara,Esteem,Grand Vitara,Swift',
  },
  {
    year: 2000,
    make: 'Toyota',
    models: 'Sienna,Solara,Echo,Land Cruiser,Tacoma,MR2 Spyder,Tundra,Celica,Corolla,RAV4,4Runner,Avalon,Camry',
  },
  {
    year: 2000,
    make: 'Volkswagen',
    models: 'Golf,Pointer,Passat,Cabrio,Derby,Beetle,EuroVan,Jetta,Sedan',
  },
  {
    year: 2000,
    make: 'Volvo',
    models: 'S80,V40,S70,S40,C70,V70',
  },
  {
    year: 1999,
    make: 'Acura',
    models: 'SLX,TL,NSX,RL,CL,Integra,EL',
  },
  {
    year: 1999,
    make: 'Audi',
    models: 'A6 Quattro,A4 Quattro,A6,A8 Quattro,TT,A8,A4,A3',
  },
  {
    year: 1999,
    make: 'Bentley',
    models: 'Azure,Continental,Arnage',
  },
  {
    year: 1999,
    make: 'BMW',
    models: '328is,850Ci,528i,Z3,318is,323ti,740iL,M5,318ti,323i,740i,328i,540i,750iL,M3,318i,323is',
  },
  {
    year: 1999,
    make: 'Buick',
    models: 'Park Avenue,Century,Regal,LeSabre,Riviera',
  },
  {
    year: 1999,
    make: 'Cadillac',
    models: 'Eldorado,Seville,DeVille,Catera,Escalade',
  },
  {
    year: 1999,
    make: 'Chevrolet',
    models:
      'Blazer,Metro,Chevy Pickup,Cavalier,Express 1500,L30,K1500,K2500 Suburban,P30,Prizm,Astro,Malibu,C2500 Suburban,Tigra,C35,K1500 Suburban,C1500,Monte Carlo,S10,Tracker,Chevy,Cargo Van,Venture,K2500,C1500 Suburban,Silverado,C2500,Camaro,Silverado 1500,Corvette,Express 2500,Express 3500,LUV,Lumina,Silverado 2500,Suburban 1500,Tahoe',
  },
  {
    year: 1999,
    make: 'Chrysler',
    models: 'Cirrus,Voyager,Concorde,300M,Town & Country,Grand Voyager,Intrepid,Sebring,LHS',
  },
  {
    year: 1999,
    make: 'Daewoo',
    models: 'Leganza,Lanos,Nubira',
  },
  {
    year: 1999,
    make: 'Dodge',
    models:
      'Stratus,Grand Caravan,Ram 1500,Caravan,Durango,Viper,Ram 2500,Ramcharger,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Dakota,Intrepid,Neon,Avenger',
  },
  {
    year: 1999,
    make: 'Ferrari',
    models: 'F355 Spider,550 Maranello,F355,456 M',
  },
  {
    year: 1999,
    make: 'Ford',
    models:
      'F-150,F-100 Ranger,F-250 Super Duty,Escort,Fiesta,Crown Victoria,E-250 Econoline,Mystique,Sable,Mustang,Ranger,Contour,Grand Marquis,Taurus,Windstar,Lobo,E-150 Econoline,E-150 Econoline Club Wagon,Expedition,Explorer,F-250,Econoline',
  },
  {
    year: 1999,
    make: 'GMC',
    models:
      'Jimmy,Sonoma,Safari,Sierra 2500,Yukon,C2500,K2500 Suburban,C2500 Suburban,K1500 Suburban,K2500,C1500 Suburban,C1500,Savana 2500,K1500,Savana 1500,Sierra 1500',
  },
  {
    year: 1999,
    make: 'Honda',
    models: 'Prelude,Civic,Odyssey,Passport,Accord,CR-V,EV Plus',
  },
  {
    year: 1999,
    make: 'Hyundai',
    models: 'Elantra,Sonata,Accent,Tiburon',
  },
  {
    year: 1999,
    make: 'Infiniti',
    models: 'Q45,G20,I30,QX4',
  },
  {
    year: 1999,
    make: 'Isuzu',
    models: 'Hombre,Oasis,Amigo,Rodeo,Trooper,VehiCROSS',
  },
  {
    year: 1999,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ8,XK8,XJR,S-Type',
  },
  {
    year: 1999,
    make: 'Jeep',
    models: 'Grand Cherokee,TJ,Cherokee,Wrangler',
  },
  {
    year: 1999,
    make: 'Kia',
    models: 'Sephia,Sportage',
  },
  {
    year: 1999,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1999,
    make: 'Land Rover',
    models: 'Freelander,Discovery,Range Rover',
  },
  {
    year: 1999,
    make: 'Lexus',
    models: 'SC400,LX470,RX300,LS400,SC300,GS400,GS300,ES300',
  },
  {
    year: 1999,
    make: 'Lincoln',
    models: 'Navigator,Town Car,Continental',
  },
  {
    year: 1999,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1999,
    make: 'Maserati',
    models: 'Quattroporte,3200 GT',
  },
  {
    year: 1999,
    make: 'Mazda',
    models: 'Millenia,626,Protege,B2500,B3000,B4000,Miata',
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    models:
      'SL500,SLK230,C230,S420,S430,S600,C280,E280,E420,E55 AMG,G500,C43 AMG,CL500,CL600,E300,E320,SL60 AMG,ML320,S320,SL600,CLK320,CLK430,ML430,E430,S500',
  },
  {
    year: 1999,
    make: 'Mercury',
    models: 'Cougar,Tracer,Grand Marquis,Mystique,Sable,Villager,Mountaineer',
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    models: 'Montero,Galant,3000GT,Diamante,Eclipse,Mirage,Montero Sport',
  },
  {
    year: 1999,
    make: 'Nissan',
    models: 'Altima,Frontier,Tsubame,Maxima,Tsuru,Sentra,Lucino,Pickup,Pathfinder,Quest,Altra EV',
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    models: 'Silhouette,Cutlass,Intrigue,Alero,88,LSS,Bravada,Aurora',
  },
  {
    year: 1999,
    make: 'Peugeot',
    models: '306,405',
  },
  {
    year: 1999,
    make: 'Plymouth',
    models: 'Voyager,Neon,Grand Voyager,Prowler,Breeze',
  },
  {
    year: 1999,
    make: 'Pontiac',
    models: 'Bonneville,Grand Am,Sunfire,Firefly,Montana,Firebird,Trans Sport,Grand Prix',
  },
  {
    year: 1999,
    make: 'Porsche',
    models: '911,Boxster',
  },
  {
    year: 1999,
    make: 'Rolls-Royce',
    models: 'Silver Seraph',
  },
  {
    year: 1999,
    make: 'Rover',
    models: 'Mini',
  },
  {
    year: 1999,
    make: 'Saab',
    models: '9-3,9-5',
  },
  {
    year: 1999,
    make: 'Saturn',
    models: 'SL,SL2,SC1,SW1,SC2,SL1,SW2',
  },
  {
    year: 1999,
    make: 'Subaru',
    models: 'Impreza,Legacy,Forester',
  },
  {
    year: 1999,
    make: 'Suzuki',
    models: 'Grand Vitara,Swift,Vitara,Esteem',
  },
  {
    year: 1999,
    make: 'Toyota',
    models: 'RAV4,4Runner,Paseo,Avalon,Camry,Sienna,Solara,Land Cruiser,Tercel,Tacoma,Celica,Corolla',
  },
  {
    year: 1999,
    make: 'Volkswagen',
    models: 'Jetta,Sedan,Golf,Pointer,Passat,Cabrio,Derby,Beetle,EuroVan',
  },
  {
    year: 1999,
    make: 'Volvo',
    models: 'C70,S40,V40,S80,V70,S70',
  },
  {
    year: 1998,
    make: 'Acura',
    models: 'Integra,EL,SLX,TL,NSX,RL,CL',
  },
  {
    year: 1998,
    make: 'Aston Martin',
    models: 'DB7',
  },
  {
    year: 1998,
    make: 'Audi',
    models: 'A8,A4,A3,A6 Quattro,A4 Quattro,Cabriolet,A6,A8 Quattro',
  },
  {
    year: 1998,
    make: 'Bentley',
    models: 'Turbo R,Azure,Brooklands,Continental',
  },
  {
    year: 1998,
    make: 'BMW',
    models: '328i,540i,750iL,M3,318i,323is,328is,850Ci,528i,Z3,318is,740iL,318ti,323i,740i',
  },
  {
    year: 1998,
    make: 'Buick',
    models: 'Riviera,Skylark,Park Avenue,Century,LeSabre,Regal',
  },
  {
    year: 1998,
    make: 'Cadillac',
    models: 'Eldorado,DeVille,Catera,Seville',
  },
  {
    year: 1998,
    make: 'Chevrolet',
    models:
      'Venture,C1500 Suburban,Monte Carlo,Silverado,Camaro,Corvette,Express 2500,Express 3500,LUV,Lumina,Suburban 1500,Tahoe,K2500,Blazer,Metro,C2500,Cavalier,Express 1500,K1500,K2500 Suburban,P30,Prizm,Astro,Malibu,C2500 Suburban,C35,K1500 Suburban,C1500,S10,Tracker,Chevy,Cargo Van',
  },
  {
    year: 1998,
    make: 'Chrysler',
    models: 'Sebring,300M,Town & Country,Cirrus,Voyager,Concorde,Grand Voyager,Intrepid',
  },
  {
    year: 1998,
    make: 'Dodge',
    models:
      'Ram 2500 Van,Ram 3500 Van,Dakota,B2500,Intrepid,Neon,Avenger,Stratus,Grand Caravan,Ram 1500,Caravan,Durango,Ram 1500 Van,Viper,Ram 2500',
  },
  {
    year: 1998,
    make: 'Eagle',
    models: 'Talon',
  },
  {
    year: 1998,
    make: 'Ferrari',
    models: 'F355 F1,456 GTA,F355 Spider,456 GT,F355 GTS,550 Maranello',
  },
  {
    year: 1998,
    make: 'Ford',
    models:
      'E-150 Econoline Club Wagon,E-150 Econoline,Expedition,Explorer,F-250,F-150,Escort,F-100 Ranger,Contour,Club Wagon,Fiesta,Taurus,Crown Victoria,E-250 Econoline,Mystique,Sable,Mustang,Ranger,Lobo,Grand Marquis,Windstar',
  },
  {
    year: 1998,
    make: 'GMC',
    models:
      'K2500,C1500 Suburban,Savana 1500,Yukon,C1500,C2500,Jimmy,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban,K1500 Suburban,Savana 2500',
  },
  {
    year: 1998,
    make: 'Honda',
    models: 'Passport,Accord,CR-V,EV Plus,Prelude,Civic,Odyssey',
  },
  {
    year: 1998,
    make: 'Hyundai',
    models: 'Tiburon,Elantra,Sonata,Accent',
  },
  {
    year: 1998,
    make: 'Infiniti',
    models: 'QX4,Q45,I30',
  },
  {
    year: 1998,
    make: 'Isuzu',
    models: 'Hombre,Oasis,Amigo,Rodeo,Trooper',
  },
  {
    year: 1998,
    make: 'Jaguar',
    models: 'XJR,Vanden Plas,XJ8,XK8',
  },
  {
    year: 1998,
    make: 'Jeep',
    models: 'TJ,Cherokee,Grand Cherokee,Wrangler',
  },
  {
    year: 1998,
    make: 'Kia',
    models: 'Sportage,Sephia',
  },
  {
    year: 1998,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1998,
    make: 'Land Rover',
    models: 'Discovery,Range Rover',
  },
  {
    year: 1998,
    make: 'Lexus',
    models: 'GS400,GS300,ES300,SC400,LX470,LS400,SC300',
  },
  {
    year: 1998,
    make: 'Lincoln',
    models: 'Continental,Mark VIII,Town Car,Navigator',
  },
  {
    year: 1998,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1998,
    make: 'Mazda',
    models: 'B2500,Millenia,626,Protege,B4000,MPV,B3000',
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    models:
      'SL60 AMG,ML320,S320,SL600,CLK320,E430,S500,E55 AMG,SL500,SLK230,C230,S420,S600,C280,ML430,E420,C43 AMG,CL500,CL600,E300,E320',
  },
  {
    year: 1998,
    make: 'Mercury',
    models: 'Mountaineer,Villager,Mystique,Tracer,Grand Marquis,Sable',
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    models: 'Mirage,Montero Sport,Montero,Galant,3000GT,Diamante,Eclipse',
  },
  {
    year: 1998,
    make: 'Nissan',
    models: 'Sentra,Lucino,Pickup,Frontier,Pathfinder,Quest,200SX,Altima,Tsubame,Maxima,240SX,Tsuru',
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    models: '88,LSS,Bravada,Achieva,Aurora,Silhouette,Cutlass,Intrigue,Regency',
  },
  {
    year: 1998,
    make: 'Peugeot',
    models: '306,405',
  },
  {
    year: 1998,
    make: 'Plymouth',
    models: 'Breeze,Voyager,Neon,Grand Voyager',
  },
  {
    year: 1998,
    make: 'Pontiac',
    models: 'Firebird,Trans Sport,Bonneville,Grand Prix,Sunfire,Firefly,Grand Am',
  },
  {
    year: 1998,
    make: 'Porsche',
    models: 'Boxster,911',
  },
  {
    year: 1998,
    make: 'Rolls-Royce',
    models: 'Silver Spur',
  },
  {
    year: 1998,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1998,
    make: 'Saturn',
    models: 'SC2,SL1,SW2,SL,SL2,SC1,SW1',
  },
  {
    year: 1998,
    make: 'Subaru',
    models: 'Forester,Impreza,Legacy',
  },
  {
    year: 1998,
    make: 'Suzuki',
    models: 'Esteem,Sidekick,X-90,Swift',
  },
  {
    year: 1998,
    make: 'Toyota',
    models: 'Tacoma,Celica,Corolla,RAV4,4Runner,Paseo,T100,Avalon,Camry,Supra,Sienna,Land Cruiser,Tercel',
  },
  {
    year: 1998,
    make: 'Volkswagen',
    models: 'Derby,Beetle,Jetta,Sedan,Golf,Pointer,Passat,Cabrio',
  },
  {
    year: 1998,
    make: 'Volvo',
    models: 'C70,S90,V90,S70,V70',
  },
  {
    year: 1997,
    make: 'Acura',
    models: 'TL,NSX,RL,CL,Integra,EL,SLX',
  },
  {
    year: 1997,
    make: 'Aston Martin',
    models: 'DB7',
  },
  {
    year: 1997,
    make: 'Audi',
    models: 'S6,Cabriolet,A6,A8 Quattro,A8,A4,A6 Quattro,A4 Quattro',
  },
  {
    year: 1997,
    make: 'Bentley',
    models: 'Azure,Brooklands,Continental,Turbo R',
  },
  {
    year: 1997,
    make: 'BMW',
    models: '740iL,840Ci,318ti,740i,325i,328i,540i,750iL,M3,318i,328is,850Ci,323i,528i,Z3,318is',
  },
  {
    year: 1997,
    make: 'Buick',
    models: 'Century,LeSabre,Skylark,Riviera,Park Avenue,Regal',
  },
  {
    year: 1997,
    make: 'Cadillac',
    models: 'Seville,Eldorado,DeVille,Catera',
  },
  {
    year: 1997,
    make: 'Chevrolet',
    models:
      'K2500,K2500 Suburban,P30,Astro,Malibu,C2500,C2500 Suburban,C35,K1500 Suburban,C1500,S10,Chevy,Cargo Van,Venture,C1500 Suburban,Monte Carlo,Silverado,K1500,Camaro,Corvette,Express 2500,Express 3500,LUV,Lumina,Suburban 1500,Tahoe,Blazer,Cavalier,Express 1500',
  },
  {
    year: 1997,
    make: 'Chrysler',
    models: 'Cirrus,Chrysler R/T,Stratus,Grand Voyager,LHS,Intrepid,Sebring,Neon,Town & Country,Concorde,Voyager',
  },
  {
    year: 1997,
    make: 'Dodge',
    models:
      'Ram 1500 Van,Viper,Ram 2500,Neon,Ram 2500 Van,Ram 3500 Van,Dakota,B2500,Intrepid,Avenger,Stratus,Grand Caravan,Ram 1500,Caravan',
  },
  {
    year: 1997,
    make: 'Eagle',
    models: 'Talon,Vision',
  },
  {
    year: 1997,
    make: 'Ferrari',
    models: '550 Maranello,456 GTA,F355 Spider,456 GT,F355 GTS,F50',
  },
  {
    year: 1997,
    make: 'Ford',
    models:
      'Thunderbird,Lobo,Crown Victoria,E-250 Econoline,Mystique,Sable,Mustang,Ranger,Aspire,Grand Marquis,Taurus,Windstar,E-150 Econoline Club Wagon,E-150 Econoline,Escort,Expedition,Explorer,F-250,Aerostar,F-250 HD,F-150,F-100 Ranger,Probe,Contour,Club Wagon',
  },
  {
    year: 1997,
    make: 'Geo',
    models: 'Tracker,Metro,Prizm',
  },
  {
    year: 1997,
    make: 'GMC',
    models:
      'K2500 Suburban,C2500 Suburban,Savana 2500,K1500 Suburban,K2500,Savana 1500,C1500 Suburban,Yukon,C2500,Jimmy,Sonoma,Safari,K1500,C1500',
  },
  {
    year: 1997,
    make: 'Honda',
    models: 'Prelude,Civic,Odyssey,Passport,Accord,CR-V,Civic del Sol',
  },
  {
    year: 1997,
    make: 'Hyundai',
    models: 'Sonata,Accent,Tiburon,Elantra',
  },
  {
    year: 1997,
    make: 'Infiniti',
    models: 'I30,QX4,J30,Q45',
  },
  {
    year: 1997,
    make: 'Isuzu',
    models: 'Rodeo,Trooper,Hombre,Oasis',
  },
  {
    year: 1997,
    make: 'Jaguar',
    models: 'XJ6,XK8,XJR,Vanden Plas',
  },
  {
    year: 1997,
    make: 'Jeep',
    models: 'Wrangler,TJ,Cherokee,Grand Cherokee',
  },
  {
    year: 1997,
    make: 'Kia',
    models: 'Sportage,Sephia',
  },
  {
    year: 1997,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1997,
    make: 'Land Rover',
    models: 'Discovery,Range Rover',
  },
  {
    year: 1997,
    make: 'Lexus',
    models: 'LS400,SC300,GS300,ES300,SC400,LX450',
  },
  {
    year: 1997,
    make: 'Lincoln',
    models: 'Mark VIII,Town Car,Continental',
  },
  {
    year: 1997,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1997,
    make: 'Mazda',
    models: '626,Protege,B2300,B4000,MPV,Miata,B3000,MX-6,Millenia',
  },
  {
    year: 1997,
    make: 'Mercedes-Benz',
    models:
      'S600,C280,CLK320,E420,C220,C36 AMG,E300,E500,SL320,SL60 AMG,S320,SL600,CL500,S500,CL600,SL500,SLK230,C230,E320,S420',
  },
  {
    year: 1997,
    make: 'Mercury',
    models: 'Sable,Mountaineer,Villager,Mystique,Tracer,Cougar,Grand Marquis',
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    models: 'Galant,3000GT,Diamante,Eclipse,Mirage,Montero Sport,Montero',
  },
  {
    year: 1997,
    make: 'Nissan',
    models: 'Maxima,240SX,Tsuru,Sentra,Lucino,Pathfinder,Quest,Pickup,200SX,Altima,Tsubame',
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    models: 'Achieva,Cutlass,Regency,88,LSS,Bravada,Aurora,Cutlass Supreme,Silhouette',
  },
  {
    year: 1997,
    make: 'Peugeot',
    models: '306,405',
  },
  {
    year: 1997,
    make: 'Plymouth',
    models: 'Voyager,Grand Voyager,Neon,Breeze,Prowler',
  },
  {
    year: 1997,
    make: 'Pontiac',
    models: 'Firefly,Grand Am,Sunrunner,Trans Sport,Firebird,Sunfire,Bonneville,Grand Prix',
  },
  {
    year: 1997,
    make: 'Porsche',
    models: '911,Boxster',
  },
  {
    year: 1997,
    make: 'Rolls-Royce',
    models: 'Silver Spur,Silver Dawn',
  },
  {
    year: 1997,
    make: 'Saab',
    models: '900,9000',
  },
  {
    year: 1997,
    make: 'Saturn',
    models: 'SC1,SW1,SC2,SL1,SW2,SL,SL2',
  },
  {
    year: 1997,
    make: 'Subaru',
    models: 'Legacy,SVX,Impreza',
  },
  {
    year: 1997,
    make: 'Suzuki',
    models: 'Swift,X-90,Esteem,Sidekick',
  },
  {
    year: 1997,
    make: 'Toyota',
    models: 'Supra,Land Cruiser,Tercel,Tacoma,Previa,Celica,Corolla,RAV4,4Runner,Paseo,T100,Avalon,Camry',
  },
  {
    year: 1997,
    make: 'Volkswagen',
    models: 'Golf,Passat,EuroVan,Cabrio,Derby,Jetta,Sedan',
  },
  {
    year: 1997,
    make: 'Volvo',
    models: '850,S90,V90,960',
  },
  {
    year: 1996,
    make: 'Acura',
    models: 'SLX,TL,NSX,RL,Integra',
  },
  {
    year: 1996,
    make: 'Audi',
    models: 'A4,A6 Quattro,A4 Quattro,S6,Cabriolet,A6',
  },
  {
    year: 1996,
    make: 'Bentley',
    models: 'Azure,Brooklands,Continental,Turbo R',
  },
  {
    year: 1996,
    make: 'BMW',
    models: 'M3,318i,328is,850Ci,528i,Z3,318is,740iL,840Ci,318ti,325i,328i,750iL',
  },
  {
    year: 1996,
    make: 'Buick',
    models: 'Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis,Riviera',
  },
  {
    year: 1996,
    make: 'Cadillac',
    models: 'Commercial Chassis,DeVille,Seville,Eldorado,Fleetwood',
  },
  {
    year: 1996,
    make: 'Chevrolet',
    models:
      'Lumina,Caprice,Suburban 1500,Tahoe,Blazer,Cavalier,Express 1500,Impala,K2500,K2500 Suburban,P30,Corsica,Astro,Lumina APV,C2500,C2500 Suburban,Lumina Van,C35,K1500 Suburban,C1500,S10,Chevy,G30,Beretta,C1500 Suburban,Monte Carlo,Silverado,K1500,Camaro,Corvette,Express 2500,Express 3500',
  },
  {
    year: 1996,
    make: 'Chrysler',
    models: 'Town & Country,Neon,Breeze,Concorde,Voyager,Cirrus,New Yorker,Stratus,Grand Voyager,LHS,Intrepid,Sebring',
  },
  {
    year: 1996,
    make: 'Dodge',
    models:
      'Avenger,Stratus,Grand Caravan,Ram 1500,Caravan,Ram 1500 Van,Viper,Ram 2500,Neon,Ram 2500 Van,Ram 3500 Van,Dakota,B2500,Intrepid,Ramcharger,Stealth',
  },
  {
    year: 1996,
    make: 'Eagle',
    models: 'Vision,Summit,Talon',
  },
  {
    year: 1996,
    make: 'Ferrari',
    models: '456 GT,F355 Spider,F355 GTS,F50,F355 Berlinetta',
  },
  {
    year: 1996,
    make: 'Ford',
    models:
      'F-250,Aerostar,F-100 Ranger,Probe,Contour,Club Wagon,Thunderbird,Crown Victoria,E-250 Econoline,Mystique,Sable,Mustang,Ranger,Aspire,Bronco,Grand Marquis,Taurus,Windstar,F-150,E-150 Econoline Club Wagon,E-150 Econoline,Escort,Explorer',
  },
  {
    year: 1996,
    make: 'Geo',
    models: 'Metro,Prizm,Tracker',
  },
  {
    year: 1996,
    make: 'GMC',
    models:
      'C2500,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban,Savana 2500,K1500 Suburban,K2500,Savana 1500,C1500,C1500 Suburban,Yukon,Jimmy',
  },
  {
    year: 1996,
    make: 'Honda',
    models: 'Prelude,Civic del Sol,Civic,Odyssey,Passport,Accord',
  },
  {
    year: 1996,
    make: 'Hyundai',
    models: 'Elantra,Sonata,Accent',
  },
  {
    year: 1996,
    make: 'Infiniti',
    models: 'J30,Q45,I30,G20',
  },
  {
    year: 1996,
    make: 'Isuzu',
    models: 'Hombre,Oasis,Trooper,Rodeo',
  },
  {
    year: 1996,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ12,XJ6,XJS,XJR',
  },
  {
    year: 1996,
    make: 'Jeep',
    models: 'Grand Cherokee,Cherokee',
  },
  {
    year: 1996,
    make: 'Kia',
    models: 'Sephia,Sportage',
  },
  {
    year: 1996,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1996,
    make: 'Land Rover',
    models: 'Discovery,Range Rover',
  },
  {
    year: 1996,
    make: 'Lexus',
    models: 'ES300,SC400,LX450,LS400,SC300,GS300',
  },
  {
    year: 1996,
    make: 'Lincoln',
    models: 'Continental,Mark VIII,Town Car',
  },
  {
    year: 1996,
    make: 'Mazda',
    models: 'MX-6,Millenia,B3000,626,Protege,B2300,B4000,MPV,Miata,MX-3',
  },
  {
    year: 1996,
    make: 'Mercedes-Benz',
    models: 'S500,SL500,E320,S420,S600,C280,CL500,CL600,C220,C36 AMG,E300,SL320,C230,S320,SL600',
  },
  {
    year: 1996,
    make: 'Mercury',
    models: 'Mystique,Tracer,Cougar,Grand Marquis,Sable,Villager',
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    models: 'Montero,Galant,3000GT,Diamante,Eclipse,Mirage',
  },
  {
    year: 1996,
    make: 'Nissan',
    models: 'Pickup,200SX,Altima,Tsubame,300ZX,Maxima,240SX,Tsuru,Sentra,Lucino,Pathfinder,Quest',
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    models: '98,Aurora,Cutlass Supreme,Silhouette,Achieva,Cutlass Ciera,Cutlass,88,LSS,Bravada',
  },
  {
    year: 1996,
    make: 'Plymouth',
    models: 'Grand Voyager,Voyager,Neon,Breeze',
  },
  {
    year: 1996,
    make: 'Pontiac',
    models: 'Sunfire,Bonneville,Grand Prix,Firefly,Grand Am,Sunrunner,Trans Sport,Firebird',
  },
  {
    year: 1996,
    make: 'Porsche',
    models: '911',
  },
  {
    year: 1996,
    make: 'Rolls-Royce',
    models: 'Silver Dawn,Silver Spur',
  },
  {
    year: 1996,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1996,
    make: 'Saturn',
    models: 'SL,SL2,SC1,SW1,SC2,SL1,SW2',
  },
  {
    year: 1996,
    make: 'Subaru',
    models: 'SVX,Impreza,Legacy',
  },
  {
    year: 1996,
    make: 'Suzuki',
    models: 'Swift,X-90,Esteem,Sidekick',
  },
  {
    year: 1996,
    make: 'Toyota',
    models: 'RAV4,4Runner,Paseo,T100,Avalon,Camry,Supra,Land Cruiser,Tercel,Tacoma,Previa,Celica,Corolla',
  },
  {
    year: 1996,
    make: 'Volkswagen',
    models: 'Jetta,Sedan,EuroVan,Golf,Caravelle,Passat,Cabrio,Derby',
  },
  {
    year: 1996,
    make: 'Volvo',
    models: '960,850',
  },
  {
    year: 1995,
    make: 'Acura',
    models: 'Integra,Legend,TL,NSX',
  },
  {
    year: 1995,
    make: 'Alfa Romeo',
    models: '164',
  },
  {
    year: 1995,
    make: 'Audi',
    models: 'A6,90 Quattro,90,S6,A6 Quattro,Cabriolet',
  },
  {
    year: 1995,
    make: 'Bentley',
    models: 'Turbo R,Brooklands,Continental',
  },
  {
    year: 1995,
    make: 'BMW',
    models: '525i,530i,750iL,850CSi,M3,318i,325i,850Ci,320i,325is,740i,318is,740iL,840Ci,318ti,540i',
  },
  {
    year: 1995,
    make: 'Buick',
    models: 'Commercial Chassis,Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark',
  },
  {
    year: 1995,
    make: 'Cadillac',
    models: 'Seville,Eldorado,Fleetwood,Commercial Chassis,DeVille',
  },
  {
    year: 1995,
    make: 'Chevrolet',
    models:
      'C35,K1500 Suburban,LLV,C1500,S10,Chevy,G30,Beretta,C1500 Suburban,Monte Carlo,G10,G20,Silverado,K1500,Camaro,Corvette,Lumina,Caprice,Suburban 1500,Tahoe,Blazer,Cavalier,Impala,K2500,K2500 Suburban,P30,Corsica,Astro,Lumina APV,C2500,C2500 Suburban,Lumina Van',
  },
  {
    year: 1995,
    make: 'Chrysler',
    models:
      'Stratus,Grand Voyager,LHS,LeBaron,Sebring,Intrepid,Concorde,Spirit,Town & Country,Neon,Voyager,Cirrus,New Yorker',
  },
  {
    year: 1995,
    make: 'Dodge',
    models:
      'Neon,Dakota,B2500,Intrepid,Ramcharger,Stealth,Avenger,Stratus,Grand Caravan,Ram 1500,Caravan,Ram 1500 Van,Spirit,Viper,Colt,Ram 2500',
  },
  {
    year: 1995,
    make: 'Eagle',
    models: 'Vision,Talon,Summit',
  },
  {
    year: 1995,
    make: 'Ferrari',
    models: '456 GT,F355 Berlinetta,348 Spider,512 M,F355 GTS,F50',
  },
  {
    year: 1995,
    make: 'Ford',
    models:
      'Grand Marquis,Taurus,Windstar,F-150,E-150 Econoline Club Wagon,E-150 Econoline,Escort,Explorer,F-250,Aerostar,Cougar,Probe,Contour,Club Wagon,Thunderbird,Crown Victoria,E-250 Econoline,Mystique,Sable,Mustang,Ranger,Aspire,Bronco',
  },
  {
    year: 1995,
    make: 'Geo',
    models: 'Metro,Prizm,Tracker',
  },
  {
    year: 1995,
    make: 'GMC',
    models:
      'K1500 Suburban,K2500,C1500,C1500 Suburban,Yukon,Jimmy,C2500,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban',
  },
  {
    year: 1995,
    make: 'Honda',
    models: 'Odyssey,Passport,Accord,Prelude,Civic del Sol,Civic',
  },
  {
    year: 1995,
    make: 'Hyundai',
    models: 'Sonata,Scoupe,Accent,Elantra',
  },
  {
    year: 1995,
    make: 'Infiniti',
    models: 'G20,J30,Q45',
  },
  {
    year: 1995,
    make: 'Isuzu',
    models: 'Trooper,Pickup,Rodeo',
  },
  {
    year: 1995,
    make: 'Jaguar',
    models: 'XJR,Vanden Plas,XJ12,XJ6,XJS',
  },
  {
    year: 1995,
    make: 'Jeep',
    models: 'Cherokee,Grand Cherokee,Wrangler',
  },
  {
    year: 1995,
    make: 'Kia',
    models: 'Sportage,Sephia',
  },
  {
    year: 1995,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1995,
    make: 'Land Rover',
    models: 'Discovery,Range Rover',
  },
  {
    year: 1995,
    make: 'Lexus',
    models: 'GS300,ES300,SC400,LS400,SC300',
  },
  {
    year: 1995,
    make: 'Lincoln',
    models: 'Town Car,Continental,Mark VIII',
  },
  {
    year: 1995,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1995,
    make: 'Mazda',
    models: 'RX-7,MX-6,MX-3,Protege,Millenia,B3000,323,626,B2300,B4000,929,MPV,Miata',
  },
  {
    year: 1995,
    make: 'Mercedes-Benz',
    models: 'C220,C36 AMG,E300,SL320,S320,SL600,S500,S600L,E420,SL500,E320,S420,S600,C280,S350',
  },
  {
    year: 1995,
    make: 'Mercury',
    models: 'Villager,Mystique,Sable,Tracer,Cougar,Grand Marquis',
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    models: 'Eclipse,Mirage,Montero,Galant,3000GT,Diamante,Expo',
  },
  {
    year: 1995,
    make: 'Nissan',
    models: '240SX,Tsuru,Sentra,Pathfinder,Quest,Pickup,200SX,Axxess,Altima,Tsubame,300ZX,Maxima',
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    models: '88,98,Aurora,Cutlass Supreme,Silhouette,Achieva,Cutlass Ciera,Cutlass',
  },
  {
    year: 1995,
    make: 'Plymouth',
    models: 'Neon,Acclaim,Colt,Grand Voyager,Voyager',
  },
  {
    year: 1995,
    make: 'Pontiac',
    models: 'Sunrunner,Trans Sport,Firebird,Grand Prix,Sunfire,Bonneville,Firefly,Grand Am',
  },
  {
    year: 1995,
    make: 'Porsche',
    models: '911,928,968',
  },
  {
    year: 1995,
    make: 'Rolls-Royce',
    models: 'Flying Spur,Corniche S,Silver Spirit,Corniche IV,Silver Dawn,Silver Spur',
  },
  {
    year: 1995,
    make: 'Saab',
    models: '900,9000',
  },
  {
    year: 1995,
    make: 'Saturn',
    models: 'SC2,SL1,SW2,SL,SL2,SC1,SW1',
  },
  {
    year: 1995,
    make: 'Subaru',
    models: 'Impreza,Justy,Legacy,SVX',
  },
  {
    year: 1995,
    make: 'Suzuki',
    models: 'Esteem,Sidekick,Swift',
  },
  {
    year: 1995,
    make: 'Toyota',
    models: 'Tercel,Tacoma,Previa,Celica,Corolla,Pickup,4Runner,Paseo,T100,Avalon,Camry,MR2,Supra,Land Cruiser',
  },
  {
    year: 1995,
    make: 'Volkswagen',
    models: 'Corrado,Passat,Cabrio,Derby,Jetta,Sedan,EuroVan,Golf,Caravelle',
  },
  {
    year: 1995,
    make: 'Volvo',
    models: '850,960,940',
  },
  {
    year: 1994,
    make: 'Acura',
    models: 'Legend,NSX,Integra,Vigor',
  },
  {
    year: 1994,
    make: 'Alfa Romeo',
    models: 'Spider,164',
  },
  {
    year: 1994,
    make: 'Aston Martin',
    models: 'Virage',
  },
  {
    year: 1994,
    make: 'Audi',
    models: 'Cabriolet,100,S4,100 Quattro,V8 Quattro,90 Quattro,90',
  },
  {
    year: 1994,
    make: 'Bentley',
    models: 'Brooklands,Continental,Turbo R',
  },
  {
    year: 1994,
    make: 'BMW',
    models: '740i,318is,740iL,540i,M3,525i,530i,750iL,840Ci,850CSi,318i,325i,850Ci,320i,325is',
  },
  {
    year: 1994,
    make: 'Buick',
    models: 'Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis,Park Avenue',
  },
  {
    year: 1994,
    make: 'Cadillac',
    models: 'DeVille,Seville,Eldorado,Fleetwood,Commercial Chassis',
  },
  {
    year: 1994,
    make: 'Chevrolet',
    models:
      'Impala,K2500,K2500 Suburban,P30,Cavalier,Corsica,C35,Astro,Lumina APV,C2500,C2500 Suburban,S10 Blazer,Chevy,K1500 Suburban,LLV,Blazer,C1500,S10,G30,Beretta,C1500 Suburban,G10,G20,Commercial Chassis,K1500,Suburban,Camaro,Corvette,Lumina,Caprice',
  },
  {
    year: 1994,
    make: 'Chrysler',
    models: 'Phantom,New Yorker,Grand Voyager,LHS,LeBaron,Intrepid,Concorde,Spirit,Shadow,Town & Country,Voyager',
  },
  {
    year: 1994,
    make: 'Dodge',
    models:
      'Ram 1500,Caravan,Colt,Spirit,Viper,B250,Shadow,Ram 2500,B350,Ramcharger,Dakota,Intrepid,Stealth,Grand Caravan',
  },
  {
    year: 1994,
    make: 'Eagle',
    models: 'Summit,Vision,Talon',
  },
  {
    year: 1994,
    make: 'Ferrari',
    models: '348 Spider,512 TR,F355 GTS,348 GTB,F355 Berlinetta,348 GTS',
  },
  {
    year: 1994,
    make: 'Ford',
    models:
      'Probe,F-150,Thunderbird,Ghia,Crown Victoria,E-250 Econoline,F-250,Sable,Mustang,Ranger,Aspire,Bronco,Grand Marquis,Taurus,Topaz,E-150 Econoline Club Wagon,Cougar,E-150 Econoline,Escort,Explorer,Tempo,Aerostar',
  },
  {
    year: 1994,
    make: 'Geo',
    models: 'Prizm,Tracker,Metro',
  },
  {
    year: 1994,
    make: 'GMC',
    models:
      'K1500,K2500 Suburban,Safari,C2500 Suburban,K1500 Suburban,K2500,C1500,C1500 Suburban,Yukon,Jimmy,C2500,Sonoma',
  },
  {
    year: 1994,
    make: 'Honda',
    models: 'Prelude,Civic del Sol,Civic,Passport,Accord',
  },
  {
    year: 1994,
    make: 'Hyundai',
    models: 'Excel,Sonata,Scoupe,Elantra',
  },
  {
    year: 1994,
    make: 'Infiniti',
    models: 'G20,J30,Q45',
  },
  {
    year: 1994,
    make: 'Isuzu',
    models: 'Amigo,Pickup,Rodeo,Trooper',
  },
  {
    year: 1994,
    make: 'Jaguar',
    models: 'XJ6,XJS,Vanden Plas,XJ12',
  },
  {
    year: 1994,
    make: 'Jeep',
    models: 'Cherokee,Wrangler,Grand Cherokee',
  },
  {
    year: 1994,
    make: 'Kia',
    models: 'Sephia',
  },
  {
    year: 1994,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1994,
    make: 'Land Rover',
    models: 'Discovery,Range Rover',
  },
  {
    year: 1994,
    make: 'Lexus',
    models: 'LS400,SC300,GS300,ES300,SC400',
  },
  {
    year: 1994,
    make: 'Lincoln',
    models: 'Continental,Mark VIII,Town Car',
  },
  {
    year: 1994,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1994,
    make: 'Mazda',
    models: 'B3000,323,626,B2300,B4000,929,Miata,MX-6,RX-7,Protege,MX-3,MPV',
  },
  {
    year: 1994,
    make: 'Mercedes-Benz',
    models: 'SL500,E320,S420,S600,C280,E500,S350,C220,SL320,S320,SL600,S500,E420',
  },
  {
    year: 1994,
    make: 'Mercury',
    models: 'Cougar,Grand Marquis,Topaz,Villager,Capri,Sable,Tracer',
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    models: 'Montero,Galant,3000GT,Diamante,Expo,Eclipse,Mirage,Precis,Expo LRV',
  },
  {
    year: 1994,
    make: 'Nissan',
    models: '300ZX,Pickup,Maxima,240SX,Tsubame,Sentra,Tsuru,Pathfinder,Quest,Axxess,Altima',
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    models: 'Silhouette,98,Achieva,Bravada,Cutlass Ciera,88,Cutlass,Cutlass Cruiser,Cutlass Supreme',
  },
  {
    year: 1994,
    make: 'Plymouth',
    models: 'Colt,Grand Voyager,Laser,Voyager,Sundance,Acclaim',
  },
  {
    year: 1994,
    make: 'Pontiac',
    models: 'Sunbird,Firefly,Grand Am,Sunrunner,Trans Sport,Firebird,Grand Prix,Bonneville',
  },
  {
    year: 1994,
    make: 'Porsche',
    models: '968,911,928',
  },
  {
    year: 1994,
    make: 'Rolls-Royce',
    models: 'Silver Spur,Touring Limousine,Silver Spirit,Corniche IV',
  },
  {
    year: 1994,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1994,
    make: 'Saturn',
    models: 'SL,SL2,SC1,SW1,SC2,SL1,SW2',
  },
  {
    year: 1994,
    make: 'Subaru',
    models: 'Legacy,Impreza,Justy,Loyale,SVX',
  },
  {
    year: 1994,
    make: 'Suzuki',
    models: 'Swift,Sidekick',
  },
  {
    year: 1994,
    make: 'Toyota',
    models: 'T100,Camry,MR2,Supra,Land Cruiser,Tercel,Previa,Celica,Corolla,Pickup,4Runner,Paseo',
  },
  {
    year: 1994,
    make: 'Volkswagen',
    models: 'Transporter,Golf,Corrado,Passat,EuroVan,Sedan,Jetta',
  },
  {
    year: 1994,
    make: 'Volvo',
    models: '940,850,960',
  },
  {
    year: 1993,
    make: 'Acura',
    models: 'Legend,NSX,Integra,Vigor',
  },
  {
    year: 1993,
    make: 'Alfa Romeo',
    models: '164,Spider',
  },
  {
    year: 1993,
    make: 'Aston Martin',
    models: 'Virage',
  },
  {
    year: 1993,
    make: 'Asuna',
    models: 'GT,Sunfire,Sunrunner,SE',
  },
  {
    year: 1993,
    make: 'Audi',
    models: '90 Quattro,90,100,S4,100 Quattro,V8 Quattro',
  },
  {
    year: 1993,
    make: 'Bentley',
    models: 'Brooklands,Continental,Turbo R',
  },
  {
    year: 1993,
    make: 'BMW',
    models: '535i,750iL,M5,318i,325i,850Ci,325is,740i,318is,740iL,525i,525iT,320i',
  },
  {
    year: 1993,
    make: 'Buick',
    models: 'Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis',
  },
  {
    year: 1993,
    make: 'Cadillac',
    models: 'DeVille,Commercial Chassis,Fleetwood,Seville,Allante,Eldorado,60 Special',
  },
  {
    year: 1993,
    make: 'Chevrolet',
    models:
      'K1500,Suburban,Camaro,Lumina,Caprice,K2500 Suburban,P30,Cavalier,Corvette,C35,Astro,Blazer,Lumina APV,C2500 Suburban,S10 Blazer,K1500 Suburban,K2500,LLV,C1500,S10,Beretta,C1500 Suburban,C2500,Corsica,G10,G20,G30,Commercial Chassis',
  },
  {
    year: 1993,
    make: 'Chrysler',
    models:
      'Shadow,Town & Country,Dynasty,Phantom,New Yorker,Grand Voyager,Imperial,LeBaron,Intrepid,Concorde,Spirit,Daytona',
  },
  {
    year: 1993,
    make: 'Dodge',
    models:
      'Stealth,Ram 50,D250,Spirit,Caravan,Colt,Daytona,Viper,Grand Caravan,B250,Ramcharger,Shadow,B350,Dynasty,D150,Dakota,Intrepid',
  },
  {
    year: 1993,
    make: 'Eagle',
    models: 'Vision,Talon,2000 GTX,Summit',
  },
  {
    year: 1993,
    make: 'Excalibur',
    models: 'Limited',
  },
  {
    year: 1993,
    make: 'Ferrari',
    models: '348 Spider,Mondial t,512 TR,348 GTB,348 GTS',
  },
  {
    year: 1993,
    make: 'Ford',
    models:
      'Cougar,E-150 Econoline,Escort,Explorer,Tempo,Aerostar,Festiva,Probe,F-150,Mustang,Thunderbird,Ghia,Crown Victoria,E-250 Econoline,F-250,Ranger,Bronco,Grand Marquis,Taurus,Topaz,E-150 Econoline Club Wagon',
  },
  {
    year: 1993,
    make: 'Geo',
    models: 'Metro,Prizm,Tracker,Storm',
  },
  {
    year: 1993,
    make: 'GMC',
    models:
      'Jimmy,Typhoon,C1500,C2500,Sonoma,K1500,K2500 Suburban,Safari,C2500 Suburban,K1500 Suburban,K2500,C1500 Suburban,Yukon',
  },
  {
    year: 1993,
    make: 'Honda',
    models: 'Accord,Prelude,Civic del Sol,Civic',
  },
  {
    year: 1993,
    make: 'Hyundai',
    models: 'Elantra,Excel,Sonata,Scoupe',
  },
  {
    year: 1993,
    make: 'Infiniti',
    models: 'J30,Q45,G20',
  },
  {
    year: 1993,
    make: 'Isuzu',
    models: 'Stylus,Amigo,Pickup,Rodeo,Trooper',
  },
  {
    year: 1993,
    make: 'Jaguar',
    models: 'XJRS,Vanden Plas,XJ6,XJS',
  },
  {
    year: 1993,
    make: 'Jeep',
    models: 'Wrangler,Grand Wagoneer,Cherokee,Grand Cherokee',
  },
  {
    year: 1993,
    make: 'Lada',
    models: 'Niva,Signet,Samara',
  },
  {
    year: 1993,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1993,
    make: 'Land Rover',
    models: 'Range Rover',
  },
  {
    year: 1993,
    make: 'Lexus',
    models: 'GS300,ES300,SC400,LS400,SC300',
  },
  {
    year: 1993,
    make: 'Lincoln',
    models: 'Continental,Mark VIII,Town Car',
  },
  {
    year: 1993,
    make: 'Lotus',
    models: 'Esprit',
  },
  {
    year: 1993,
    make: 'Mazda',
    models: 'MPV,MX-3,323,626,929,B2600,Miata,Protege,MX-6,RX-7',
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    models: '300D,300SL,500SEC,600SEL,300SE,500SEL,190E,500E,300CE,300E,300TE,400E,300SD,500SL,600SL,400SEL,600SEC',
  },
  {
    year: 1993,
    make: 'Mercury',
    models: 'Capri,Sable,Cougar,Tracer,Grand Marquis,Topaz,Villager',
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    models: 'Precis,Expo LRV,Montero,Galant,3000GT,Diamante,Expo,Eclipse,Mirage',
  },
  {
    year: 1993,
    make: 'Nissan',
    models: 'Tsuru,Pathfinder,Quest,Axxess,Altima,300ZX,Maxima,Pickup,NX,240SX,Tsubame,Sentra',
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    models: 'Cutlass,Cutlass Supreme,Silhouette,98,Achieva,Cutlass Cruiser,Bravada,Cutlass Ciera,88',
  },
  {
    year: 1993,
    make: 'Plymouth',
    models: 'Acclaim,Colt,Grand Voyager,Laser,Voyager,Sundance',
  },
  {
    year: 1993,
    make: 'Pontiac',
    models: 'LeMans,Trans Sport,Firebird,Bonneville,Grand Prix,Sunbird,Grand Am',
  },
  {
    year: 1993,
    make: 'Porsche',
    models: '968,911,928',
  },
  {
    year: 1993,
    make: 'Rolls-Royce',
    models: 'Corniche IV,Silver Spur,Silver Spirit',
  },
  {
    year: 1993,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1993,
    make: 'Saturn',
    models: 'SW2,SL,SL2,SC1,SW1,SC2,SL1',
  },
  {
    year: 1993,
    make: 'Subaru',
    models: 'SVX,Justy,Loyale,Legacy,Impreza',
  },
  {
    year: 1993,
    make: 'Suzuki',
    models: 'Swift,Sidekick',
  },
  {
    year: 1993,
    make: 'Toyota',
    models: 'Celica,Corolla,Pickup,4Runner,Paseo,T100,Camry,MR2,Supra,Land Cruiser,Tercel,Previa',
  },
  {
    year: 1993,
    make: 'Volkswagen',
    models: 'EuroVan,Jetta,Sedan,Golf,Fox,Transporter,Cabriolet,Corrado,Passat',
  },
  {
    year: 1993,
    make: 'Volvo',
    models: '240,960,940,850',
  },
  {
    year: 1992,
    make: 'Acura',
    models: 'NSX,Integra,Vigor,Legend',
  },
  {
    year: 1992,
    make: 'Alfa Romeo',
    models: 'Spider,164',
  },
  {
    year: 1992,
    make: 'Aston Martin',
    models: 'Virage',
  },
  {
    year: 1992,
    make: 'Asuna',
    models: 'Sunrunner',
  },
  {
    year: 1992,
    make: 'Audi',
    models: '100,S4,90 Quattro,100 Quattro,V8 Quattro,80 Quattro,80',
  },
  {
    year: 1992,
    make: 'Bentley',
    models: 'Mulsanne,Turbo R,Continental',
  },
  {
    year: 1992,
    make: 'BMW',
    models: '525i,535i,750iL,M5,850i,318i,325i,320i,325is,735iL,318is,735i',
  },
  {
    year: 1992,
    make: 'Buick',
    models: 'Commercial Chassis,Skylark,Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre',
  },
  {
    year: 1992,
    make: 'Cadillac',
    models: 'Brougham,Fleetwood,Allante,Eldorado,DeVille,Commercial Chassis,Seville',
  },
  {
    year: 1992,
    make: 'Chevrolet',
    models:
      'C35,Astro,Blazer,Lumina APV,C2500 Suburban,S10 Blazer,Corvette,K1500 Suburban,K2500,LLV,C1500,S10,Beretta,C1500 Suburban,C2500,Corsica,G10,G20,G30,Sprint,Commercial Chassis,K1500,Suburban,Lumina,Camaro,Caprice,K2500 Suburban,Metro,P30,Cavalier',
  },
  {
    year: 1992,
    make: 'Chrysler',
    models: 'Grand Voyager,Imperial,LeBaron,Spirit,Daytona,Shadow,Town & Country,Dynasty,Phantom,New Yorker',
  },
  {
    year: 1992,
    make: 'Daihatsu',
    models: 'Rocky,Charade',
  },
  {
    year: 1992,
    make: 'Dodge',
    models:
      'B250,Ramcharger,B350,Dynasty,Grand Caravan,DM350,D150,Dakota,Stealth,Ram 50,Shadow,Spirit,Monaco,D250,Caravan,Colt,Daytona,Viper',
  },
  {
    year: 1992,
    make: 'Eagle',
    models: 'Premier,2000 GTX,Summit,Talon,Vista',
  },
  {
    year: 1992,
    make: 'Ferrari',
    models: 'F40,348 TB,348 TS,Mondial t,512 TR',
  },
  {
    year: 1992,
    make: 'Ford',
    models:
      'Bronco,Grand Marquis,Ranger,Taurus,Topaz,E-150 Econoline Club Wagon,Cougar,Mustang,E-150 Econoline,Explorer,Tempo,F-150,Aerostar,Escort,Thunderbird,F-250,Festiva,Probe,Ghia,Crown Victoria,E-250 Econoline',
  },
  {
    year: 1992,
    make: 'Geo',
    models: 'Storm,Prizm,Metro,Tracker',
  },
  {
    year: 1992,
    make: 'GMC',
    models:
      'C2500 Suburban,K1500 Suburban,K2500,C1500 Suburban,Yukon,Jimmy,Typhoon,C1500,C2500,Sonoma,K1500,K2500 Suburban,Safari',
  },
  {
    year: 1992,
    make: 'Honda',
    models: 'Civic,Accord,Prelude',
  },
  {
    year: 1992,
    make: 'Hyundai',
    models: 'Excel,Sonata,Scoupe,Elantra',
  },
  {
    year: 1992,
    make: 'Infiniti',
    models: 'G20,M30,Q45',
  },
  {
    year: 1992,
    make: 'Isuzu',
    models: 'Trooper,Impulse,Amigo,Stylus,Pickup,Rodeo',
  },
  {
    year: 1992,
    make: 'Jaguar',
    models: 'XJ12,Vanden Plas,XJ6,XJS',
  },
  {
    year: 1992,
    make: 'Jeep',
    models: 'Cherokee,Comanche,Wrangler',
  },
  {
    year: 1992,
    make: 'Lada',
    models: 'Samara,Niva,Signet',
  },
  {
    year: 1992,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1992,
    make: 'Land Rover',
    models: 'Range Rover',
  },
  {
    year: 1992,
    make: 'Lexus',
    models: 'LS400,SC300,ES300,SC400',
  },
  {
    year: 1992,
    make: 'Lincoln',
    models: 'Mark VII,Town Car,Continental',
  },
  {
    year: 1992,
    make: 'Lotus',
    models: 'Esprit,Elan',
  },
  {
    year: 1992,
    make: 'Mazda',
    models: '929,Protege,B2600,MX-3,Miata,MPV,MX-6,323,626',
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    models: '400E,300SD,500SL,400SE,300D,300SL,600SEL,300SE,500SEL,190E,500E,300CE,300E,300TE',
  },
  {
    year: 1992,
    make: 'Mercury',
    models: 'Topaz,Capri,Sable,Cougar,Tracer,Grand Marquis',
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    models: 'Expo,Eclipse,Mirage,Precis,Expo LRV,Montero,Galant,3000GT,Diamante',
  },
  {
    year: 1992,
    make: 'Nissan',
    models: 'Pickup,NX,Hikari,240SX,Sentra,Tsuru,Pathfinder,Axxess,300ZX,Stanza,Maxima',
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    models:
      'Bravada,Toronado,Cutlass Ciera,88,Custom Cruiser,Cutlass,Cutlass Supreme,Cutlass Cruiser,Silhouette,98,Achieva',
  },
  {
    year: 1992,
    make: 'Plymouth',
    models: 'Grand Voyager,Laser,Voyager,Acclaim,Sundance,Colt',
  },
  {
    year: 1992,
    make: 'Pontiac',
    models: 'Grand Am,LeMans,Trans Sport,Firebird,Bonneville,Grand Prix,Sunbird',
  },
  {
    year: 1992,
    make: 'Porsche',
    models: '911,968',
  },
  {
    year: 1992,
    make: 'Rolls-Royce',
    models: 'Silver Spur,Silver Spirit,Corniche II',
  },
  {
    year: 1992,
    make: 'Saab',
    models: '900,9000',
  },
  {
    year: 1992,
    make: 'Saturn',
    models: 'SC,SL1,SL,SL2',
  },
  {
    year: 1992,
    make: 'Subaru',
    models: 'SVX,Justy,Loyale,Legacy',
  },
  {
    year: 1992,
    make: 'Suzuki',
    models: 'Sidekick,Swift',
  },
  {
    year: 1992,
    make: 'Toyota',
    models: 'Land Cruiser,Tercel,Previa,Celica,Corolla,Cressida,Pickup,4Runner,Paseo,Camry,MR2,Supra',
  },
  {
    year: 1992,
    make: 'Volkswagen',
    models: 'Fox,Transporter,Cabriolet,EuroVan,Corrado,Passat,Jetta,Golf,Sedan',
  },
  {
    year: 1992,
    make: 'Volvo',
    models: '940,240,960,740',
  },
  {
    year: 1992,
    make: 'Yugo',
    models: 'Cabrio,GV',
  },
  {
    year: 1991,
    make: 'Acura',
    models: 'Legend,NSX,Integra',
  },
  {
    year: 1991,
    make: 'Alfa Romeo',
    models: 'Spider,164',
  },
  {
    year: 1991,
    make: 'Aston Martin',
    models: 'Virage',
  },
  {
    year: 1991,
    make: 'Audi',
    models: '200 Quattro,Coupe Quattro,100,90,90 Quattro,100 Quattro,V8 Quattro,80 Quattro,200,80',
  },
  {
    year: 1991,
    make: 'Bentley',
    models: 'Continental,Eight,Mulsanne,Turbo R',
  },
  {
    year: 1991,
    make: 'BMW',
    models: '325is,735iL,318is,735i,525i,535i,750iL,M3,M5,325iX,850i,318i,325i',
  },
  {
    year: 1991,
    make: 'Buick',
    models: 'LeSabre,Park Avenue,Reatta,Regal,Century,Commercial Chassis,Skylark,Riviera,Roadmaster',
  },
  {
    year: 1991,
    make: 'Cadillac',
    models: 'Seville,Brougham,Allante,Eldorado,Fleetwood,DeVille,Commercial Chassis',
  },
  {
    year: 1991,
    make: 'Chevrolet',
    models:
      'V2500 Suburban,P30,Cavalier,C35,Astro,Lumina APV,R1500 Suburban,S10 Blazer,Corvette,V1500 Suburban,V3500,K2500,LLV,C1500,Beretta,C2500,Corsica,G10,G20,G30,Sprint,Commercial Chassis,K1500,Suburban,Lumina,Blazer,R2500 Suburban,S10,Camaro,Caprice,Tracker',
  },
  {
    year: 1991,
    make: 'Chrysler',
    models:
      'Dynasty,Phantom,New Yorker,Grand Voyager,Imperial,LeBaron,Spirit,TC Maserati,Daytona,Shadow,Town & Country',
  },
  {
    year: 1991,
    make: 'Daihatsu',
    models: 'Charade,Rocky',
  },
  {
    year: 1991,
    make: 'Dodge',
    models:
      'DM350,Monaco,D250,Caravan,Colt,Daytona,B250,Ramcharger,Grand Caravan,B350,Dynasty,D150,Dakota,Stealth,Ram 50,Shadow,Spirit',
  },
  {
    year: 1991,
    make: 'Eagle',
    models: '2000 GTX,Summit,Talon,Vista,Premier',
  },
  {
    year: 1991,
    make: 'Ferrari',
    models: 'Mondial t,F40,348 TB,Testarossa,348 TS',
  },
  {
    year: 1991,
    make: 'Ford',
    models:
      'Thunderbird,B-200,F-250,Festiva,Probe,E-250 Econoline Club Wagon,Ghia,E-150 Econoline Club Wagon,Bronco,E-250 Econoline,Grand Marquis,Ranger,Taurus,LTD Crown Victoria,Topaz,F-200,Cougar,Mustang,Country Squire,Explorer,Tempo,F-150,Aerostar,E-150 Econoline,Escort',
  },
  {
    year: 1991,
    make: 'Geo',
    models: 'Tracker,Storm,Metro,Prizm',
  },
  {
    year: 1991,
    make: 'GMC',
    models:
      'C1500,C2500,Sonoma,Tracker,K1500,R2500 Suburban,V2500 Suburban,Safari,K2500,R1500 Suburban,V1500 Suburban,V3500,Jimmy,S15 Jimmy,Syclone',
  },
  {
    year: 1991,
    make: 'Honda',
    models: 'Prelude,Civic,CRX,Accord',
  },
  {
    year: 1991,
    make: 'Hyundai',
    models: 'Excel,Sonata,Scoupe',
  },
  {
    year: 1991,
    make: 'Infiniti',
    models: 'M30,Q45,G20',
  },
  {
    year: 1991,
    make: 'Isuzu',
    models: 'Amigo,Pickup,Rodeo,Trooper,Stylus,Impulse',
  },
  {
    year: 1991,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ6,XJS,XJ12',
  },
  {
    year: 1991,
    make: 'Jeep',
    models: 'Cherokee,Comanche,Grand Wagoneer,Wrangler',
  },
  {
    year: 1991,
    make: 'Lada',
    models: 'Niva,Signet,Samara',
  },
  {
    year: 1991,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1991,
    make: 'Land Rover',
    models: 'Range Rover',
  },
  {
    year: 1991,
    make: 'Lexus',
    models: 'LS400,ES250',
  },
  {
    year: 1991,
    make: 'Lincoln',
    models: 'Continental,Mark VII,Town Car',
  },
  {
    year: 1991,
    make: 'Lotus',
    models: 'Esprit,Elan',
  },
  {
    year: 1991,
    make: 'Maserati',
    models: 'Spyder',
  },
  {
    year: 1991,
    make: 'Mazda',
    models: 'Protege,RX-7,323,929,626,B2600,Miata,MPV,MX-6',
  },
  {
    year: 1991,
    make: 'Mercedes-Benz',
    models: '300SE,560SEC,190E,350SD,420SEL,300CE,300E,300TE,560SEL,350SDL,300SEL,500SL,300D,300SL',
  },
  {
    year: 1991,
    make: 'Mercury',
    models: 'Cougar,Tracer,Grand Marquis,Topaz,Capri,Sable,Colony Park',
  },
  {
    year: 1991,
    make: 'Mitsubishi',
    models: 'Montero,Galant,3000GT,Eclipse,Mirage,Precis',
  },
  {
    year: 1991,
    make: 'Nissan',
    models: 'Micra,300ZX,Stanza,Maxima,Pickup,NX,Hikari,240SX,Sentra,Tsuru,Pathfinder,Axxess',
  },
  {
    year: 1991,
    make: 'Oldsmobile',
    models:
      'Cutlass Supreme,Delta 88,Cutlass Calais,Cutlass Cruiser,Silhouette,Bravada,Toronado,Cutlass Ciera,98,Custom Cruiser,Cutlass',
  },
  {
    year: 1991,
    make: 'Peugeot',
    models: '405,505',
  },
  {
    year: 1991,
    make: 'Plymouth',
    models: 'Sundance,Colt,Grand Voyager,Laser,Voyager,Acclaim',
  },
  {
    year: 1991,
    make: 'Pontiac',
    models: 'Tempest,Grand Prix,Grand Am,6000,Sunbird,Firefly,LeMans,Trans Sport,Optima,Firebird,Bonneville',
  },
  {
    year: 1991,
    make: 'Porsche',
    models: '928,944,911',
  },
  {
    year: 1991,
    make: 'Rolls-Royce',
    models: 'Corniche II,Silver Spur,Silver Spirit',
  },
  {
    year: 1991,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1991,
    make: 'Saturn',
    models: 'SL,SL2,SC,SL1',
  },
  {
    year: 1991,
    make: 'Sterling',
    models: '827',
  },
  {
    year: 1991,
    make: 'Subaru',
    models: 'Justy,Loyale,Legacy,XT',
  },
  {
    year: 1991,
    make: 'Suzuki',
    models: 'Swift,Sidekick',
  },
  {
    year: 1991,
    make: 'Toyota',
    models: '4Runner,Camry,MR2,Supra,Land Cruiser,Tercel,Previa,Celica,Corolla,Cressida,Pickup',
  },
  {
    year: 1991,
    make: 'Volkswagen',
    models: 'Jetta,Vanagon,Golf,Sedan,Fox,Transporter,Cabriolet,Corrado,Passat',
  },
  {
    year: 1991,
    make: 'Volvo',
    models: '740,940,240,780',
  },
  {
    year: 1991,
    make: 'Yugo',
    models: 'Cabrio,GV',
  },
  {
    year: 1990,
    make: 'Acura',
    models: 'Integra,Legend',
  },
  {
    year: 1990,
    make: 'Alfa Romeo',
    models: 'Spider',
  },
  {
    year: 1990,
    make: 'Aston Martin',
    models: 'Virage',
  },
  {
    year: 1990,
    make: 'Audi',
    models: '80 Quattro,200,80,200 Quattro,Coupe Quattro,100,90,90 Quattro,100 Quattro,V8 Quattro',
  },
  {
    year: 1990,
    make: 'Bentley',
    models: 'Turbo R,Continental,Eight,Mulsanne',
  },
  {
    year: 1990,
    make: 'BMW',
    models: '535i,750iL,M3,325iX,325i,325is,735iL,735i,525i',
  },
  {
    year: 1990,
    make: 'Buick',
    models: 'Skylark,LeSabre,Riviera,Electra,Reatta,Regal,Century',
  },
  {
    year: 1990,
    make: 'Cadillac',
    models: 'Eldorado,Fleetwood,DeVille,Commercial Chassis,Seville,Brougham,Allante',
  },
  {
    year: 1990,
    make: 'Chevrolet',
    models:
      'Beretta,C2500,Cavalier,Corsica,G10,G20,G30,Sprint,K1500,Suburban,Lumina,Blazer,R2500 Suburban,Camaro,Caprice,Tracker,V2500 Suburban,S10,Celebrity,P30,C35,Astro,Lumina APV,R1500 Suburban,S10 Blazer,Corvette,V1500 Suburban,V3500,K2500,LLV,C1500',
  },
  {
    year: 1990,
    make: 'Chrysler',
    models: 'Spirit,TC Maserati,Daytona,Shadow,Phantom,Town & Country,Dynasty,New Yorker,Imperial,LeBaron',
  },
  {
    year: 1990,
    make: 'Daihatsu',
    models: 'Rocky,Charade',
  },
  {
    year: 1990,
    make: 'Dodge',
    models:
      '2000 GTX,D150,Shadow,Dakota,Omni,Ram 50,Spirit,Monaco,D250,Caravan,Colt,Daytona,DM350,B250,Ramcharger,Grand Caravan,B350,Dynasty',
  },
  {
    year: 1990,
    make: 'Eagle',
    models: 'Premier,Talon,Vista,Summit',
  },
  {
    year: 1990,
    make: 'Ferrari',
    models: '348 TS,Mondial t,F40,348 TB,Testarossa',
  },
  {
    year: 1990,
    make: 'Ford',
    models:
      'LTD Crown Victoria,Festiva,Topaz,F-200,Cougar,Mustang,Country Squire,Tempo,F-150,Aerostar,E-150 Econoline,Escort,Thunderbird,B-200,F-250,Probe,E-250 Econoline Club Wagon,E-150 Econoline Club Wagon,Bronco,Bronco II,E-250 Econoline,Ranger,Taurus',
  },
  {
    year: 1990,
    make: 'Geo',
    models: 'Prizm,Tracker,Storm,Metro',
  },
  {
    year: 1990,
    make: 'GMC',
    models:
      'R1500 Suburban,V1500 Suburban,V3500,Jimmy,S15 Jimmy,C1500,C2500,S15,K1500,R2500 Suburban,Tracker,V2500 Suburban,Safari,K2500',
  },
  {
    year: 1990,
    make: 'Honda',
    models: 'Accord,Prelude,Civic,CRX',
  },
  {
    year: 1990,
    make: 'Hyundai',
    models: 'Excel,Sonata',
  },
  {
    year: 1990,
    make: 'Infiniti',
    models: 'M30,Q45',
  },
  {
    year: 1990,
    make: 'Isuzu',
    models: 'Impulse,Amigo,Pickup,Trooper',
  },
  {
    year: 1990,
    make: 'Jaguar',
    models: 'Vanden Plas,XJ6,XJS,XJ12',
  },
  {
    year: 1990,
    make: 'Jeep',
    models: 'Wrangler,Cherokee,Comanche,Grand Wagoneer,Wagoneer',
  },
  {
    year: 1990,
    make: 'Lada',
    models: 'Samara,Niva,Signet',
  },
  {
    year: 1990,
    make: 'Lamborghini',
    models: 'Diablo',
  },
  {
    year: 1990,
    make: 'Land Rover',
    models: 'Range Rover',
  },
  {
    year: 1990,
    make: 'Lexus',
    models: 'ES250,LS400',
  },
  {
    year: 1990,
    make: 'Lincoln',
    models: 'Town Car,Continental,Mark VII',
  },
  {
    year: 1990,
    make: 'Lotus',
    models: 'Esprit,Elan',
  },
  {
    year: 1990,
    make: 'Maserati',
    models: '228i,Spyder,430i',
  },
  {
    year: 1990,
    make: 'Mazda',
    models: '626,MPV,MX-6,323,Protege,RX-7,929,B2600,Miata',
  },
  {
    year: 1990,
    make: 'Mercedes-Benz',
    models: '190E,300D,300SL,300SE,560SEC,420SEL,300CE,300E,300TE,560SEL,350SDL,300SEL,500SL',
  },
  {
    year: 1990,
    make: 'Mercury',
    models: 'Sable,Colony Park,Cougar,Grand Marquis,Tracer,Topaz',
  },
  {
    year: 1990,
    make: 'Mitsubishi',
    models: 'Mirage,Precis,Van,Sigma,Montero,Galant,Eclipse',
  },
  {
    year: 1990,
    make: 'Nissan',
    models: 'Sentra,Tsuru,Pathfinder,Axxess,Micra,300ZX,Stanza,Maxima,Pickup,Pulsar NX,Hikari,240SX',
  },
  {
    year: 1990,
    make: 'Oldsmobile',
    models:
      'Cutlass Ciera,Custom Cruiser,Cutlass,98,Cutlass Cruiser,Cutlass Supreme,Delta 88,Cutlass Calais,Silhouette,Toronado',
  },
  {
    year: 1990,
    make: 'Peugeot',
    models: '505,405',
  },
  {
    year: 1990,
    make: 'Plymouth',
    models: 'Acclaim,Sundance,Horizon,Colt,Grand Voyager,Laser,Voyager',
  },
  {
    year: 1990,
    make: 'Pontiac',
    models: 'Firefly,Grand Prix,LeMans,Sunbird,Trans Sport,Optima,Firebird,Bonneville,Tempest,Grand Am,6000',
  },
  {
    year: 1990,
    make: 'Porsche',
    models: '928,944,911',
  },
  {
    year: 1990,
    make: 'Rolls-Royce',
    models: 'Silver Spirit,Corniche II,Silver Spur',
  },
  {
    year: 1990,
    make: 'Saab',
    models: '9000,900',
  },
  {
    year: 1990,
    make: 'Sterling',
    models: '827',
  },
  {
    year: 1990,
    make: 'Subaru',
    models: 'XT,Loyale,Legacy,Justy',
  },
  {
    year: 1990,
    make: 'Suzuki',
    models: 'Sidekick,Swift',
  },
  {
    year: 1990,
    make: 'Toyota',
    models: 'Celica,Corolla,Cressida,Pickup,4Runner,Camry,Supra,Land Cruiser,Tercel',
  },
  {
    year: 1990,
    make: 'Volkswagen',
    models: 'Jetta,Vanagon,Golf,Sedan,Fox,Transporter,Cabriolet,Corrado,Passat',
  },
  {
    year: 1990,
    make: 'Volvo',
    models: '240,780,760,740',
  },
  {
    year: 1990,
    make: 'Yugo',
    models: 'Cabrio,GV',
  },
]
