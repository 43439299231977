import * as React from 'react'
import { ElementType } from 'react'
import { ErrorMessage, FieldProps } from 'formik'
import * as changeCase from 'capital-case'
import { normalizePhone } from '../../util/util'
import classNames from 'classnames'
import { always, cond, equals, isNil, reject, T } from 'ramda'

export const Label: React.FC<{ className?: string; htmlFor?: string; children?: any }> = ({
  children,
  className,
  ...rest
}) => {
  const defaultClasses = 'font-bold text-gray-500'
  return (
    <label className={classNames(defaultClasses, className)} {...rest}>
      {children}
    </label>
  )
}

export const CustomFormControl: React.FC<{
  id?: string
  as?: ElementType
  onChange?: (e: any) => void
  type?: string
  pattern?: string
  children?: any
}> = ({ as: Component = 'input', type = 'text', pattern = null, ...rest }) => {
  const className = 'block w-full rounded px-4 py-1 border-gray-300 border form-control-height'
  const variantClasses = 'disabled:bg-gray-200 focus:style-outline-primary focus:outline-none'
  const asClassName = cond([
    [equals('select'), always('bg-white leading-tight')],
    [equals('textarea'), always('')],
    [equals('input'), always('')],
    [T, always('')],
  ])(Component)
  const customProps = reject(isNil)({ type, pattern })
  return <Component {...customProps} className={classNames(className, asClassName, variantClasses)} {...rest} />
}

export const CustomTextField: React.FC<
  FieldProps & { subLabel?: string; required?: boolean; customLabel?: string }
> = ({ required, field, subLabel, customLabel, ...props }) => {
  return (
    <div className="mb-3">
      {subLabel ? (
        <>
          <Label className="mb-0 pb-0">
            {customLabel ?? changeCase.capitalCase(field.name)}
            {required ? <span style={{ color: 'red' }}> *</span> : ''}
          </Label>
          <div className="pb-2" style={{ fontSize: 13, marginTop: -2, color: 'gray' }}>
            {subLabel}
          </div>
        </>
      ) : (
        <Label>
          {changeCase.capitalCase(customLabel ? customLabel : field.name)}
          {required ? <span style={{ color: 'red' }}> *</span> : ''}
        </Label>
      )}
      <CustomFormControl {...field} {...props} />
      <ErrorMessage render={(errorMessage) => <div style={{ color: 'red' }}>{errorMessage}</div>} name={field.name} />
    </div>
  )
}

export const CustomNumberField: React.FC<
  FieldProps & { subLabel?: string; required?: boolean; customLabel?: string }
> = ({ required, field, form, subLabel, customLabel, ...props }) => {
  return (
    <div className="mb-3">
      {subLabel ? (
        <>
          <Label className="mb-0 pb-0">
            {changeCase.capitalCase(customLabel ? customLabel : field.name)}
            {required ? <span style={{ color: 'red' }}> *</span> : ''}
          </Label>
          <div className="pb-2" style={{ fontSize: 13, marginTop: -2, color: 'gray' }}>
            {subLabel}
          </div>
        </>
      ) : (
        <Label>
          {changeCase.capitalCase(customLabel ? customLabel : field.name)}
          {required ? <span style={{ color: 'red' }}> *</span> : ''}
        </Label>
      )}
      <CustomFormControl
        {...field}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value.replace(/[^\d]/g, '')
          form.setFieldValue(field.name, val)
        }}
      />
      <ErrorMessage render={(errorMessage) => <div style={{ color: 'red' }}>{errorMessage}</div>} name={field.name} />
    </div>
  )
}

export const PhoneNumberField: React.FC<FieldProps & { required?: boolean; label?: string }> = ({
  required,
  label,
  field,
  form,
  ...props
}) => (
  <div className="mb-3">
    <Label>
      {label ? label : changeCase.capitalCase(field.name)}
      {required ? <span style={{ color: 'red' }}> *</span> : ''}
    </Label>
    <CustomFormControl
      type={'tel'}
      /* prettier-ignore */
      pattern={"[0-9-]+"}
      {...field}
      {...props}
      onChange={(e: any) => {
        const val = normalizePhone(e.target.value)
        form.setFieldValue(field.name, val)
      }}
    />
    <ErrorMessage render={(errorMessage) => <div style={{ color: 'red' }}>{errorMessage}</div>} name={field.name} />
  </div>
)
