import { carDataByYear } from '../data/carDataByYear'
// import { referrals } from "../data/referrals";
import { Mutation, MutationCatchAllArgs } from '../generated/nest-graphql'
import gql from 'graphql-tag'
import { newBackendClient } from '../components/clients/new-backend.client'

export function defaultSort(a: string, b: string) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const getYears = () => {
  return Object.keys(carDataByYear).reverse()
}

export const getMakesByYear = (year: string) => {
  // @ts-ignore
  const yearData = carDataByYear[`${year}`]
  return Object.keys(yearData).sort()
}

export const getModelsByYearAndMake = (year: string, make: string) => {
  // @ts-ignore
  const modelData = carDataByYear[`${year}`][`${make}`]
  return Object.keys(modelData).sort()
}

export const catchAll = async (values: any) => {
  try {
    return newBackendClient.mutate<Mutation, MutationCatchAllArgs>({
      variables: {
        catchAllInput: values,
      },
      mutation: gql`
        mutation catchAll($catchAllInput: JSON!) {
          catchAll(catchAllInput: $catchAllInput)
        }
      `,
    })
  } catch (e) {
    console.error({ e })
  }
}
